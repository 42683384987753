import { Text } from "@chakra-ui/react";

export const marketingReportColumnsData = [
  {
    Header: "การตลาด",
    accessor: "marketingUsername",
    disableSortBy: true,
  },
  {
    Header: "สมัครใหม่",
    accessor: "newRegisterCount",
    disableSortBy: true,
  },
  {
    Header: "ฝาก",
    accessor: "deposit",
    disableSortBy: true,
  },
  {
    Header: "โบนัส",
    accessor: "bonus",
    disableSortBy: true,
  },
  {
    Header: "ฝาก+โบนัส",
    accessor: "depositBonus",
    disableSortBy: true,
  },
  {
    Header: "ถอน",
    accessor: "withdraw",
    disableSortBy: true,
  },
  {
    Header: "กำไรขาดทุน",
    accessor: "profitAndLoss",
    Cell: ({ value }) => (
      <Text color={value < 0 ? "red.500" : "green.500"}>{value}</Text>
    ),
    disableSortBy: true,
  },
];
