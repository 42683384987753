import { useToast } from "@chakra-ui/react";

export const useErrorHandler = () => {
  const toast = useToast();
  return (error, defaultMessage) => {
    if (error.response && error.response.data && error.response.data.message) {
      toast({
        title: "Error",
        position: "top-right",
        description: error.response.data.message,
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        position: "top-right",
        description: defaultMessage,
        status: "error",
        duration: 1000,
        isClosable: true,
      });
    }
  };
};
