import {
  Flex,
  Stat,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import IconBox from "components/Icons/IconBox";
import React from "react";
import AnimatedNumber from "components/AnimatedNumber/AnimatedNumber";

const ReportsCard = ({ icon, title, number, isInteger, description }) => {
  const cleanNumber = parseFloat(number?.replace(/,/g, ""));

  return (
    !isNaN(cleanNumber) && (
      <Card>
        <CardBody h="100%">
          <Flex direction="column" justify="space-between" w="100%" h="100%">
            <Flex justify="space-between" align="center" w="100%">
              <Flex direction="column" align="flex-start">
                <StatGroup>
                  <Stat>
                    <StatLabel>{title}</StatLabel>
                    <StatNumber>
                      <AnimatedNumber n={cleanNumber} dp={isInteger ? 0 : 2} />
                    </StatNumber>
                    <StatHelpText
                      color="green.500"
                      fontSize={{ base: "xs", md: "sm" }}
                    >
                      {description}
                    </StatHelpText>
                  </Stat>
                </StatGroup>
              </Flex>
              <IconBox
                w={{ base: "30px", md: "50px" }}
                h={{ base: "30px", md: "50px" }}
                borderColor={"green.500"}
                borderWidth="1px"
              >
                {icon}
              </IconBox>
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    )
  );
};

export default ReportsCard;
