import React from "react";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import { useErrorHandler } from "hooks/useErrorHandler";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { validateField } from "../../../../utils/CommonUtils";
import { updateInvalidTransaction } from "services/transaction-report/invalidTransactionReportService";
import { ERROR_MESSAGE } from "variables/system";

const RejectInvalidTransactionModal = ({
  isOpen,
  onClose,
  setSearch,
  transaction,
}) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const handleRejectInvalidTransaction = async (values, actions) => {
    updateInvalidTransaction(values)
      .then((response) => {
        onClose();
        if (response.status === 200) {
          handleSuccess(response.data.message);
          setSearch();
        }
      })
      .catch((error) => {
        onClose();
        handleError(error, ERROR_MESSAGE);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent p={8}>
        <ModalHeader>
          <Box textAlign="center">
            <Text fontSize="26px">ยกเลิกรายการ</Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              depositId: transaction?.id,
              status: "REJECT",
              reason: "",
              username: "",
            }}
            onSubmit={(values, actions) => {
              handleRejectInvalidTransaction(values, actions);
            }}
          >
            {(props) => (
              <Form>
                <Field name="reason" validate={validateField}>
                  {({ field, form }) => (
                    <FormControl
                      mt={4}
                      isInvalid={form.errors.reason && form.touched.reason}
                      isRequired
                    >
                      <FormLabel fontSize="xs" fontWeight="bold">
                        หมายเหตุ
                      </FormLabel>
                      <Input {...field} placeholder="หมายเหตุ" fontSize="sm" />
                    </FormControl>
                  )}
                </Field>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    backgroundColor="green.500"
                    color={"#000000"}
                    w="100%"
                    type="submit"
                    isLoading={props.isSubmitting}
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RejectInvalidTransactionModal;
