import { Button, Flex, Icon, Stack, Text } from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import React, { useState } from "react";
import { banksSettingColumnsData } from "../../../variables/columns/finance-setting/bankSettingColumnsData";
import { HiOutlinePlusSm } from "react-icons/hi";
import BasicTableRemote from "components/Tables/BasicTableRemote";

import { QueryClient, QueryClientProvider } from "react-query";

import { ERROR_MESSAGE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import ManageBankModal from "./components/ManageBankModal";
// import { banks } from "variables/bank";

import DeleteBankModal from "./components/DeleteBankModal";
import {
  fetchBankSetting,
  updateBankSetting,
} from "services/finance-setting/bankSettingService";
import { useSuccessHandler } from "hooks/useSuccessHandler";

const BanksSetting = ({ pageName }) => {
  const queryClient = new QueryClient();

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const [modalState, setModalState] = useState({
    manageBankModalOpen: false,
    deleteBankModalOpen: false,
  });
  const [selectedBank, setSelectedBank] = useState(null);

  const [showTable, setShowTable] = useState(true);

  const handleModalOpen = (modal, user = null) => {
    setSelectedBank(user);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setShowTable(false);
    queryClient
      .refetchQueries()
      .catch((error) => handleError(error, ERROR_MESSAGE));
    setShowTable(true);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedBank(null);
  };

  const onToggleColumn = (obj) => {
    updateBankSetting(obj.id, obj)
      .then((response) => {
        handleSuccess(response.data.message);
        queryClient
          .refetchQueries()
          .catch((error) => handleError(error, ERROR_MESSAGE));
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
          <Button
            color={"#000000"}
            backgroundColor="green.500"
            width={{ base: "100%", md: "auto" }}
            minW={{ base: "none", md: "280px" }}
            borderRadius="6px"
            onClick={() => handleModalOpen("manageBankModal")}
          >
            <Icon as={HiOutlinePlusSm} mr="5px" />
            เพิ่มธนาคาร
          </Button>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.8}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  ตั้งค่า ธนาคารฝาก-ถอน
                </Text>
              </Flex>
            </CardHeader>
            {showTable ? (
              <QueryClientProvider client={queryClient}>
                <BasicTableRemote
                  columnsData={banksSettingColumnsData(
                    onToggleColumn,
                    handleModalOpen,
                  )}
                  query={"banks"}
                  fetchData={fetchBankSetting}
                  isPagination={false}
                />
              </QueryClientProvider>
            ) : null}
          </Card>
        </AnimatedFlex>
        <ManageBankModal
          isOpen={modalState.manageBankModalOpen}
          onClose={() => handleModalClose("manageBankModal")}
          bank={selectedBank}
          onSuccess={fetchBankSetting}
        />
        <DeleteBankModal
          isOpen={modalState.deleteBankModalOpen}
          onClose={() => handleModalClose("deleteBankModal")}
          obj={selectedBank}
          onSuccess={fetchBankSetting}
        />
      </Stack>
    </Flex>
  );
};

export default BanksSetting;
