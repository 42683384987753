import {
  Button,
  Flex,
  Icon,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import { usePagination, useTable } from "react-table";
import "assets/css/styles.css";

import { useQuery } from "react-query";

const initialState = {
  queryPageIndex: 0,
  queryPageSize: 10,
  totalCount: null,
};

const PAGE_CHANGED = "PAGE_CHANGED";
const PAGE_SIZE_CHANGED = "PAGE_SIZE_CHANGED";
const TOTAL_COUNT_CHANGED = "TOTAL_COUNT_CHANGED";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case PAGE_CHANGED:
      return {
        ...state,
        queryPageIndex: payload,
      };
    case PAGE_SIZE_CHANGED:
      return {
        ...state,
        queryPageSize: payload,
      };
    case TOTAL_COUNT_CHANGED:
      return {
        ...state,
        totalCount: payload,
      };
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

function BasicTableRemote(props) {
  const { columnsData, query, fetchData, isPagination } = props;
  const columns = useMemo(() => columnsData, []);
  // const data = useMemo(() => tableData, []);

  const [{ queryPageIndex, queryPageSize, totalCount }, dispatch] =
    React.useReducer(reducer, initialState);

  const { data, isSuccess } = useQuery(
    [query, queryPageIndex, queryPageSize],
    () => fetchData(queryPageIndex, queryPageSize),
    { keepPreviousData: false, staleTime: Infinity },
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageCount,
    gotoPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: isSuccess ? data.results : [],
      initialState: {
        pageIndex: queryPageIndex,
        pageSize: queryPageSize,
        sortBy: [
          {
            id: "id",
            desc: false,
          },
        ],
      },
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: isSuccess ? Math.ceil(totalCount / queryPageSize) : null,
    },
    // useSortBy,
    usePagination,
  );

  React.useEffect(() => {
    dispatch({ type: PAGE_CHANGED, payload: pageIndex });
  }, [pageIndex]);

  React.useEffect(() => {
    dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
    gotoPage(0);
  }, [pageSize, gotoPage]);

  React.useEffect(() => {
    if (data?.count) {
      dispatch({
        type: TOTAL_COUNT_CHANGED,
        payload: data.count,
      });
    }
  }, [data?.count]);

  const createPages = (count) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

  // const { pageIndex, pageSize } = state;

  const stackAlignment = useBreakpointValue({ base: "center", md: "flex-end" });
  const stackMarginStart = useBreakpointValue({ base: "0", md: "auto" });

  const [pageChunk, setPageChunk] = useState(0);
  const pagesPerChunk = 10;

  return (
    <>
      <Flex direction="column" w="100%">
        <Stack
          direction="row"
          spacing="12px"
          align="center"
          my="24px"
          px="22px"
        >
          {isPagination && (
            <>
              <Select
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
                color="gray.400"
                size="sm"
                borderRadius="md"
                maxW="75px"
                cursor="pointer"
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
              </Select>
              <Text fontSize="xs" color="gray.400" fontWeight="normal">
                entries per page
              </Text>
            </>
          )}
        </Stack>
        <Box overflowX="auto" w="100%">
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
            w="100%"
          >
            <Thead backgroundColor="rgb(29,29,29)">
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      // {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={index}
                      pe="0px"
                      p={3}
                      minW="175px"
                      textAlign="center"
                    >
                      <Flex
                        justify="center"
                        align="center"
                        fontSize={{ sm: "10px", lg: "13px" }}
                        color="white"
                      >
                        {column.render("Header")}
                        {!column.disableSortBy && (
                          <Icon
                            w={{ sm: "10px", md: "14px" }}
                            h={{ sm: "10px", md: "14px" }}
                            color={column.isSorted ? "gray.500" : "gray.400"}
                            float="right"
                            as={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? TiArrowSortedDown
                                  : TiArrowSortedUp
                                : TiArrowUnsorted
                            }
                          />
                        )}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    key={index}
                    sx={{
                      transition: "background-color 0.2s ease",
                      _hover: { backgroundColor: "gray.800" },
                    }}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <Td
                          {...cell.getCellProps()}
                          fontSize="sm"
                          key={index}
                          color="white"
                          p={2}
                          minW="175px"
                          textAlign="center"
                          className="td-border-bottom"
                        >
                          {cell.render("Cell")}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
        {isPagination && (
          <Flex
            direction={{ sm: "column", md: "row" }}
            w="100%"
            justify="space-between"
            align="center"
            px={{ md: "22px" }}
          >
            <Text
              fontSize="sm"
              color="green.500"
              fontWeight="normal"
              mb={{ sm: "24px", md: "0px" }}
            >
              Showing {pageSize * pageIndex + 1} to{" "}
              {pageSize * (pageIndex + 1) <= totalCount
                ? pageSize * (pageIndex + 1)
                : totalCount}{" "}
              of {totalCount} entries
            </Text>
            <Stack
              direction="row"
              alignSelf={stackAlignment}
              spacing="4px"
              ms={stackMarginStart}
            >
              <Button
                variant="no-hover"
                onClick={() => {
                  if (pageChunk > 0) {
                    setPageChunk(pageChunk - 1);
                  }
                }}
                transition="all .5s ease"
                w="40px"
                h="40px"
                borderRadius="6px"
                bg="gray.600"
                _hover={{ bg: "gray.500" }}
              >
                <Icon as={GrFormPrevious} w="16px" h="16px" color="gray.400" />
              </Button>
              {createPages(pageCount)
                .slice(
                  pageChunk * pagesPerChunk,
                  (pageChunk + 1) * pagesPerChunk,
                )
                .map((pageNumber, index) => (
                  <Button
                    variant="no-hover"
                    transition="all .5s ease"
                    onClick={() => gotoPage(pageNumber - 1)}
                    w="40px"
                    h="40px"
                    borderRadius="6px"
                    bg={pageNumber === pageIndex + 1 ? "green.500" : "gray.600"}
                    _hover={{ bg: "gray.500" }}
                    key={index}
                  >
                    <Text fontSize="sm" color="#000000">
                      {pageNumber}
                    </Text>
                  </Button>
                ))}
              <Button
                variant="no-hover"
                onClick={() => {
                  if ((pageChunk + 1) * pagesPerChunk < pageCount) {
                    setPageChunk(pageChunk + 1);
                  }
                }}
                transition="all .5s ease"
                w="40px"
                h="40px"
                borderRadius="6px"
                bg="gray.600"
                _hover={{ bg: "gray.500" }}
              >
                <Icon as={GrFormNext} w="16px" h="16px" color="gray.400" />
              </Button>
            </Stack>
          </Flex>
        )}
      </Flex>
    </>
  );
}

export default BasicTableRemote;
