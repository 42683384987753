import { Flex, Badge, IconButton } from "@chakra-ui/react";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";

export const memberTagColumnsData = (handleModalOpen) => [
  {
    Header: "ชื่อแท็ค",
    accessor: "name",
    disableSortBy: true,
  },
  {
    Header: "รายระเอียด",
    accessor: "description",
    disableSortBy: true,
  },
  {
    Header: "สี",
    accessor: "color",
    Cell: ({ cell }) => (
      <Flex align="center" justify="center">
        <Badge
          backgroundColor={cell.value}
          w="65px"
          h="28px"
          borderRadius="15px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color={"#000000"}
        >
          {cell.value}
        </Badge>
      </Flex>
    ),
    disableSortBy: true,
  },
  {
    Header: "แก้ไข",
    accessor: "แก้ไข",
    Cell: ({ row }) => (
      <IconButton
        variant="outline"
        borderColor={"green.500"}
        color={"green.500"}
        aria-label="edit"
        size="sm"
        icon={<HiOutlinePencil />}
        onClick={() => handleModalOpen("manageMemberTagModal", row.original)}
      />
    ),
    disableSortBy: true,
  },
  {
    Header: "ลบ",
    accessor: "delete",
    Cell: ({ row }) => (
      <IconButton
        variant="outline"
        borderColor={"red.500"}
        color={"red.500"}
        aria-label="delete"
        size="sm"
        icon={<HiOutlineTrash />}
        onClick={() => handleModalOpen("deleteMemberTagModal", row.original)}
      >
        แก้ไข
      </IconButton>
    ),
    disableSortBy: true,
  },
];
