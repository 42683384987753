import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Grid,
  GridItem,
  Text,
  useBreakpointValue,
  Select,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { DEVICE_DROPDOWN, ERROR_MESSAGE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import ImageUploader from "components/ImageUploader/ImageUploader";
import {
  addBanner,
  updateBanner,
} from "services/website-setting/bannerService";
import { useSuccessHandler } from "hooks/useSuccessHandler";

const ManageBannerModal = ({ isOpen, onClose, banner = null, onSuccess }) => {
  const initialRef = React.useRef(null);

  const finalRef = React.useRef(null);

  const modalSize = useBreakpointValue({ base: "full", md: "6xl" });

  const gridTemplateColumns = useBreakpointValue({
    base: "1fr",
    lg: "1fr 2fr",
  });

  const buttonSize = useBreakpointValue({ base: "100%", md: "65.7%" });

  const handleError = useErrorHandler();

  const handleSuccess = useSuccessHandler();

  const handleSubmit = (values, actions) => {
    const payload = banner ? { ...banner, ...values } : values;

    const response = banner ? updateBanner(payload) : addBanner(payload);

    response
      .then((response) => {
        handleSuccess(response.data.message);
        onSuccess();
        onClose();
      })
      .catch((error) => {
        onClose();
        handleError(error, ERROR_MESSAGE);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={modalSize}
    >
      <ModalOverlay />
      <ModalContent
        maxH="100vh"
        p={8}
        borderRadius={{ base: "none", md: "md" }}
      >
        <ModalHeader>
          <Box textAlign="center">
            <Text fontSize="26px">
              {banner ? `แก้ไข Banner: ${banner?.name}` : "เพิ่ม banner"}
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <Formik
            initialValues={{
              name: banner?.name || "",
              content: banner?.content || "",
              actionLink: banner?.actionLink || "",
              imageUrl: banner?.image || "",
              device: banner?.device || "",
              language: banner?.language || "TH",
              sort: banner?.sort || 1,
              status: banner?.status || true,
            }}
            onSubmit={(values, actions) => {
              handleSubmit(values, actions);
            }}
            enableReinitialize
          >
            {({ values, handleChange, setFieldValue, isSubmitting }) => (
              <Form>
                <Grid templateColumns={gridTemplateColumns} gap={4}>
                  <Box>
                    <ImageUploader
                      initialImage={values.imageUrl}
                      onUpload={(uri) => setFieldValue("imageUrl", uri)}
                    />
                  </Box>
                  <Box>
                    <Grid
                      templateColumns={{ base: "1fr", md: "repeat(1, 1fr)" }}
                      gap={4}
                    >
                      <GridItem colSpan={{ base: 1, md: 1 }}>
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ชื่อโปรโมชั่น
                          </FormLabel>
                          <Input
                            name="name"
                            onChange={handleChange}
                            value={values.name}
                            fontSize="sm"
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={{ base: 1, md: 1 }}>
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            คอนเทนต์
                          </FormLabel>
                          <Input
                            name="content"
                            onChange={handleChange}
                            value={values.content}
                            fontSize="sm"
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={{ base: 1, md: 1 }}>
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ลิ้งค์
                          </FormLabel>
                          <Input
                            name="actionLink"
                            onChange={handleChange}
                            value={values.actionLink}
                            fontSize="sm"
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={{ base: 1, md: 1 }}>
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ลำดับ
                          </FormLabel>
                          <Input
                            name="sort"
                            onChange={handleChange}
                            value={values.sort}
                            fontSize="sm"
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={{ base: 1, md: 1 }}>
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            อุปกรณ์
                          </FormLabel>
                          <Select
                            name="device"
                            onChange={handleChange}
                            value={values.device}
                            fontSize="sm"
                            placeholder="เลือกอุปกรณ์"
                          >
                            {DEVICE_DROPDOWN.map((type, id) => (
                              <option key={id} value={type.val}>
                                {type.text}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel mb={0} fontSize="xs" fontWeight="bold">
                            Active
                          </FormLabel>
                          <Switch
                            isChecked={values.status === "true"}
                            onChange={(e) =>
                              setFieldValue(
                                "status",
                                e.target.checked.toString(),
                              )
                            }
                          />
                        </FormControl>
                      </GridItem>
                    </Grid>
                  </Box>
                </Grid>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    backgroundColor="green.500"
                    color={"#000000"}
                    w={buttonSize}
                    type="submit"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManageBannerModal;
