import { Icon, IconButton, Switch } from "@chakra-ui/react";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";

export const trueWalletsSettingColumnsData = (
  onToggleColumn,
  handleModalOpen,
) => [
  {
    Header: "เบอร์โทรศัพท์",
    accessor: "phoneNumber",
    disableSortBy: true,
  },
  {
    Header: "ชื่อ",
    accessor: "name",
    disableSortBy: true,
  },
  {
    Header: "สถานะเปิดใช้งาน",
    accessor: "active",
    Cell: ({ row }) => (
      <Switch
        size="md"
        colorScheme="green"
        onChange={() =>
          onToggleColumn({
            ...row.original,
            active: !row.original.active,
          })
        }
        isChecked={row.original.active}
      />
    ),
    disableSortBy: true,
  },
  {
    Header: "แก้ไข",
    accessor: "แก้ไข",
    Cell: ({ row }) => (
      <IconButton
        variant="outline"
        borderColor={"green.500"}
        color={"green.500"}
        aria-label="edit"
        size="sm"
        icon={<Icon as={HiOutlinePencil} />}
        onClick={() => handleModalOpen("manageTrueWalletModal", row.original)}
      >
        แก้ไข
      </IconButton>
    ),
    disableSortBy: true,
  },
  {
    Header: "ลบ",
    accessor: "ลบ",
    Cell: ({ row }) => (
      <IconButton
        variant="outline"
        borderColor={"red.500"}
        color={"red.500"}
        aria-label="delete"
        size="sm"
        icon={<Icon as={HiOutlineTrash} />}
        onClick={() => handleModalOpen("deleteTrueWalletModal", row.original)}
      >
        แก้ไข
      </IconButton>
    ),
    disableSortBy: true,
  },
];
