import { HStack, Button, Icon } from "@chakra-ui/react";
import { HiOutlineCheck, HiOutlineXMark, HiMiniQrCode } from "react-icons/hi2";

const DepositEditButtons = ({ row, handleModalOpen }) => {
  const status = row.original.status;
  const image = row.original.image;
  const showBlockAutoButtons = status === "BLOCK_AUTO";
  const showNotSureButtons = status === "NOT_SURE";
  const showQr = image !== null;

  console.log(image);

  return (
    <>
      <HStack spacing={2}>
        {showBlockAutoButtons && (
          <>
            <Button
              leftIcon={<Icon as={HiOutlineCheck} boxSize={4} />}
              variant="outline"
              colorScheme="green"
              size="xs"
              fontSize="xs"
              padding={2}
              onClick={() =>
                handleModalOpen(
                  "approveBlockAutoTransactionModal",
                  row.original,
                )
              }
            >
              อนุมัติ
            </Button>
            <Button
              leftIcon={<Icon as={HiOutlineXMark} boxSize={4} />}
              variant="outline"
              colorScheme="red"
              size="xs"
              fontSize="xs"
              padding={2}
              onClick={() =>
                handleModalOpen("rejectBlockAutoTransactionModal", row.original)
              }
            >
              ยกเลิก
            </Button>
          </>
        )}
        {showNotSureButtons && (
          <>
            <Button
              leftIcon={<Icon as={HiOutlineCheck} boxSize={4} />}
              variant="outline"
              colorScheme="green"
              size="xs"
              fontSize="xs"
              padding={2}
              onClick={() =>
                handleModalOpen("approveInvalidTransactionModal", row.original)
              }
            >
              อนุมัติ
            </Button>
            <Button
              leftIcon={<Icon as={HiOutlineXMark} boxSize={4} />}
              variant="outline"
              colorScheme="red"
              size="xs"
              fontSize="xs"
              padding={2}
              onClick={() =>
                handleModalOpen("rejectInvalidTransactionModal", row.original)
              }
            >
              ยกเลิก
            </Button>
          </>
        )}

        {showQr && (
          <Button
            leftIcon={<Icon as={HiMiniQrCode} boxSize={4} />}
            variant="outline"
            colorScheme="blue"
            size="xs"
            fontSize="xs"
            padding={2}
            onClick={() => handleModalOpen("qrCodeModal", row.original)}
          >
            QR
          </Button>
        )}
      </HStack>
    </>
  );
};

export default DepositEditButtons;
