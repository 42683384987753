import { Box, Flex, Spacer, Spinner, Stack, Text } from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import CardBody from "components/Card/CardBody";
import { QueryClient, QueryClientProvider } from "react-query";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import {
  fetchInvalidTransaction,
  fetchMarketingInvalidTransaction,
} from "services/transaction-report/invalidTransactionReportService";
import React, { useEffect, useState } from "react";
import { getReferenceDates } from "../../../utils/DateformatUtils";
import moment from "moment";
import { invalidTransactionReportColumnsData } from "variables/columns/transaction-report/invalidTransactionReportColumnsData";
import RejectInvalidTransactionModal from "./components/RejectInvalidTransactionModal";
import ApproveInvalidTransactionModal from "./components/ApproveInvalidTransactionModal";
import { mapJsonToTableData } from "../../../utils/CommonUtils";
import useDateRange from "../../../hooks/useDateRange";
import FileExportDropdown from "../../../components/FileExportDropdown/FileExportDropdown";

const InvalidTransactionReport = ({ pageName }) => {
  const queryClient = new QueryClient();
  const { dateNow, dateDayCheck } = getReferenceDates();
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const [search, setSearch] = useState({});
  const [downloadableData, setDownloadableData] = useState([]);

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };
  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const { selectedDates, handleDateChange } = useDateRange(
    [dateDayCheck, dateNow],
    search,
    setSearch,
    dateFieldsNames,
    dateSearchFormat,
  );

  const [modalState, setModalState] = useState({
    approveInvalidTransactionModalOpen: false,
    rejectInvalidTransactionModalOpen: false,
  });

  const handleModalOpen = (modal, transaction = null) => {
    setSelectedTransaction(transaction);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedTransaction(null);
  };

  const role = JSON.parse(localStorage.getItem("APP_PROFILE")).role;

  const fetchData = async (pageIndex, pageSize, search) => {
    const response =
      role !== "MARKETING"
        ? await fetchInvalidTransaction(pageIndex, pageSize, search)
        : await fetchMarketingInvalidTransaction(pageIndex, pageSize, search);

    const formattedRecords = mapJsonToTableData(
      response.results,
      invalidTransactionReportColumnsData(handleModalOpen),
    );

    setDownloadableData(formattedRecords);
    return response;
  };

  useEffect(() => {
    setSearch({
      [dateFieldsNames.from]: moment(dateDayCheck).format(dateSearchFormat),
      [dateFieldsNames.to]: moment(dateNow).format(dateSearchFormat),
    });
    setIsLoading(false);
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
          <Box>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Box>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column" justifyContent="center">
                <Text fontSize="18px" fontWeight="bold" mb="6px">
                  เช็ครายการไม่พบบัญชี
                </Text>
              </Flex>
              <Spacer />
              <FileExportDropdown
                data={downloadableData}
                filename={"รายการฝากไม่พบบัญชี"}
              />
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <QueryClientProvider client={queryClient}>
                  <SearchTableRemote
                    columnsData={invalidTransactionReportColumnsData(
                      handleModalOpen,
                    )}
                    fetchData={fetchData}
                    search={search}
                    setSearch={setSearch}
                  />
                </QueryClientProvider>
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
        <ApproveInvalidTransactionModal
          isOpen={modalState.approveInvalidTransactionModalOpen}
          onClose={() => handleModalClose("approveInvalidTransactionModal")}
          setSearch={setSearch}
          transaction={selectedTransaction}
        />
        <RejectInvalidTransactionModal
          isOpen={modalState.rejectInvalidTransactionModalOpen}
          onClose={() => handleModalClose("rejectInvalidTransactionModal")}
          setSearch={setSearch}
          transaction={selectedTransaction}
        />
      </Stack>
    </Flex>
  );
};

export default InvalidTransactionReport;
