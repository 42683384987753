import { Field, Form, Formik } from "formik";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Stack,
  Switch,
  Text,
  Textarea,
  useBreakpointValue,
} from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import { Element } from "react-scroll";
import CardBody from "components/Card/CardBody";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "hooks/useErrorHandler";
import { ERROR_MESSAGE } from "variables/system";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import {
  fetchLiveChatSetting,
  updateLiveChatSetting,
} from "services/website-setting/liveChatService";

const LiveChatSetting = ({ pageName }) => {
  const gridWidth = useBreakpointValue({ base: "100%", md: "50%" });

  const handleSuccess = useSuccessHandler();

  const handleError = useErrorHandler();

  const [liveChatData, setLiveChatData] = useState(null);

  useEffect(() => {
    fetchLiveChatSetting()
      .then((response) => {
        setLiveChatData(response.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  }, []);

  const handleSubmit = (values) => {
    updateLiveChatSetting(values)
      .then((response) => {
        handleSuccess(response.data.message);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
        </AnimatedFlex>
        <Formik
          initialValues={{
            chatscript: liveChatData?.chatscript || "",
            status: liveChatData?.status || false,
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Flex direction="column">
                <AnimatedFlex duration={0.4} delay={0.6}>
                  <Card
                    w={{ sm: "100%" }}
                    alignSelf="flex-end"
                    justifySelf="flex-end"
                  >
                    <Element>
                      <CardBody>
                        <Grid
                          templateColumns={{
                            base: "1fr",
                            md: "repeat(1, 1fr)",
                          }}
                          gap="24px"
                          w={gridWidth}
                        >
                          <GridItem>
                            <FormControl>
                              <FormLabel
                                fontWeight="semibold"
                                fontSize="xs"
                                mb="10px"
                              >
                                เปิด/ปิด LiveChat
                              </FormLabel>
                              <Switch
                                isChecked={Boolean(values.status)}
                                onChange={(e) => {
                                  setFieldValue("status", e.target.checked);
                                }}
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <FormLabel
                                fontWeight="semibold"
                                fontSize="xs"
                                mb="10px"
                              >
                                Script
                              </FormLabel>
                              <Field
                                as={Textarea}
                                name="chatscript"
                                minHeight="315px"
                                fontSize="sm"
                              />
                            </FormControl>
                          </GridItem>
                        </Grid>
                      </CardBody>
                    </Element>
                  </Card>
                </AnimatedFlex>
                <AnimatedFlex
                  justify="flex-end"
                  pt="15px"
                  duration={0.4}
                  delay={0.8}
                >
                  <Button
                    backgroundColor="green.500"
                    color={"#000000"}
                    width={{ base: "100%", md: "auto" }}
                    minW={{ base: "none", md: "280px" }}
                    borderRadius="6px"
                    type="submit"
                  >
                    บันทึกข้อมูล
                  </Button>
                </AnimatedFlex>
              </Flex>
            </Form>
          )}
        </Formik>
      </Stack>
    </Flex>
  );
};

export default LiveChatSetting;
