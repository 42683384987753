import { callapi } from "../../utils/APIUtils";

export const addPromotionSetting = (payload) => {
  return callapi("/api/admin/promotion", "POST", payload);
};

export const fetchPromotionSetting = () => {
  return callapi("/api/admin/promotion", "GET", {});
};

export const updatePromotionSetting = (id, payload) => {
  return callapi(`/api/admin/promotion/${id}`, "PUT", payload);
};

export const deletePromotionSetting = (id) => {
  return callapi(`/api/admin/promotion/${id}`, "DELETE", {});
};
