export const promotionReportColumnsData = () => {
  return [
    {
      Header: "ชื่อโปรโมชั่น",
      accessor: "name",
    },
    {
      Header: "จำนวนครั้ง",
      accessor: "countPromotion",
    },
    {
      Header: "จำนวนโบนัส",
      accessor: "sumBonus",
    },
  ];
};
