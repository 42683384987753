import React from "react";
import {
  Box,
  Image,
  Text,
  Badge,
  Flex,
  Icon,
  Switch,
  Divider,
} from "@chakra-ui/react";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";

const PromotionCard = ({ promotion, onEdit, onDelete }) => {
  return (
    <Box
      borderRadius="lg"
      overflow="hidden"
      p={0}
      backgroundColor="gray.700"
      boxShadow="lg"
    >
      <Image
        src={promotion.urlImage || "https://via.placeholder.com/1040"}
        alt={`${promotion.name} image`}
        width="100%"
        height="150px"
        objectFit="cover"
      />

      <Box p={4}>
        <Flex justify="space-between" align="center">
          <Text fontSize="xl" fontWeight="bold">
            {promotion.name}
          </Text>
          <Flex mt={4}>
            <Icon
              as={HiOutlineTrash}
              w={5}
              h={5}
              mx={2}
              cursor="pointer"
              color="red.500"
              onClick={onDelete}
            />
            <Icon
              as={HiOutlinePencil}
              w={5}
              h={5}
              mx={2}
              cursor="pointer"
              color="green.500"
              onClick={onEdit}
            />
          </Flex>
        </Flex>
        <Badge colorScheme="teal">{promotion.typeBonus}</Badge>
        <Flex justify="space-between" mt={2}>
          <Text fontWeight="medium" fontSize="sm">
            ฝากต่ำสุด
          </Text>
          <Text fontSize="sm">{promotion.minTopup}</Text>
        </Flex>
        <Flex justify="space-between" mt={2}>
          <Text fontWeight="medium" fontSize="sm">
            ฝากสูงสุด
          </Text>
          <Text fontSize="sm">{promotion.maxTopup}</Text>
        </Flex>
        <Flex justify="space-between" mt={2}>
          <Text fontWeight="medium" fontSize="sm">
            โบนัสสูงสุด
          </Text>
          <Text fontSize="sm">{promotion.maxBonus}</Text>
        </Flex>
        <Flex justify="space-between" mt={2}>
          <Text fontWeight="medium" fontSize="sm">
            รับโบนัสสูงสุด
          </Text>
          <Text fontSize="sm">{promotion.maxReceiveBonus}</Text>
        </Flex>
        <Flex justify="space-between" mt={2}>
          <Text fontWeight="medium" fontSize="sm">
            ยอดเทิร์น
          </Text>
          <Text fontSize="sm">{promotion.turnOver}</Text>
        </Flex>
        <Flex justify="space-between" mt={2}>
          <Text fontWeight="medium" fontSize="sm">
            อั้นถอน
          </Text>
          <Text fontSize="sm">{promotion.maxWithdraw}</Text>
        </Flex>

        <Divider my={4} borderColor="#ffffff14" />

        <Flex wrap="wrap" mb={4}>
          <Badge colorScheme="purple" mr={2} mb={2}>
            {promotion.typePromotion}
          </Badge>
          <Badge colorScheme="purple" mr={2} mb={2}>
            {promotion.typeGame}
          </Badge>
        </Flex>
        <Flex
          justify="space-between"
          mt={2}
          p={2}
          border="1px"
          borderColor="whiteAlpha.300"
          borderRadius="md"
        >
          <Text fontWeight="medium" color="white" fontSize="sm">
            สถานะเปิดใช้งาน
          </Text>
          <Switch isChecked={promotion.active} readOnly />
        </Flex>
      </Box>
    </Box>
  );
};

export default PromotionCard;
