import { mode } from "@chakra-ui/theme-tools";
/*
colors: {
		gray: {
			600: 'rgb(56, 60, 65)',
			700: 'rgba(49, 54, 61, 1)',
			800: 'rgb(37,41,45)'
		},
		teal: {
			500: 'rgb(79, 209, 197)'
		},
		green: {
			500: 'rgba(83, 177, 153, 1)'
		}
	},
 */

export const globalStyles = {
  colors: {
    red: {
      200: "rgba(234, 84, 85, 1)", // ColorScheme Value
      500: "rgba(223,23,24,1)",
    },
    gray: {
      500: "rgba(29,29,29,1)",
      600: "rgba(56, 60, 65,1)",
      700: "rgba(23,23,23,1)",
      800: "rgba(16,17,18,1)", // background overriding
    },
    teal: {
      500: "rgba(79, 209, 197,1)",
    },
    green: {
      200: "rgba(40, 199, 111, 1)", // ColorScheme Value
      500: "rgba(29,215,96,1)",
    },
  },
  styles: {
    global: (props) => ({
      body: {
        bg:
          document.documentElement.layout === "auth"
            ? mode("white", "gray.800")(props)
            : mode("gray.50", "gray.800")(props),
        fontFamily: "'IBM Plex Sans Thai', sans-serif",
      },
      html: {
        fontFamily: "'IBM Plex Sans Thai', sans-serif",
      },
    }),
  },
};
