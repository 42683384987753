import { FieldArray, Form, Formik } from "formik";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import React, { useEffect, useState } from "react";

import { useErrorHandler } from "hooks/useErrorHandler";
import { ERROR_MESSAGE } from "variables/system";
import { HiOutlinePlus, HiOutlineTrash } from "react-icons/hi2";
import {
  fetchCanonical,
  updateCanonical,
} from "services/website-setting/canonicalService";
import { useSuccessHandler } from "hooks/useSuccessHandler";

const CanonicalSetting = ({ pageName }) => {
  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();

  const [canonicalData, setCanonicalData] = useState(null);

  const fetchCanonicalData = () => {
    fetchCanonical()
      .then((response) => {
        setCanonicalData(response.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const handleSubmit = (values) => {
    updateCanonical(values)
      .then((response) => {
        handleSuccess(response.data.message);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    fetchCanonicalData();
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
        </AnimatedFlex>
        <Formik
          initialValues={{
            domain: canonicalData?.domain || "",
            target: canonicalData?.target || "",
            chanonicalPagePayloadList:
              canonicalData?.chanonicalPagePayloadList || [
                {
                  id: "",
                  page: "",
                  target: "",
                  isDelete: null,
                },
              ],
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form>
              <Flex direction="column">
                <Stack direction="column" spacing="24px" w="100%">
                  <AnimatedFlex duration={0.4} delay={0.4}>
                    <Card
                      w={{ sm: "100%" }}
                      alignSelf="flex-end"
                      justifySelf="flex-end"
                    >
                      <CardBody>
                        <Grid
                          templateColumns={{
                            base: "1fr",
                            md: "repeat(2, 1fr)",
                          }}
                          gap="24px"
                          w="100%"
                        >
                          <GridItem>
                            <FormControl>
                              <FormLabel
                                fontWeight="semibold"
                                fontSize="xs"
                                mb="10px"
                              >
                                Domain
                              </FormLabel>
                              <Input
                                borderRadius="4px"
                                placeholder="eg. Jackson"
                                fontSize="sm"
                                value={values.domain}
                                onChange={handleChange}
                                name="domain"
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <FormLabel
                                fontWeight="semibold"
                                fontSize="xs"
                                mb="10px"
                              >
                                Target
                              </FormLabel>
                              <Input
                                borderRadius="4px"
                                placeholder="eg. Jackson"
                                fontSize="sm"
                                value={values.target}
                                onChange={handleChange}
                                name="target"
                              />
                            </FormControl>
                          </GridItem>
                        </Grid>
                      </CardBody>
                    </Card>
                  </AnimatedFlex>
                  <FieldArray name="chanonicalPagePayloadList">
                    {({ push }) => (
                      <>
                        <AnimatedFlex
                          justify="space-between"
                          align="center"
                          w="100%"
                          duration={0.4}
                          delay={0.6}
                        >
                          <Text fontSize="lg" fontWeight="bold">
                            เพิ่ม Target
                          </Text>
                          <Button
                            variant="outline"
                            borderColor={"green.500"}
                            color={"green.500"}
                            size="md"
                            borderRadius="6px"
                            onClick={() =>
                              push({
                                id: "",
                                page: "",
                                target: "",
                                isDelete: null,
                              })
                            }
                          >
                            <Icon as={HiOutlinePlus} />
                          </Button>
                        </AnimatedFlex>
                        <AnimatedFlex duration={0.4} delay={0.8}>
                          <Card
                            w={{ sm: "100%" }}
                            alignSelf="flex-end"
                            justifySelf="flex-end"
                          >
                            <CardBody>
                              <Grid
                                templateColumns={{
                                  base: "1fr",
                                  sm: "repeat(2, 1fr) auto",
                                  md: "repeat(2, 1fr) auto",
                                }}
                                gap="24px"
                                w="100%"
                              >
                                {values.chanonicalPagePayloadList.map(
                                  (condition, index) =>
                                    !condition.isDelete && (
                                      <React.Fragment key={index}>
                                        <GridItem
                                          colSpan={{ base: 1, sm: 1, md: 1 }}
                                        >
                                          <FormControl>
                                            <FormLabel
                                              fontWeight="semibold"
                                              fontSize="xs"
                                              mb="10px"
                                            >
                                              Page
                                            </FormLabel>
                                            <Input
                                              value={condition.page}
                                              onChange={handleChange}
                                              name={`chanonicalPagePayloadList.${index}.page`}
                                              placeholder="eg. 10000"
                                              fontSize="sm"
                                            />
                                          </FormControl>
                                        </GridItem>
                                        <GridItem
                                          colSpan={{ base: 1, sm: 1, md: 1 }}
                                        >
                                          <FormControl>
                                            <FormLabel
                                              fontWeight="semibold"
                                              fontSize="xs"
                                              mb="10px"
                                            >
                                              Target
                                            </FormLabel>
                                            <Input
                                              value={condition.target}
                                              onChange={handleChange}
                                              name={`chanonicalPagePayloadList.${index}.target`}
                                              placeholder="eg. 1000000"
                                              fontSize="sm"
                                            />
                                          </FormControl>
                                        </GridItem>
                                        <GridItem
                                          colSpan={1}
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="end"
                                        >
                                          <IconButton
                                            variant="outline"
                                            borderColor={"red.500"}
                                            color={"red.500"}
                                            aria-label="delete"
                                            size="md"
                                            icon={<HiOutlineTrash />}
                                            onClick={() =>
                                              setFieldValue(
                                                `chanonicalPagePayloadList.${index}.isDelete`,
                                                "Y",
                                              )
                                            }
                                          />
                                        </GridItem>
                                      </React.Fragment>
                                    ),
                                )}
                              </Grid>
                            </CardBody>
                          </Card>
                        </AnimatedFlex>
                      </>
                    )}
                  </FieldArray>
                  <Flex justify="flex-end" pt="15px">
                    <Button
                      backgroundColor="green.500"
                      color={"#000000"}
                      width={{ base: "100%", md: "auto" }}
                      minW={{ base: "none", md: "280px" }}
                      borderRadius="6px"
                      type="submit"
                    >
                      บันทึกข้อมูล
                    </Button>
                  </Flex>
                </Stack>
              </Flex>
            </Form>
          )}
        </Formik>
      </Stack>
    </Flex>
  );
};

export default CanonicalSetting;
