import { callapi, callListApi } from "../../utils/APIUtils";
import {
  buildBankSettingPayload,
  buildBasePayload,
} from "../../utils/PayloadUtils";

export const addTrueWalletSetting = (payload) => {
  return callapi("/api/admin/truewallet", "POST", payload);
};

export const fetchTrueWalletSetting = async (
  page,
  pageSize,
  sort = "D",
  sortField = "id",
) => {
  const req = buildBasePayload(
    page,
    pageSize,
    null,
    sort,
    sortField,
    buildBankSettingPayload,
  );

  const response = await callListApi("/api/admin/truewallet", "GET", req)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(`API error: ${res.statusText}`);
      }
    })
    .catch((e) => {
      throw new Error(`API error: ${e.message}`);
    });

  const data2 = await response;

  const data = {
    count: data2.data.length,
    results: data2.data,
  };

  return data;
};

export const updateTrueWalletSetting = (id, payload) => {
  return callapi(`/api/admin/truewallet/${id}`, "PUT", payload);
};

export const deleteTrueWalletSetting = (id) => {
  return callapi(`/api/admin/truewallet/${id}`, "DELETE", {});
};
