import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Field, Form, Formik } from "formik";
import { updatePaymentGatewaySetting } from "services/finance-setting/paymentGatewayService";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import { useErrorHandler } from "hooks/useErrorHandler";
import { ERROR_MESSAGE } from "variables/system";

const UpdatePaymentGatewaySettingModal = ({
  isOpen,
  onClose,
  paymentGateway,
}) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const initialConfig = paymentGateway ? JSON.parse(paymentGateway.config) : {};

  const handleUpdatePaymentGatewaySetting = (values, actions) => {
    const updatedConfig = JSON.stringify(values);

    const req = {
      ...paymentGateway,
      config: updatedConfig,
    };

    updatePaymentGatewaySetting(req)
      .then((response) => {
        handleSuccess(response.data.message);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      })
      .finally(() => {
        actions.setSubmitting(false);
        onClose();
      });
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent p={8}>
        <ModalHeader>
          <Box textAlign="center">
            <Text fontSize="26px">ตั้งค่า Payment Gateway</Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={initialConfig}
            onSubmit={(values, actions) => {
              handleUpdatePaymentGatewaySetting(values, actions);
            }}
          >
            {(props) => (
              <Form>
                {Object.keys(initialConfig).map((key, index) => (
                  <Field name={key} key={key}>
                    {({ field }) => (
                      <FormControl mt={4}>
                        <FormLabel fontSize="xs" fontWeight="bold">
                          {key.replace(/_/g, " ")}
                        </FormLabel>
                        <Input
                          {...field}
                          fontSize="sm"
                          placeholder={key}
                          isReadOnly={index === 0}
                          backgroundColor={
                            index === 0 ? "gray.800" : "gray.700"
                          }
                          color={"white"}
                        />
                      </FormControl>
                    )}
                  </Field>
                ))}
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    backgroundColor="green.500"
                    color={"#000000"}
                    w="100%"
                    type="submit"
                    isLoading={props.isSubmitting}
                    disabled={props.isSubmitting}
                  >
                    ยืนยัน
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UpdatePaymentGatewaySettingModal;
