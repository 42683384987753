import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import { Element } from "react-scroll";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { ERROR_MESSAGE } from "variables/system";
import { Formik, Form } from "formik";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import {
  fetchWebSetting,
  updateWebSetting,
} from "services/website-setting/webSettingService";

const WebSetting = ({ pageName }) => {
  const [webSettingData, setWebSettingData] = useState(null);
  const [webSiteUrl, setWebsiteUrl] = useState("");
  const [iframeKey, setIframeKey] = useState(0);

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const fetchWebSettingData = () => {
    fetchWebSetting()
      .then((response) => {
        setWebSettingData(response.data.data);
        setWebsiteUrl(response.data.data?.website);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const handleSubmit = (values) => {
    const req = { ...webSettingData, ...values };
    updateWebSetting(req)
      .then((response) => {
        if (response.status === 200) {
          handleSuccess(response.data.message);
          setIframeKey((prevKey) => prevKey + 1);
        }
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    fetchWebSettingData();
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }} overflow="hidden">
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
        </AnimatedFlex>
        <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
          <GridItem>
            <Stack direction="column" spacing="24px" w="100%">
              <Formik
                initialValues={{
                  backgroundwalletImg:
                    webSettingData?.backgroundwalletImg || "",
                  backgroundMoneyImg: webSettingData?.backgroundMoneyImg || "",
                  walletColorCode: webSettingData?.walletColorCode || "#ffffff",
                  walletColor2Code:
                    webSettingData?.walletColor2Code || "#ffffff",
                  logo: webSettingData?.logo || "",
                }}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <AnimatedFlex duration={0.4} delay={0.6}>
                      <Card
                        w={{ sm: "100%" }}
                        alignSelf="flex-end"
                        justifySelf="flex-end"
                      >
                        <Element id="decoration" name="decoration">
                          <CardBody>
                            <Grid
                              templateColumns={{
                                base: "1fr",
                                sm: "repeat(2, 1fr)",
                                md: "repeat(2, 1fr)",
                              }}
                              gap="24px"
                              w="100%"
                            >
                              <GridItem colSpan={2}>
                                <FormControl>
                                  <FormLabel
                                    fontWeight="semibold"
                                    fontSize="xs"
                                    mb="10px"
                                  >
                                    โลโก้{" "}
                                    <Box
                                      as="span"
                                      color="green.500"
                                      fontWeight="normal"
                                    >
                                      (ขนาด 100 x 100 px)
                                    </Box>
                                  </FormLabel>
                                  <ImageUploader
                                    initialImage={values.logo}
                                    onUpload={(uri) =>
                                      setFieldValue("logo", uri)
                                    }
                                  />
                                </FormControl>
                              </GridItem>
                              <GridItem colSpan={2}>
                                <FormControl>
                                  <FormLabel
                                    fontWeight="semibold"
                                    fontSize="xs"
                                    mb="10px"
                                  >
                                    ภาพพื้นหลัง{" "}
                                    <Box
                                      as="span"
                                      color="green.500"
                                      fontWeight="normal"
                                    >
                                      (ขนาด 1920x1080 px)
                                    </Box>
                                  </FormLabel>
                                  <ImageUploader
                                    initialImage={values.backgroundwalletImg}
                                    onUpload={(uri) =>
                                      setFieldValue("backgroundwalletImg", uri)
                                    }
                                  />
                                </FormControl>
                              </GridItem>
                              <GridItem colSpan={2}>
                                <FormControl>
                                  <FormLabel
                                    fontWeight="semibold"
                                    fontSize="xs"
                                    mb="10px"
                                  >
                                    ภาพพื้นหลังโทรศัพท์{" "}
                                    <Box
                                      as="span"
                                      color="green.500"
                                      fontWeight="normal"
                                    >
                                      (ขนาด 381 x 768 px)
                                    </Box>
                                  </FormLabel>
                                  <ImageUploader
                                    initialImage={values.backgroundMoneyImg}
                                    onUpload={(uri) =>
                                      setFieldValue("backgroundMoneyImg", uri)
                                    }
                                  />
                                </FormControl>
                              </GridItem>
                            </Grid>
                          </CardBody>
                        </Element>
                      </Card>
                    </AnimatedFlex>
                    <AnimatedFlex
                      justify="flex-end"
                      pt="15px"
                      duration={0.4}
                      delay={0.6}
                    >
                      <Button
                        type="submit"
                        backgroundColor="green.500"
                        color="black"
                        width="100%"
                        borderRadius="6px"
                      >
                        บันทึกข้อมูล
                      </Button>
                    </AnimatedFlex>
                  </Form>
                )}
              </Formik>
            </Stack>
          </GridItem>
          <GridItem display={{ base: "none", md: "block" }}>
            <Stack direction="column" spacing="24px" w="100%">
              <AnimatedFlex duration={0.4} delay={0.8}>
                <Card>
                  <Grid
                    templateColumns={{
                      base: "1fr",
                      sm: "repeat(2, 1fr)",
                      md: "repeat(2, 1fr)",
                    }}
                    gap="24px"
                    w="100%"
                  >
                    <GridItem colSpan={2}>
                      <FormLabel
                        fontWeight="semibold"
                        fontSize="xs"
                        mb="10px"
                        textAlign="center"
                        w="100%"
                      >
                        Desktop View{" "}
                        <Box as="span" color="green.500" fontWeight="normal">
                          (1920px x 1080px)
                        </Box>
                      </FormLabel>
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        <Box
                          style={{
                            width: "720px",
                            height: "405px",
                            transformOrigin: "top left",
                            overflow: "hidden",
                          }}
                        >
                          <iframe
                            key={iframeKey}
                            src={webSiteUrl}
                            width="1920px"
                            height="1080px"
                            style={{
                              border: "none",
                              transform: "scale(0.375)",
                              transformOrigin: "top left",
                            }}
                            title="Desktop Website View"
                          />
                        </Box>
                      </Flex>
                    </GridItem>
                    <GridItem colSpan={{ base: 1, sm: 2, md: 1 }}>
                      <FormLabel
                        fontWeight="semibold"
                        fontSize="xs"
                        mb="10px"
                        textAlign="center"
                        pt={2}
                        pb={2}
                        w="100%"
                      >
                        Mobile View{" "}
                        <Box as="span" color="green.500" fontWeight="normal">
                          (430px x 932px)
                        </Box>
                      </FormLabel>
                      <Flex justifyContent="center">
                        <Box
                          style={{
                            width: "215px",
                            height: "466px",
                            transformOrigin: "top left",
                            overflow: "hidden",
                          }}
                        >
                          <iframe
                            key={iframeKey}
                            src={webSiteUrl}
                            width="430px"
                            height="932px"
                            style={{
                              border: "none",
                              transform: "scale(0.5)",
                              transformOrigin: "top left",
                            }}
                            title="Mobile Website View"
                          />
                        </Box>
                      </Flex>
                    </GridItem>
                    <GridItem colSpan={{ base: 1, sm: 2, md: 1 }}>
                      <FormLabel
                        fontWeight="semibold"
                        fontSize="xs"
                        mb="10px"
                        textAlign="center"
                        pt={2}
                        pb={2}
                        w="100%"
                      >
                        Tablet View{" "}
                        <Box as="span" color="green.500" fontWeight="normal">
                          (430px x 932px)
                        </Box>
                      </FormLabel>
                      <Box
                        style={{
                          width: "384px",
                          height: "512px",
                          transformOrigin: "top left",
                          overflow: "hidden",
                        }}
                      >
                        <iframe
                          key={iframeKey}
                          src={webSiteUrl}
                          width="768px"
                          height="1024px"
                          style={{
                            border: "none",
                            transform: "scale(0.5)",
                            transformOrigin: "top left",
                          }}
                          title="Tablet Website View"
                        />
                      </Box>
                    </GridItem>
                  </Grid>
                </Card>
              </AnimatedFlex>
            </Stack>
          </GridItem>
        </Grid>
      </Stack>
    </Flex>
  );
};

export default WebSetting;
