import { callapi } from "../../utils/APIUtils";

export const fetchMemberInfo = (payload) => {
  return callapi("/api/admin/webuser/getInfo", "POST", payload);
};

export const fetchMemberTransactionGraph = (payload) => {
  return callapi("/api/admin/webuser/getGraph", "POST", payload);
};

export const fetchMemberTop20DepositHistory = (payload) => {
  return callapi(
    "/api/admin/search/list/depositHistory/top20",
    "POST",
    payload,
  );
};

export const updateMemberTurnover = (payload) => {
  return callapi("/api/admin/clearturnover", "POST", payload);
};

export const updateMemberMinWithdraw = (payload) => {
  return callapi("/api/admin/updateminwithdraw", "POST", payload);
};

export const createWithdrawCredit = (payload) => {
  return callapi("/api/admin/withdraw", "POST", payload);
};
