import { Badge, Flex, IconButton, Text, useClipboard } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { HiOutlineSquare2Stack } from "react-icons/hi2";

export const commissionReportColumnsData = () => {
  return [
    {
      Header: "สถานะ",
      accessor: "status",
      Cell: ({ cell }) => (
        <Flex align="center" justify="center">
          <Badge
            colorScheme={cell.value === "SUCCESS" ? "green" : "red"}
            w="65px"
            h="28px"
            borderRadius="15px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {cell.value}
          </Badge>
        </Flex>
      ),
    },
    {
      Header: "ยูสเซอร์",
      accessor: "username",
      Cell: ({ row }) => {
        const history = useHistory();
        const { onCopy } = useClipboard(row.original.username);

        return (
          <Flex align="center">
            <IconButton
              icon={<HiOutlineSquare2Stack />}
              onClick={onCopy}
              aria-label="Copy link"
              variant="outline"
              size="sm"
              border="none"
              mr={2}
            />
            <Text
              color={row.original.memberTagsColor}
              cursor="pointer"
              _hover={{ textDecoration: "underline" }}
              onClick={() =>
                history.push(`/admin/member/${row.original.username}`)
              }
            >
              {row.original.username}
            </Text>
          </Flex>
        );
      },
    },
    {
      Header: "ยอด",
      accessor: "amount",
    },
    {
      Header: "ประเภท",
      accessor: "type",
    },
    {
      Header: "หมายเหตุ",
      accessor: "reason",
    },
    {
      Header: "วันที่ทำรายการ",
      accessor: "createdDate",
    },
  ];
};
