import { callapi } from "../../utils/APIUtils";

export const addMemberTag = (payload) => {
  return callapi("/api/admin/membertags/add", "POST", payload);
};

export const fetchMemberTag = () => {
  return callapi("/api/admin/membertags", "POST", {});
};

export const updateMemberTag = (payload) => {
  return callapi("/api/admin/membertags/update", "POST", payload);
};

export const deleteMemberTag = (id) => {
  return callapi(`/api/admin/membertags/${id}`, "DELETE", {});
};
