import { Flex, Icon, IconButton, Image, Switch, Text } from "@chakra-ui/react";
import { HiOutlineCog8Tooth } from "react-icons/hi2";
import { paymentGateway } from "../../paymentGateway";

export const paymentGatewaySettingColumnsData = (
  handleModalOpen,
  onToggleColumn,
) => [
  {
    Header: "โลโก้ผู้ให้บริการ",
    Cell: ({ cell, row }) => (
      <Flex align="center" justify="center" w="100%">
        <Image
          src={`${paymentGateway[row.original.payment]?.logo}`}
          alt={cell.value}
          boxSize="30px"
          borderRadius="50%"
          boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
        />
      </Flex>
    ),
    disableSortBy: true,
  },
  {
    Header: "ผู้ให้บริการ",
    accessor: "provider",
    disableSortBy: true,
    Cell: ({ row }) => (
      <Text>{paymentGateway[row.original.payment]?.nice_name}</Text>
    ),
  },
  {
    Header: "สถานะเปิดใช้งาน",
    accessor: "active",
    Cell: ({ row }) => (
      <Switch
        size="md"
        colorScheme="green"
        onChange={() =>
          onToggleColumn({
            id: row.original.id,
            payment: row.original.payment,
            active: !row.original.active,
          })
        }
        isChecked={row.original.active}
      />
    ),
    disableSortBy: true,
  },
  {
    Header: "ตั้งค่า",
    Cell: ({ row }) => (
      <IconButton
        variant="outline"
        borderColor={"blue.500"}
        color={"blue.500"}
        aria-label="edit"
        size="sm"
        icon={<Icon as={HiOutlineCog8Tooth} />}
        onClick={() =>
          handleModalOpen("updatePaymentGatewayModal", row.original)
        }
      >
        แก้ไข
      </IconButton>
    ),
    disableSortBy: true,
  },
];
