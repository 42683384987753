import { Box, Flex, Spacer, Spinner, Stack, Text } from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import moment from "moment/moment";
import CardBody from "components/Card/CardBody";
import { QueryClient, QueryClientProvider } from "react-query";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import React, { useEffect, useState } from "react";
import { fetchJackpotHistory } from "services/system-setting/jackpotSettingService";
import { jackpotReportColumnsData } from "variables/columns/website-report/jackpotReportColumnsData";
import { getReferenceDates } from "../../../utils/DateformatUtils";
import { mapJsonToTableData } from "../../../utils/CommonUtils";
import useDateRange from "../../../hooks/useDateRange";
import FileExportDropdown from "../../../components/FileExportDropdown/FileExportDropdown";

const JackpotReport = ({ pageName }) => {
  const queryClient = new QueryClient();

  const { dateNow, dateDayCheck } = getReferenceDates();

  const [search, setSearch] = useState({});
  const [downloadableData, setDownloadableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };

  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const { selectedDates, handleDateChange } = useDateRange(
    [dateDayCheck, dateNow],
    search,
    setSearch,
    dateFieldsNames,
    dateSearchFormat,
  );

  useEffect(() => {
    setSearch({
      [dateFieldsNames.from]: moment(dateDayCheck).format(dateSearchFormat),
      [dateFieldsNames.to]: moment(dateNow).format(dateSearchFormat),
    });

    setIsLoading(false);
  }, []);

  const fetchData = async (pageIndex, pageSize, search) => {
    const response = await fetchJackpotHistory(pageIndex, pageSize, search);
    const formattedRecords = mapJsonToTableData(
      response.results,
      jackpotReportColumnsData,
    );

    setDownloadableData(formattedRecords);
    return response;
  };

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }} overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontSize="lg" fontWeight="bold">
            {pageName}
          </Text>
          <Box>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Box>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.8}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column" justifyContent="center">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  ประวัติการแจกแจ๊คพ๊อต
                </Text>
              </Flex>
              <Spacer />
              <FileExportDropdown
                data={downloadableData}
                filename={"รายงานประวัติการแจกแจ๊คพ๊อต"}
              />
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <QueryClientProvider client={queryClient}>
                  <SearchTableRemote
                    columnsData={jackpotReportColumnsData}
                    fetchData={fetchData}
                    search={search}
                    setSearch={setSearch}
                  />
                </QueryClientProvider>
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default JackpotReport;
