import { Flex, Spinner, Stack, Text } from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import { memberLoginHistoryColumnsData } from "variables/columns/website-report/memberLoginHistoryColumnsData";
import React, { useEffect, useState } from "react";

import { ERROR_MESSAGE } from "variables/system";
import SearchTable1 from "components/Tables/SearchTable1";
import { useErrorHandler } from "hooks/useErrorHandler";
import { fetchMemberLoginReport } from "services/website-report/memberLoginReportService";

const MemberLoginReport = ({ pageName }) => {
  const [memberLoginHistoryData, setMemberLoginHistoryData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const handleError = useErrorHandler();

  useEffect(() => {
    fetchMemberLoginReport()
      .then((response) => {
        setMemberLoginHistoryData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleError(error, ERROR_MESSAGE);
      });
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }} overflow="hidden">
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  ประวัติการเข้าสู่ระบบ
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <SearchTable1
                  tableData={memberLoginHistoryData}
                  columnsData={memberLoginHistoryColumnsData}
                />
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default MemberLoginReport;
