import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Grid,
  GridItem,
  Text,
  useBreakpointValue,
  Stack,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";

import { ERROR_MESSAGE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import { validateField, validatePassword } from "../../../../utils/CommonUtils";
import {
  addStaff,
  fetchStaffRole,
  updateStaff,
} from "services/system-setting/staffSettingService";
import { useSuccessHandler } from "hooks/useSuccessHandler";

const ManageStaffModal = ({ isOpen, onClose, staff = null, onSuccess }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const [staffRoles, setStaffRoles] = useState([]);

  const modalSize = useBreakpointValue({ base: "full", md: "xl" });

  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();

  useEffect(() => {
    fetchStaffRole()
      .then((response) => {
        setStaffRoles(response.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  }, []);

  const handleSaveStaff = (values, actions) => {
    const payload = staff ? { ...staff, ...values } : values;

    const response = staff ? updateStaff(payload) : addStaff(payload);

    response
      .then((response) => {
        onClose();
        if (response.status === 200) {
          handleSuccess(response.data.message);
          // setSearch()
          onSuccess();
        }
      })
      .catch((error) => {
        onClose();
        handleError(error, ERROR_MESSAGE);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={modalSize}
    >
      <ModalOverlay />
      <ModalContent
        maxH="100vh"
        p={8}
        borderRadius={{ base: "none", md: "md" }}
      >
        <ModalHeader>
          <Box textAlign="center">
            <Text fontSize="26px">
              {staff ? `แก้ไขพนักงาน: ${staff?.username}` : "เพิ่มพนักงาน"}
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <Formik
            initialValues={{
              username: staff?.username || "",
              password: staff?.password || "",
              tel: staff?.tel || "",
              fullName: staff?.fullName || "",
              limitFlag: staff?.limitFlag || 0,
              limit: staff?.limit || "",
              active: staff?.active || 0,
              roleCode: staff?.role || "",
            }}
            onSubmit={(values, actions) => {
              handleSaveStaff(values, actions);
            }}
          >
            {(props) => (
              <Form>
                <Grid
                  templateColumns={{ base: "1fr", md: "repeat(1, 1fr)" }}
                  gap={4}
                >
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field name="fullName" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.fullName && form.touched.fullName
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ชื่อ-นามสกุล
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="ชื่อ-นามสกุล"
                            fontSize="sm"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field name="username" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.username && form.touched.username
                          }
                          isRequired={!staff}
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ยูสเซอร์เนม
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="ยูสเซอร์เนม"
                            readOnly={!!staff}
                            fontSize="sm"
                            backgroundColor={staff ? "gray.800" : ""}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field
                      name="password"
                      validate={(value) => validatePassword(value, staff)}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.password && form.touched.password
                          }
                          isRequired={!staff}
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            พาสเวิร์ด
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="พาสเวิร์ด"
                            type="password"
                            fontSize="sm"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field name="tel">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            เบอร์โทรศัพท์
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="เบอร์โทรศัพท์"
                            fontSize="sm"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field name="roleCode" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.roleCode && form.touched.roleCode
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ตำแหน่ง
                          </FormLabel>
                          <Select
                            {...field}
                            placeholder="เลือกตำแหน่ง"
                            fontSize="sm"
                          >
                            {staffRoles.map((roles) => (
                              <option key={roles.id} value={roles.roleName}>
                                {roles.roleName}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field name="limitFlag">
                      {({ field, form }) => (
                        <FormControl display="flex" alignItems="center">
                          <Stack>
                            <FormLabel
                              htmlFor="limit-flag"
                              mb="0"
                              fontSize="xs"
                              fontWeight="bold"
                            >
                              ตั้งค่าลิมิต
                            </FormLabel>
                            <Switch
                              id="limit-flag"
                              {...field}
                              isChecked={field.value === 1}
                              onChange={() =>
                                form.setFieldValue(
                                  "limitFlag",
                                  field.value === 1 ? 0 : 1,
                                )
                              }
                            />
                          </Stack>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  {props.values.limitFlag === 1 && (
                    <GridItem colSpan={{ base: 1, md: 1 }}>
                      <Field name="limit" validate={validateField}>
                        {({ field }) => (
                          <FormControl>
                            <Stack>
                              <FormLabel fontSize="xs" fontWeight="bold">
                                ลิมิตต่อวัน
                              </FormLabel>
                              <Input
                                {...field}
                                placeholder="จำนวนลิมิตต่อวัน"
                                type="number"
                                fontSize="sm"
                              />
                            </Stack>
                          </FormControl>
                        )}
                      </Field>
                    </GridItem>
                  )}
                  <GridItem>
                    <Field name="active">
                      {({ field, form }) => (
                        <FormControl display="flex" alignItems="center">
                          <Stack>
                            <FormLabel
                              htmlFor="is-active"
                              mb="0"
                              fontSize="xs"
                              fontWeight="bold"
                            >
                              Active
                            </FormLabel>
                            <Switch
                              id="is-active"
                              {...field}
                              isChecked={field.value === 1}
                              onChange={() =>
                                form.setFieldValue(
                                  "active",
                                  field.value === 1 ? 0 : 1,
                                )
                              }
                            />
                          </Stack>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                </Grid>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    color={"#000000"}
                    backgroundColor="green.500"
                    w="100%"
                    type="submit"
                    isLoading={props.isSubmitting}
                    disabled={props.isSubmitting}
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManageStaffModal;
