import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Image,
  Spinner,
  Text,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { memberRecentDepositTransactionColumnsData } from "variables/columns/member-management/memberRecentDepositTransaction";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import SimpleTable from "components/Tables/SimpleTable";

import { useErrorHandler } from "hooks/useErrorHandler";
import { ERROR_MESSAGE } from "variables/system";
import { banks } from "variables/bank";

import { HiOutlineUserCircle } from "react-icons/hi2";
import { HiOutlineKey } from "react-icons/hi";
import LineChart from "components/Charts/LineChart";

import {
  fetchMemberInfo,
  fetchMemberTop20DepositHistory,
  fetchMemberTransactionGraph,
} from "services/member-management/memberProfileService";
import { formatDate } from "../../../utils/DateformatUtils";
import WithdrawCreditModal from "./components/WithdrawCreditModal";
import MinWithdrawModal from "./components/MinWithdrawModal";
import ResetPasswordModal from "../../member-management/members/components/ResetPasswordModal";
import { createLineChartOptions } from "utils/ChartUtils";
import { getMemberLevelColorScheme } from "../../../utils/CommonUtils";
import ClearTurnoverModal from "./components/ClearTurnoverModal";

const MemberProfile = () => {
  const { username } = useParams();

  const [memberData, setMemberData] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);

  const [memberRecentDepositTransaction, setMemberRecentDepositTransaction] =
    useState([]);

  const handleError = useErrorHandler();

  const [modalState, setModalState] = useState({
    withdrawCreditModalOpen: false,
    minWithdrawModalOpen: false,
    resetPasswordModalOpen: false,
    clearTurnoverModalOpen: false,
  });

  const [chartOptions, setChartOptions] = useState({});
  const [memberLineData, setMemberLineData] = useState([]);

  const handleModalOpen = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
  };

  const fetchMemberProfileData = () => {
    const req = {
      username: username,
    };
    fetchMemberInfo(req)
      .then((response) => {
        if (response.status === 200) {
          setMemberData({
            ...response.data.data,
            username,
          });
        }
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const fetchMemberTransactionGraphData = () => {
    const req = {
      username: username,
    };

    setIsLoading2(true);
    fetchMemberTransactionGraph(req)
      .then((response) => {
        if (response.status === 200) {
          const newChartOptions = createLineChartOptions({
            xAxisCategories: response.data.data.labelsDeposit,
          });
          setChartOptions(newChartOptions);
          setMemberLineData([
            {
              name: "Deposit",
              data: response.data.data.dataDeposit,
            },
            {
              name: "Withdraw",
              data: response.data.data.dataWithdraw,
            },
          ]);
          setIsLoading2(false);
        }
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const fetchMemberRecentDepositTransaction = () => {
    const req = {
      username: username,
      page: 0,
      amount: "",
      bonus: "",
      beforeAmount: "",
      addCredit: "",
      createdDate: "",
      reason: "",
      totalSize: 0,
      sortField: "id",
      size: 20,
      sort: "D",
    };

    fetchMemberTop20DepositHistory(req)
      .then((response) => {
        if (response.status === 200) {
          setMemberRecentDepositTransaction(response.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    fetchMemberProfileData();
    fetchMemberTransactionGraphData();
    fetchMemberRecentDepositTransaction();
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }} overflow="hidden">
      <Grid templateColumns={{ base: "1fr", lg: "1fr 1.5fr" }} gap={4}>
        <GridItem>
          <AnimatedFlex duration={0.4} delay={0.4}>
            <Card p={8} borderRadius="lg" shadow="md">
              <Flex direction="column" align="center" mb={2}>
                <Icon as={HiOutlineUserCircle} w={16} h={16} mb={2} />
                <Badge
                  colorScheme={getMemberLevelColorScheme(
                    memberData?.memberLevelName,
                  )}
                  borderRadius="full"
                  px="4"
                  py="1"
                  mb={2}
                >
                  {memberData?.memberLevelName != null
                    ? memberData?.memberLevelName
                    : "-"}
                </Badge>
                <Text fontSize="lg" color={memberData?.memberTagsColor}>
                  {username}
                </Text>
                <Text fontSize="sm" color="rgba(116,116,116,1)" mb={2}>
                  {memberData?.name}
                </Text>
                <Text fontSize="md" color="green.500">
                  {memberData?.credit}
                </Text>
              </Flex>
              <Divider my={4} borderColor="#ffffff14" />
              <Grid templateColumns="1fr auto" gap={4} mb={4}>
                <Flex justify="space-between" align="center">
                  <Flex direction="column">
                    <Text fontSize="xs" fontWeight="bold" mb={2}>
                      ยูสเซอร์เนม
                    </Text>
                    <Text fontSize="sm">{username}</Text>
                  </Flex>
                  <Flex direction="column">
                    <Text fontSize="xs" fontWeight="bold" mb={2}>
                      ยูสเซอร์เนม Ufabet
                    </Text>
                    <Text fontSize="sm">{memberData?.ufaUsername}</Text>
                  </Flex>
                  <Flex direction="column">
                    <Text fontSize="xs" fontWeight="bold" mb={2}>
                      ยูสเซอร์เนม Betflix
                    </Text>
                    <Text fontSize="sm">{memberData?.betflixUsername}</Text>
                  </Flex>
                </Flex>
              </Grid>
              <Grid templateColumns="1fr auto" gap={4}>
                <Flex justify="space-between" align="center">
                  <Flex direction="column">
                    <Text fontSize="xs" fontWeight="bold" mb={2}>
                      รหัสผ่าน
                    </Text>
                    <Text fontSize="sm">******</Text>
                  </Flex>
                  <IconButton
                    variant="outline"
                    borderColor={"red.500"}
                    color={"red.500"}
                    aria-label="remove credit"
                    size="sm"
                    icon={<HiOutlineKey />}
                    onClick={() => handleModalOpen("resetPasswordModal")}
                  />
                </Flex>
              </Grid>
              <Divider my={4} borderColor="#ffffff14" />
              <Grid templateColumns="auto 1fr" gap={4} mb={4}>
                <Flex justify="space-between" align="center">
                  <Flex direction="column">
                    <Text fontSize="xs" fontWeight="bold" mb={2}>
                      ธนาคาร
                    </Text>
                    <Flex align="center">
                      <Image
                        src={`${banks[memberData?.bankName]?.logo}`}
                        backgroundColor={banks[memberData?.bankName]?.color}
                        boxSize="25px"
                        borderRadius="50%"
                        boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
                      />
                      <Text ml={3} fontSize="sm">
                        {banks[memberData?.bankName]?.name_th}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Grid>
              <Grid templateColumns="1fr 1fr" gap={4} mb={4}>
                <Box>
                  <Text fontWeight="bold" fontSize="xs" mb={2}>
                    เลขบัญชี
                  </Text>
                  <Text fontSize="sm">{memberData?.bankAccount}</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" fontSize="xs" mb={4}>
                    ชื่อ - นามสกุล
                  </Text>
                  <Text fontSize="sm">{memberData?.name}</Text>
                </Box>
              </Grid>
              <Grid templateColumns="1fr 1fr" gap={4}>
                <Box>
                  <Text fontWeight="bold" fontSize="xs" mb={2}>
                    แหล่งที่มา การตลาด
                  </Text>
                  <Text fontSize="sm">
                    {memberData?.marketing !== null
                      ? memberData?.marketing
                      : "-"}
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" fontSize="xs" mb={2}>
                    แหล่งที่มา แนะนำเพื่อน
                  </Text>
                  <Text fontSize="sm">
                    {" "}
                    {memberData?.affiliateName !== null
                      ? memberData?.affiliateName
                      : "-"}
                  </Text>
                </Box>
              </Grid>
              <Divider my={4} borderColor="#ffffff14" />
              <Grid templateColumns="1fr 1fr" gap={4}>
                <Box>
                  <Text fontWeight="bold" fontSize="xs" mb={2}>
                    สมัครสมาชิกเมื่อ
                  </Text>
                  <Text fontSize="sm">
                    {formatDate(memberData?.createDate)}
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight="bold" fontSize="xs" mb={2}>
                    เข้าใช้งานล่าสุด
                  </Text>
                  <Text fontSize="sm">
                    {formatDate(memberData?.lastLoginDate)}
                  </Text>
                </Box>
              </Grid>
            </Card>
          </AnimatedFlex>
        </GridItem>
        <GridItem>
          <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4}>
            <AnimatedFlex duration={0.4} delay={0.6}>
              <Card p={4} shadow="md">
                <Grid templateColumns="1fr 1fr" alignItems="center">
                  <Text fontWeight="bold">เครดิตปัจจุบัน</Text>
                  <Text textAlign="right" fontSize="2xl">
                    {memberData?.credit}
                  </Text>
                </Grid>
                <Divider my={4} borderColor="#ffffff14" />
                <Flex justify="space-between">
                  <Button
                    borderColor={"green.500"}
                    color={"green.500"}
                    width="100%"
                    variant="outline"
                    onClick={() => handleModalOpen("withdrawCreditModal")}
                  >
                    ถอนเงิน
                  </Button>
                </Flex>
              </Card>
            </AnimatedFlex>
            <AnimatedFlex duration={0.4} delay={0.7}>
              <Card p={4} shadow="md">
                <Grid templateColumns="1fr 1fr" alignItems="center">
                  <Text fontWeight="bold">เทริน์โอเวอร์</Text>
                  <Text textAlign="right" fontSize="2xl">
                    {memberData?.turnOver}
                  </Text>
                </Grid>
                <Divider my={4} borderColor="#ffffff14" />
                <Flex justify="space-between">
                  <Button
                    borderColor={"green.500"}
                    color={"green.500"}
                    width="100%"
                    variant="outline"
                    onClick={() => handleModalOpen("clearTurnoverModal")}
                  >
                    เคลียร์ยอดเทิร์น
                  </Button>
                </Flex>
              </Card>
            </AnimatedFlex>
            <AnimatedFlex duration={0.4} delay={0.8}>
              <Card p={4} shadow="md">
                <Grid templateColumns="1fr 1fr" alignItems="center">
                  <Text fontWeight="bold">ถอนขั้นต่ำ</Text>
                  <Text textAlign="right" fontSize="2xl">
                    {memberData?.minWithdraw}
                  </Text>
                </Grid>
                <Divider my={4} borderColor="#ffffff14" />
                <Flex justify="space-between">
                  <Button
                    borderColor={"green.500"}
                    color={"green.500"}
                    width="100%"
                    variant="outline"
                    onClick={() => handleModalOpen("minWithdrawModal")}
                  >
                    อัพเดทถอนขั้นต่ำ
                  </Button>
                </Flex>
              </Card>
            </AnimatedFlex>
          </Grid>
          <Grid mb={2}>
            <AnimatedFlex duration={0.4} delay={1.0}>
              <Card px="0px" pb="0px" mt={4} h="100%">
                <CardHeader
                  mb="22px"
                  pb="12px"
                  pt="12px"
                  px="22px"
                  className="card-header-decorator card-header-bg"
                >
                  <Text color="white" fontSize="lg" fontWeight="bold">
                    ฝาก/ถอน
                  </Text>
                </CardHeader>
                <CardBody h="100%">
                  <Box w="100%" h="100%">
                    {isLoading2 ? null : (
                      <LineChart
                        chartData={memberLineData}
                        chartOptions={chartOptions}
                      />
                    )}
                  </Box>
                </CardBody>
              </Card>
            </AnimatedFlex>
          </Grid>
        </GridItem>
      </Grid>
      <AnimatedFlex duration={0.4} delay={1.2}>
        <Card px="0px" mt="24px">
          <CardHeader
            px="22px"
            pb="12px"
            pt="12px"
            className="card-header-decorator card-header-bg"
          >
            <Flex direction="column">
              <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                รายการฝากล่าสุด
              </Text>
            </Flex>
          </CardHeader>
          <CardBody>
            {isLoading ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                height="400px"
                w="100%"
              >
                <Spinner color="green.500" size="xl" />
              </Flex>
            ) : (
              <SimpleTable
                tableData={memberRecentDepositTransaction}
                columnsData={memberRecentDepositTransactionColumnsData}
                rowSize={5}
              />
            )}
          </CardBody>
        </Card>
      </AnimatedFlex>
      <WithdrawCreditModal
        isOpen={modalState.withdrawCreditModalOpen}
        onClose={() => handleModalClose("withdrawCreditModal")}
        username={username}
        onSuccess={fetchMemberProfileData}
      />
      <MinWithdrawModal
        isOpen={modalState.minWithdrawModalOpen}
        onClose={() => handleModalClose("minWithdrawModal")}
        username={username}
        onSuccess={fetchMemberProfileData}
      />
      <ClearTurnoverModal
        isOpen={modalState.clearTurnoverModalOpen}
        onClose={() => handleModalClose("clearTurnoverModal")}
        username={username}
        onSuccess={fetchMemberProfileData}
      />
      <ResetPasswordModal
        isOpen={modalState.resetPasswordModalOpen}
        onClose={() => handleModalClose("resetPasswordModal")}
        setSearch={fetchMemberProfileData}
        member={memberData}
      />
    </Flex>
  );
};

export default MemberProfile;
