import {
  Box,
  Button,
  Flex,
  Icon,
  Spacer,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { membersColumnsData } from "variables/columns/member-management/membersColumnsData";

import SearchTableRemote from "components/Tables/SearchTableRemote";

import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import AddCreditModal from "./components/AddCreditModal";
import AddCreditSlipModal from "./components/AddCreditSlipModal";

import RemoveCreditModal from "./components/RemoveCreditModal";
import ResetPasswordModal from "./components/ResetPasswordModal";
import { HiOutlinePlusSm } from "react-icons/hi";
import ManageMemberModal from "./components/ManageMemberModal";

import { QueryClientProvider, QueryClient } from "react-query";
import {
  fetchMarketingMembers,
  fetchMembers,
} from "services/member-management/memberService";
import { fetchMemberInfo } from "services/member-management/memberProfileService";
import { useErrorHandler } from "hooks/useErrorHandler";
import { ERROR_MESSAGE } from "variables/system";
import { mapJsonToTableData } from "utils/CommonUtils";
import useDateRange from "../../../hooks/useDateRange";
import { getReferenceDates } from "../../../utils/DateformatUtils";
import FileExportDropdown from "../../../components/FileExportDropdown/FileExportDropdown";

const Members = ({ pageName }) => {
  const queryClient = new QueryClient();
  const { dateNow, dateDayCheck } = getReferenceDates();

  const [modalState, setModalState] = useState({
    manageMemberModalOpen: false,
    addCreditModalOpen: false,
    addCreditSlipModalOpen: false,
    removeCreditModalOpen: false,
    resetPasswordModalOpen: false,
  });

  const [selectedMember, setSelectedMember] = useState(null);
  const [downloadableData, setDownloadableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState({});
  const handleError = useErrorHandler();
  const role = JSON.parse(localStorage.getItem("APP_PROFILE")).role;

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };

  const { selectedDates, handleDateChange } = useDateRange(
    [dateDayCheck, dateNow],
    search,
    setSearch,
    dateFieldsNames,
    "DD/MM/yyyy HH:mm",
  );

  const handleModalOpen = (modal, user = null) => {
    setSelectedMember(user);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedMember(null);
  };

  const handleRefreshCredit = (username) => {
    fetchMemberInfo({ username })
      .then((response) => {
        setSearch({ ...search, username: response.data.data.credit });
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const fetchData = async (pageIndex, pageSize, search) => {
    const response =
      role !== "MARKETING"
        ? await fetchMembers(pageIndex, pageSize, search)
        : await fetchMarketingMembers(pageIndex, pageSize, search);

    const formattedRecords = mapJsonToTableData(
      response.results,
      membersColumnsData(handleModalOpen, handleRefreshCredit),
    );

    setDownloadableData(formattedRecords);
    return response;
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }} overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontSize="lg" fontWeight="bold">
            {pageName}
          </Text>
          <Box>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Box>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.8}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column" justifyContent="center">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  จัดการสมาชิกทั้งหมด
                </Text>
              </Flex>
              <Spacer />
              <Box>
                <Button
                  backgroundColor="green.500"
                  color={"#000000"}
                  width={{ base: "100%", md: "auto" }}
                  // minW={{ base: "none", md: "280px" }}
                  borderRadius="6px"
                  onClick={() => handleModalOpen("manageMemberModal")}
                >
                  <Icon as={HiOutlinePlusSm} mr="5px" />
                  เพิ่มสมาชิก
                </Button>
                <FileExportDropdown
                  data={downloadableData}
                  filename={"สมาชิคใหม่ทั้งหมด"}
                />
              </Box>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <QueryClientProvider client={queryClient}>
                  <SearchTableRemote
                    query={"members"}
                    columnsData={membersColumnsData(
                      handleModalOpen,
                      handleRefreshCredit,
                    )}
                    fetchData={fetchData}
                    search={search}
                    setSearch={setSearch}
                  />
                </QueryClientProvider>
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
        <ManageMemberModal
          isOpen={modalState.manageMemberModalOpen}
          onClose={() => handleModalClose("manageMemberModal")}
          setSearch={setSearch}
          member={selectedMember}
        />
        <AddCreditModal
          isOpen={modalState.addCreditModalOpen}
          onClose={() => handleModalClose("addCreditModal")}
          setSearch={setSearch}
          member={selectedMember}
        />
        <AddCreditSlipModal
          isOpen={modalState.addCreditSlipModalOpen}
          onClose={() => handleModalClose("addCreditSlipModal")}
          setSearch={setSearch}
          member={selectedMember}
        />
        <RemoveCreditModal
          isOpen={modalState.removeCreditModalOpen}
          onClose={() => handleModalClose("removeCreditModal")}
          setSearch={setSearch}
          member={selectedMember}
        />
        <ResetPasswordModal
          isOpen={modalState.resetPasswordModalOpen}
          onClose={() => handleModalClose("resetPasswordModal")}
          setSearch={setSearch}
          member={selectedMember}
        />
      </Stack>
    </Flex>
  );
};

export default Members;
