import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { Button, Flex, Icon, Spinner, Stack, Text } from "@chakra-ui/react";
import CardBody from "components/Card/CardBody";
import SearchTable1 from "components/Tables/SearchTable1";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import React, { useEffect, useState } from "react";
import { staffSettingColumnsData } from "variables/columns/system-setting/staffSettingColumnsData";
import { HiOutlinePlusSm } from "react-icons/hi";

import { ERROR_MESSAGE, MARKETING_ROLE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import ManageStaffModal from "./components/ManageStaffModal";
import { fetchStaff } from "services/system-setting/staffSettingService";

const StaffSetting = ({ pageName }) => {
  const [staffData, setStaffData] = useState([]);

  const handleError = useErrorHandler();

  const [isLoading, setIsLoading] = useState(true);

  const [modalState, setModalState] = useState({
    manageStaffModalOpen: false,
  });

  const [selectedStaff, setSelectedStaff] = useState(null);

  const handleModalOpen = (modal, staff = null) => {
    setSelectedStaff(staff);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedStaff(null);
  };

  const fetchStaffData = () => {
    setIsLoading(true);
    fetchStaff()
      .then((response) => {
        const filtered = response.data.data.filter(
          (item) => item.role !== MARKETING_ROLE,
        ); // check
        setStaffData(filtered);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    fetchStaffData();
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }} overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
          <Button
            color={"#000000"}
            backgroundColor="green.500"
            width={{ base: "100%", md: "auto" }}
            minW={{ base: "none", md: "280px" }}
            borderRadius="6px"
            onClick={() => handleModalOpen("manageStaffModal")}
          >
            <Icon as={HiOutlinePlusSm} mr="5px" />
            เพิ่มพนักงาน
          </Button>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.8}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  ตั้งค่าพนักงาน
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <SearchTable1
                  tableData={staffData}
                  columnsData={staffSettingColumnsData(handleModalOpen)}
                />
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
        <ManageStaffModal
          isOpen={modalState.manageStaffModalOpen}
          onClose={() => handleModalClose("manageStaffModal")}
          staff={selectedStaff}
          onSuccess={fetchStaffData}
        />
      </Stack>
    </Flex>
  );
};

export default StaffSetting;
