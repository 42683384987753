import React from "react";
import {
  Box,
  Image,
  Text,
  Flex,
  Icon,
  Switch,
  Divider,
} from "@chakra-ui/react";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";
import { getDeviceText } from "../../../../utils/CommonUtils";

const PopupCard = ({ popup, onEdit, onDelete }) => {
  return (
    <Box
      borderRadius="lg"
      overflow="hidden"
      p={0}
      backgroundColor="gray.700"
      boxShadow="lg"
    >
      <Image
        src={popup.image || "https://via.placeholder.com/1040"}
        alt={`${popup.name} image`}
        width="100%"
        height="150px"
        objectFit="cover"
      />

      <Box p={4}>
        <Flex justify="space-between" align="center">
          <Text fontSize="xl" fontWeight="bold">
            {popup.name}
          </Text>
          <Flex mt={4}>
            <Icon
              as={HiOutlineTrash}
              w={5}
              h={5}
              mx={2}
              cursor="pointer"
              color="red.500"
              onClick={onDelete}
            />
            <Icon
              as={HiOutlinePencil}
              w={5}
              h={5}
              mx={2}
              cursor="pointer"
              color="green.500"
              onClick={onEdit}
            />
          </Flex>
        </Flex>
        <Flex justify="space-between" mt={2}>
          <Text fontWeight="medium" fontSize="sm">
            คอนเทนต์
          </Text>
          <Text fontSize="sm">{popup.content}</Text>
        </Flex>
        <Flex justify="space-between" mt={2}>
          <Text fontWeight="medium" fontSize="sm">
            ลำดับ
          </Text>
          <Text fontSize="sm">{popup.sort}</Text>
        </Flex>
        <Flex justify="space-between" mt={2}>
          <Text fontWeight="medium" fontSize="sm">
            อุปกรณ์
          </Text>
          <Text fontSize="sm">{getDeviceText(popup.device)}</Text>
        </Flex>
        <Flex justify="space-between" mt={2}>
          <Text fontWeight="medium" fontSize="sm">
            ภาษา
          </Text>
          <Text fontSize="sm">{popup.language}</Text>
        </Flex>
        <Flex justify="space-between" mt={2}>
          <Text fontWeight="medium" fontSize="sm">
            วันที่เริ่มต้น
          </Text>
          <Text fontSize="sm">{popup.startDate}</Text>
        </Flex>
        <Flex justify="space-between" mt={2}>
          <Text fontWeight="medium" fontSize="sm">
            วันที่สิ้นสุด
          </Text>
          <Text fontSize="sm">{popup.endDate}</Text>
        </Flex>

        <Divider my={4} borderColor="#ffffff14" />

        <Flex
          justify="space-between"
          mt={2}
          p={2}
          border="1px"
          borderColor="whiteAlpha.300"
          borderRadius="md"
        >
          <Text fontWeight="medium" color="white" fontSize="sm">
            สถานะเปิดใช้งาน
          </Text>
          <Switch isChecked={popup.status === "true"} readOnly />
        </Flex>
      </Box>
    </Box>
  );
};

export default PopupCard;
