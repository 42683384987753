import {
  Badge,
  Flex,
  Icon,
  IconButton,
  Image,
  Spinner,
} from "@chakra-ui/react";
import { banks } from "../../bank";
import { HiOutlinePencil } from "react-icons/hi2";
import { formatDateWithoutMeridiem } from "../../../utils/DateformatUtils";
import ActionButtons from "../../../views/transaction-report/withdraw/components/ActionButtons";
import { getWithdrawSName } from "../../system";

const statusColors = {
  SUCCESS: "green",
  ERROR: "red",
  BLOCK_AUTO: "yellow",
  REJECT: "red",
  PENDING: "blue",
  SELF_TRANSFER: "green",
};

const StatusBadge = ({ status }) => {
  const colorScheme = statusColors[status] || "gray";

  return (
    <Flex align="center" justify="center">
      <Badge
        colorScheme={colorScheme}
        w="85px"
        h="28px"
        speed="0.8s"
        borderRadius="15px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {status === "PENDING" && <Spinner size="xs" mr={1} />}
        {getWithdrawSName(status)}
      </Badge>
    </Flex>
  );
};

export const withdrawTransactionColumnsData = (handleModalOpen) => {
  return [
    {
      Header: "สถานะ",
      accessor: "status",
      Cell: ({ cell }) => <StatusBadge status={cell.value} />,
    },
    {
      Header: "ยูสเซอร์",
      accessor: "username",
      disableSortBy: true,
    },
    {
      Header: "ยอดเงินถอน",
      accessor: "amount",
    },
    {
      Header: "ชื่อ-นามสกุล",
      accessor: "fullName",
      disableSortBy: true,
    },
    {
      Header: "เลขที่บัญชี",
      accessor: "accountNumber",
      disableSortBy: true,
    },
    {
      Header: "เครดิตก่อนถอน",
      accessor: "beforeAmount",
    },
    {
      Header: "เครดิตหลังถอน",
      accessor: "afterAmount",
    },
    {
      Header: "ธนาคารที่ใช้ถอน",
      Cell: ({ cell, row }) => (
        <Flex align="center" justify="center" w="100%">
          <Image
            src={`${banks[row.original.bankCode]?.logo}`}
            alt={cell.value}
            backgroundColor={banks[row.original.bankCode]?.color}
            boxSize="30px"
            borderRadius="50%"
            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
          />
        </Flex>
      ),
      disableSortBy: true,
    },
    {
      Header: "เครื่องมือ",
      Cell: ({ row }) => (
        <ActionButtons row={row} handleModalOpen={handleModalOpen} />
      ),
      disableSortBy: true,
    },
    {
      Header: "เงินในบัญชี",
      accessor: "remainBalance",
    },
    {
      Header: "เงินในบัญชีก่อนถอน",
      accessor: "beforeTransfer",
    },
    {
      Header: "เงินในบัญชีหลังถอน",
      accessor: "afterTransfer",
    },
    {
      Header: "ผู้ทำรายการ",
      accessor: "createdBy",
    },
    {
      Header: "แก้ไขโดย",
      accessor: "updatedBy",
    },
    {
      Header: "วันที่ทำรายการ",
      accessor: "createdDate",
      Cell: ({ value }) => formatDateWithoutMeridiem(value),
    },
    {
      Header: "วันที่อัพเดต",
      accessor: "updatedDate",
      Cell: ({ value }) => formatDateWithoutMeridiem(value),
    },
    {
      Header: "แก้ไข",
      Cell: ({ row }) => (
        <IconButton
          variant="outline"
          colorScheme="green"
          aria-label="edit"
          size="sm"
          icon={<Icon as={HiOutlinePencil} />}
          onClick={() =>
            handleModalOpen(
              "updateWithdrawTransactionStatusModal",
              row.original,
            )
          }
        >
          แก้ไข
        </IconButton>
      ),
      disableSortBy: true,
    },
  ];
};
