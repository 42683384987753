import { Flex, Image } from "@chakra-ui/react";
import { banks } from "../../bank";
import avatar8 from "assets/img/avatars/avatar8.png";
import { formatDate } from "../../../utils/DateformatUtils";
import EditButtons from "../../../views/transaction-report/invalid/components/EditButtons";

export const invalidTransactionReportColumnsData = (handleModalOpen) => {
  return [
    {
      Header: "ยอดเงินฝาก",
      accessor: "amount",
    },
    {
      Header: "ยอดโบนัส",
      accessor: "bonus",
      disableSortBy: true,
    },
    {
      Header: "ธนาคารที่โอนเข้า",
      Cell: ({ cell, row }) => (
        <Flex align="center" justify="center" w="100%">
          {row.original.bankCode ? (
            <Image
              src={`${banks[row.original.bankCode]?.logo}`}
              alt={cell.value}
              backgroundColor={banks[row.original.bankCode]?.color}
              boxSize="30px"
              borderRadius="50%"
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            />
          ) : (
            <Image
              src={avatar8}
              alt={cell.value}
              backgroundColor={banks[row.original.bankCode]?.color}
              boxSize="30px"
              borderRadius="50%"
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            />
          )}
        </Flex>
      ),
      disableSortBy: true,
    },
    {
      Header: "เพิ่มโดย",
      accessor: "createdBy",
    },
    {
      Header: "วันที่ทำรายการ",
      accessor: "createdDate",
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: "หมายเหตุ",
      accessor: "remark",
    },
    {
      Header: "แก้ไข",
      Cell: ({ row }) => (
        <Flex align="center" justify="center" w="100%">
          <EditButtons row={row} handleModalOpen={handleModalOpen} />
        </Flex>
      ),
    },
  ];
};
