import { Badge, Flex } from "@chakra-ui/react";

export const memberLoginHistoryColumnsData = [
  {
    Header: "สถานะ",
    accessor: "active",
    Cell: ({ cell }) => (
      <Flex align="center" justify="center">
        <Badge
          colorScheme={cell.value === true ? "green" : "red"}
          w="65px"
          h="28px"
          borderRadius="15px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {cell.value === true ? "ACTIVE" : "INACTIVE"}
        </Badge>
      </Flex>
    ),
  },
  {
    Header: "ยูสเซอร์",
    accessor: "username",
  },
  {
    Header: "เข้าสู่ระบบล่าสุด",
    accessor: "lastlogin",
  },
  {
    Header: "IP",
    accessor: "ip",
  },
];
