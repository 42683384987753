import {
  Flex,
  Icon,
  Input,
  Stack,
  Text,
  Image,
  useToast,
} from "@chakra-ui/react";
import CardBody from "../Card/CardBody";
import React, { useEffect, useState } from "react";
import { FiUpload } from "react-icons/fi";
import { fileUpload } from "../../utils/APIUtils";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { ERROR_MESSAGE } from "../../variables/system";

const ImageUploader = ({ initialImage, onUpload }) => {
  const [imageSrc, setImageSrc] = useState(initialImage);

  const toast = useToast();
  const handleError = useErrorHandler();

  useEffect(() => {
    setImageSrc(initialImage);
  }, [initialImage]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const data = new FormData();
      data.append("file", file);

      fileUpload("/api/file/uploadFile", data)
        .then((response) => {
          if (response.status === 200) {
            toast({
              title: "ทำรายการสำเร็จ",
              position: "top-right",
              description: response.data.message,
              status: "success",
              duration: 1000,
              isClosable: true,
            });
            const imageUri = response.data.data.fileDownloadUri;
            setImageSrc(imageUri);
            onUpload(imageUri);
          }
        })
        .catch((error) => {
          handleError(error, ERROR_MESSAGE);
        });
    }
  };

  return (
    <CardBody>
      <Flex
        align="center"
        justify="center"
        border="1px dashed #E2E8F0"
        borderRadius="6px"
        w="100%"
        minH="250px"
        cursor="pointer"
        position="relative"
        overflow="hidden"
        maxH="350px"
      >
        <Input
          type="file"
          accept="image/*"
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          opacity="0"
          cursor="pointer"
          onChange={handleImageUpload}
        />
        {imageSrc ? (
          <Image src={imageSrc} alt="Uploaded image" maxH="80%" />
        ) : (
          <Stack align="center">
            <Icon as={FiUpload} w={6} h={6} color="gray.400" />
            <Text color="gray.400" fontWeight="bold" fontSize="lg">
              อัพโหลด รูปประกาศ
            </Text>
            <Text color="gray.400" fontSize="xs">
              ลากและวางไฟล์ลงที่นี่
            </Text>
          </Stack>
        )}
      </Flex>
    </CardBody>
  );
};

export default ImageUploader;
