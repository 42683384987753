import { callapi } from "../../utils/APIUtils";

export const addBanner = (payload) => {
  return callapi("/api/admin/banner/v2/add", "POST", payload);
};

export const fetchBanner = () => {
  return callapi("/api/admin/banner", "POST", {});
};

export const updateBanner = (payload) => {
  return callapi("/api/admin/banner/v2/update", "POST", payload);
};

export const deleteBanner = (id) => {
  return callapi(`/api/admin/banner/${id}`, "DELETE", {});
};
