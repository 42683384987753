import { Badge, Flex, IconButton } from "@chakra-ui/react";
import { HiOutlinePencil } from "react-icons/hi2";
import { formatDate } from "../../../utils/DateformatUtils";

export const staffSettingColumnsData = (handleModalOpen) => [
  {
    Header: "สถานะ",
    accessor: "active",
    Cell: ({ cell }) => (
      <Flex align="center" justify="center">
        <Badge
          colorScheme={cell.value === 1 ? "green" : "red"}
          w="65px"
          h="28px"
          borderRadius="15px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {cell.value === 1 ? "ACTIVE" : "INACTIVE"}
        </Badge>
      </Flex>
    ),
  },
  {
    Header: "ชื่อ-นามสกุล",
    accessor: "fullName",
  },
  {
    Header: "ยูสเซอร์เนม",
    accessor: "username",
  },
  {
    Header: "เบอร์โทรศัพท์",
    accessor: "tel",
  },
  {
    Header: "ตำแหน่ง",
    accessor: "role",
  },
  {
    Header: "วันที่เริ่มทำงาน",
    accessor: "createdDate",
    Cell: ({ value }) => formatDate(value),
  },
  {
    Header: "แก้ไข",
    accessor: "edit",
    Cell: ({ row }) => (
      <IconButton
        variant="outline"
        colorScheme="green"
        aria-label="edit"
        size="sm"
        icon={<HiOutlinePencil />}
        onClick={() => handleModalOpen("manageStaffModal", row.original)}
      >
        แก้ไข
      </IconButton>
    ),
  },
];
