import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import { ERROR_MESSAGE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import { resetPassword } from "services/member-management/memberService";

const ResetPasswordModal = ({ isOpen, onClose, setSearch, member }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [responseData, setResponseData] = useState(null);

  const handleError = useErrorHandler();

  const handleConfirm = async () => {
    setIsLoading(true);
    resetPassword(member.id)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setResponseData(response.data);
          setShowSuccessModal(true);
          setSearch();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        onClose();
        handleError(error, ERROR_MESSAGE);
      });
  };

  const handleSuccessClose = () => {
    setShowSuccessModal(false);
    setResponseData(null);
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent p={8}>
          <ModalHeader>
            <Box textAlign="center">ยืนยันทำรายการ</Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody fontSize="sm">
            คุณแน่ใจจะเปลี่ยนรหัสผ่านให้กับ {member?.username}?
          </ModalBody>
          <ModalFooter>
            <Button
              backgroundColor="green.500"
              color={"#000000"}
              onClick={handleConfirm}
              isLoading={isLoading}
              w="100%"
            >
              ทำรายการ
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {responseData && (
        <Modal
          isOpen={showSuccessModal}
          onClose={handleSuccessClose}
          isCentered
        >
          <ModalOverlay />
          <ModalContent p={8}>
            <ModalHeader>
              <Box textAlign="center">เปลี่ยนรหัสผ่านใหม่สำเร็จ</Box>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody fontSize="sm">
              <Flex direction="column" align="left" justify="center">
                <Text>Username: {responseData.data.username}</Text>
                <Text>Password: {responseData.data.password}</Text>
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button
                backgroundColor="green.500"
                color="white"
                onClick={handleSuccessClose}
                w="100%"
              >
                ปิด
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ResetPasswordModal;
