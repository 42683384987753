import {
  GRAND_JACKPOT,
  MAJOR_JACKPOT,
  MINI_JACKPOT,
  MINOR_JACKPOT,
} from "../variables/system";

export const transformJackpotJsonToArray = (data) => {
  return [
    {
      id: 1,
      type: GRAND_JACKPOT,
      value: data.grand,
      rate: data.grandRate,
      current: data.grandCurrent,
      autoGrand: data.autoGrand,
      autoHourGrand: data.autoHourGrand,
      autoProfitGrand: data.autoProfitGrand,
      autoTurnGrand: data.autoTurnGrand,
    },
    {
      id: 2,
      type: MAJOR_JACKPOT,
      value: data.major,
      rate: data.majorRate,
      current: data.majorCurrent,
      autoMajor: data.autoMajor,
      autoHourMajor: data.autoHourMajor,
      autoProfitMajor: data.autoProfitMajor,
      autoTurnMajor: data.autoTurnMajor,
    },
    {
      id: 3,
      type: MINOR_JACKPOT,
      value: data.minor,
      rate: data.minorRate,
      current: data.minorCurrent,
      autoMinor: data.autoMinor,
      autoHourMinor: data.autoHourMinor,
      autoProfitMinor: data.autoProfitMinor,
      autoTurnMinor: data.autoTurnMinor,
    },
    {
      id: 4,
      type: MINI_JACKPOT,
      value: data.mini,
      rate: data.miniRate,
      current: data.miniCurrent,
      autoMini: data.autoMini,
      autoHourMini: data.autoHourMini,
      autoProfitMini: data.autoProfitMini,
      autoTurnMini: data.autoTurnMini,
    },
  ];
};

export const transformJackpotArrayToJson = (array) => {
  return {
    grand: array.find((item) => item.type === GRAND_JACKPOT)?.value,
    major: array.find((item) => item.type === MAJOR_JACKPOT)?.value,
    minor: array.find((item) => item.type === MINOR_JACKPOT)?.value,
    mini: array.find((item) => item.type === MINI_JACKPOT)?.value,

    grandRate: array.find((item) => item.type === GRAND_JACKPOT)?.rate,
    majorRate: array.find((item) => item.type === MAJOR_JACKPOT)?.rate,
    minorRate: array.find((item) => item.type === MINOR_JACKPOT)?.rate,
    miniRate: array.find((item) => item.type === MINI_JACKPOT)?.rate,

    grandCurrent: array.find((item) => item.type === GRAND_JACKPOT)?.current,
    majorCurrent: array.find((item) => item.type === MAJOR_JACKPOT)?.current,
    minorCurrent: array.find((item) => item.type === MINOR_JACKPOT)?.current,
    miniCurrent: array.find((item) => item.type === MINI_JACKPOT)?.current,

    // auto settings
    autoGrand: array.find((item) => item.type === GRAND_JACKPOT)?.autoGrand,
    autoHourGrand: array.find((item) => item.type === GRAND_JACKPOT)
      ?.autoHourGrand,
    autoProfitGrand: array.find((item) => item.type === GRAND_JACKPOT)
      ?.autoProfitGrand,
    autoTurnGrand: array.find((item) => item.type === GRAND_JACKPOT)
      ?.autoTurnGrand,

    autoMajor: array.find((item) => item.type === MAJOR_JACKPOT)?.autoMajor,
    autoHourMajor: array.find((item) => item.type === MAJOR_JACKPOT)
      ?.autoHourMajor,
    autoProfitMajor: array.find((item) => item.type === MAJOR_JACKPOT)
      ?.autoProfitMajor,
    autoTurnMajor: array.find((item) => item.type === MAJOR_JACKPOT)
      ?.autoTurnMajor,

    autoMinor: array.find((item) => item.type === MINOR_JACKPOT)?.autoMinor,
    autoHourMinor: array.find((item) => item.type === MINOR_JACKPOT)
      ?.autoHourMinor,
    autoProfitMinor: array.find((item) => item.type === MINOR_JACKPOT)
      ?.autoProfitMinor,
    autoTurnMinor: array.find((item) => item.type === MINOR_JACKPOT)
      ?.autoTurnMinor,

    autoMini: array.find((item) => item.type === MINI_JACKPOT)?.autoMini,
    autoHourMini: array.find((item) => item.type === MINI_JACKPOT)
      ?.autoHourMini,
    autoProfitMini: array.find((item) => item.type === MINI_JACKPOT)
      ?.autoProfitMini,
    autoTurnMini: array.find((item) => item.type === MINI_JACKPOT)
      ?.autoTurnMini,
  };
};
