import { Box, Flex, Spacer, Spinner, Stack, Text } from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import CardBody from "components/Card/CardBody";
import React, { useEffect, useState } from "react";
import { getReferenceDates } from "utils/DateformatUtils";
import { fetchAffiliateReport } from "services/member-report/affiliateReportService";
import { affiliateReportColumnsData } from "variables/columns/member-report/affiliateReportColumnsData";
import { ERROR_MESSAGE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import SearchTable1 from "components/Tables/SearchTable1";
import useDateRange from "hooks/useDateRange";

const AffiliateReport = ({ pageName }) => {
  const { dateNow, dateDayCheck } = getReferenceDates();
  const [isLoading, setIsLoading] = useState(true);
  const [affiliateReportData, setAffiliateReportData] = useState([]);
  const [search, setSearch] = useState({});

  const dateFieldsNames = {
    from: "listDateFrom",
    to: "listDateTo",
  };

  const handleError = useErrorHandler();
  const { selectedDates, handleDateChange } = useDateRange(
    [dateDayCheck, dateNow],
    search,
    setSearch,
    dateFieldsNames,
    "DD/MM/yyyy HH:mm:ss",
  );

  const fetchAffiliateReportData = () => {
    setIsLoading(true);
    fetchAffiliateReport(search)
      .then((response) => {
        setAffiliateReportData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleError(error, ERROR_MESSAGE);
      });
  };
  // need to double-check on init
  useEffect(() => {
    fetchAffiliateReportData();
  }, [search, setSearch]);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
          <Box>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Box>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text fontSize="18px" fontWeight="bold" mb="6px">
                  รายงานแนะนำเพื่อน
                </Text>
              </Flex>
              <Spacer />
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <SearchTable1
                  tableData={affiliateReportData}
                  columnsData={affiliateReportColumnsData()}
                  hideSearch={false}
                />
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default AffiliateReport;
