import { Box, Flex, Spacer, Spinner, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import "assets/css/styles.css";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import { QueryClient, QueryClientProvider } from "react-query";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import moment from "moment/moment";
import SearchTableRemote from "components/Tables/SearchTableRemote";

import { depositTransactionReportColumnsData } from "variables/columns/transaction-report/depositTransactionReportColumnsData";
import {
  fetchDepositHistories,
  fetchMarketingDepositHistories,
} from "services/transaction-report/depositTransactionReportService";
import { getReferenceDates } from "../../../utils/DateformatUtils";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";
import ApproveBlockAutoTransactionModal from "./components/ApproveBlockAutoTransactionModal";
import ApproveInvalidTransactionModal from "../invalid/components/ApproveInvalidTransactionModal";
import RejectInvalidTransactionModal from "../invalid/components/RejectInvalidTransactionModal";
import RejectBlockAutoTransactionModal from "./components/RejectBlockAutoTransactionModal";
import QrCodeModal from "./components/QrCodeModal";
import { mapJsonToTableData } from "../../../utils/CommonUtils";
import useDateRange from "../../../hooks/useDateRange";
import FileExportDropdown from "../../../components/FileExportDropdown/FileExportDropdown";

const DepositTransactionReport = ({ pageName }) => {
  const queryClient = new QueryClient();
  const { dateNow, dateDayCheck } = getReferenceDates();
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const [search, setSearch] = useState({});
  const [downloadableData, setDownloadableData] = useState([]);

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };
  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const { selectedDates, handleDateChange } = useDateRange(
    [dateDayCheck, dateNow],
    search,
    setSearch,
    dateFieldsNames,
    dateSearchFormat,
  );

  const role = JSON.parse(localStorage.getItem("APP_PROFILE")).role;

  const [modalState, setModalState] = useState({
    approveBlockAutoTransactionModalOpen: false,
    rejectBlockAutoTransactionModalOpen: false,
    approveInvalidTransactionModalOpen: false,
    rejectInvalidTransactionModalOpen: false,
    qrCodeModalOpen: false,
  });

  const handleModalOpen = (modal, transaction = null) => {
    setSelectedTransaction(transaction);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedTransaction(null);
  };

  const fetchData = async (pageIndex, pageSize, search) => {
    const response =
      role !== "MARKETING"
        ? await fetchDepositHistories(pageIndex, pageSize, search)
        : await fetchMarketingDepositHistories(pageIndex, pageSize, search);

    const formattedRecords = mapJsonToTableData(
      response.results,
      depositTransactionReportColumnsData(handleModalOpen),
    );

    setDownloadableData(formattedRecords);
    return response;
  };

  const searchOptions = [
    {
      label: "all",
      value: "",
    },
    {
      label: "success",
      value: "SUCCESS",
    },
    {
      label: "inprogress",
      value: "PENDING",
    },
    {
      label: "error",
      value: "ERROR",
    },
    {
      label: "reject",
      value: "REJECT",
    },
    {
      label: "rejectAndRefund",
      value: "REJECT_N_REFUND",
    },
    {
      label: "blockAuto",
      value: "BLOCK_AUTO",
    },
    {
      label: "notSure",
      value: "NOT_SURE",
    },
  ];

  const handleOptionChange = (selectedOption) => {
    setSearch({
      ...search,
      status: selectedOption ? selectedOption.value : null,
    });
  };

  useEffect(() => {
    setSearch({
      [dateFieldsNames.from]: moment(dateDayCheck).format(dateSearchFormat),
      [dateFieldsNames.to]: moment(dateNow).format(dateSearchFormat),
    });
    setIsLoading(false);
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
          <Box>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Box>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column" justifyContent="center">
                <Text fontSize="18px" fontWeight="bold" mb="6px">
                  เช็คข้อมูลฝาก
                </Text>
              </Flex>
              <Spacer />
              <Box width="235px">
                <SearchableDropdown
                  options={searchOptions}
                  isSearchable
                  placeholder="Search..."
                  onChange={handleOptionChange}
                />
              </Box>
              <FileExportDropdown
                data={downloadableData}
                filename={"ข้อมูลการฝาก"}
              />
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <QueryClientProvider client={queryClient}>
                  <SearchTableRemote
                    columnsData={depositTransactionReportColumnsData(
                      handleModalOpen,
                    )}
                    fetchData={fetchData}
                    search={search}
                    setSearch={setSearch}
                  />
                </QueryClientProvider>
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
        <ApproveBlockAutoTransactionModal
          isOpen={modalState.approveBlockAutoTransactionModalOpen}
          onClose={() => handleModalClose("approveBlockAutoTransactionModal")}
          setSearch={setSearch}
          transaction={selectedTransaction}
        />
        <RejectBlockAutoTransactionModal
          isOpen={modalState.rejectBlockAutoTransactionModalOpen}
          onClose={() => handleModalClose("rejectBlockAutoTransactionModal")}
          setSearch={setSearch}
          transaction={selectedTransaction}
        />
        <ApproveInvalidTransactionModal
          isOpen={modalState.approveInvalidTransactionModalOpen}
          onClose={() => handleModalClose("approveInvalidTransactionModal")}
          setSearch={setSearch}
          transaction={selectedTransaction}
        />
        <RejectInvalidTransactionModal
          isOpen={modalState.rejectInvalidTransactionModalOpen}
          onClose={() => handleModalClose("rejectInvalidTransactionModal")}
          setSearch={setSearch}
          transaction={selectedTransaction}
        />
        <QrCodeModal
          isOpen={modalState.qrCodeModalOpen}
          onClose={() => handleModalClose("qrCodeModal")}
          setSearch={setSearch}
          transaction={selectedTransaction}
        />
      </Stack>
    </Flex>
  );
};

export default DepositTransactionReport;
