import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Grid,
  GridItem,
  Text,
  useBreakpointValue,
  Stack,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";

import { ERROR_MESSAGE, MARKETING_ROLE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import { validateField } from "../../../../utils/CommonUtils";
import {
  addStaff,
  updateStaff,
} from "services/system-setting/staffSettingService";
import { useSuccessHandler } from "hooks/useSuccessHandler";

const ManageMarketingModal = ({
  isOpen,
  onClose,
  marketing = null,
  onSuccess,
}) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const modalSize = useBreakpointValue({ base: "full", md: "xl" });

  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();

  const initialValues = {
    username: marketing?.username || "",
    password: marketing?.password || "",
    tel: marketing?.tel || "",
    fullName: marketing?.fullName || "",
    limitFlag: 0,
    limit: "",
    active: marketing?.active || 0,
    roleCode: MARKETING_ROLE,
    lineId: marketing?.lineId || "",
  };

  const handleSaveMarketing = (values, actions) => {
    const payload = marketing ? { ...marketing, ...values } : values; // maybe handle "role" field ?

    const response = marketing ? updateStaff(payload) : addStaff(payload);

    response
      .then((response) => {
        onClose();
        if (response.status === 200) {
          handleSuccess(response.data.message);
          // setSearch()
          onSuccess();
        }
      })
      .catch((error) => {
        onClose();
        handleError(error, ERROR_MESSAGE);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={modalSize}
    >
      <ModalOverlay />
      <ModalContent
        maxH="100vh"
        p={8}
        borderRadius={{ base: "none", md: "md" }}
      >
        <ModalHeader>
          <Box textAlign="center">
            <Text fontSize="26px">
              {marketing
                ? `แก้ไขการตลาด: ${marketing?.username}`
                : "เพิ่มการตลาด"}
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              handleSaveMarketing(values, actions);
            }}
          >
            {(props) => (
              <Form>
                <Grid
                  templateColumns={{ base: "1fr", md: "repeat(1, 1fr)" }}
                  gap={4}
                >
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field name="fullName" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.fullName && form.touched.fullName
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ชื่อ-นามสกุล
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="ชื่อ-นามสกุล"
                            fontSize="sm"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field name="username" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.username && form.touched.username
                          }
                          isRequired={!marketing}
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ยูสเซอร์เนม
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="ยูสเซอร์เนม"
                            readOnly={!!marketing}
                            fontSize="sm"
                            backgroundColor={marketing ? "gray.800" : ""}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field
                      name="password"
                      validate={(value) => validateField(value)}
                    >
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.password && form.touched.password
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            พาสเวิร์ด
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="พาสเวิร์ด"
                            type="password"
                            fontSize="sm"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field name="tel">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            เบอร์โทรศัพท์
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="เบอร์โทรศัพท์"
                            fontSize="sm"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field name="lineId">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            LINE ID
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="@asgbet"
                            fontSize="sm"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field name="roleCode" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.roleCode && form.touched.roleCode
                          }
                          d
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ตำแหน่ง
                          </FormLabel>
                          <Input
                            {...field}
                            isReadOnly
                            fontSize="sm"
                            backgroundColor="gray.800"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field name="active">
                      {({ field, form }) => (
                        <FormControl display="flex" alignItems="center">
                          <Stack>
                            <FormLabel
                              htmlFor="is-active"
                              mb="0"
                              fontSize="xs"
                              fontWeight="bold"
                            >
                              Active
                            </FormLabel>
                            <Switch
                              id="is-active"
                              {...field}
                              isChecked={field.value === 1}
                              onChange={() =>
                                form.setFieldValue(
                                  "active",
                                  field.value === 1 ? 0 : 1,
                                )
                              }
                            />
                          </Stack>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                </Grid>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    color={"#000000"}
                    backgroundColor="green.500"
                    w="100%"
                    type="submit"
                    isLoading={props.isSubmitting}
                    disabled={props.isSubmitting}
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManageMarketingModal;
