import { formatDateWithoutMeridiem } from "../../../utils/DateformatUtils";

export const memberRecentDepositTransactionColumnsData = [
  {
    Header: "โบนัส",
    accessor: "bonus",
  },
  {
    Header: "โปร",
    accessor: "promotionName",
  },
  {
    Header: "เครดิตก่อนเติม",
    accessor: "beforeAmount",
  },
  {
    Header: "เติม",
    accessor: "addCredit",
  },
  {
    Header: "เครดิตหลังเติม",
    accessor: "afterAmount",
  },
  {
    Header: "เวลา",
    accessor: "createdDate",
    Cell: ({ value }) => formatDateWithoutMeridiem(value),
  },
  {
    Header: "หมายเหตุ",
    accessor: "reason",
  },
];
