import React from "react";
import { Box, Button, Flex, Switch, Text } from "@chakra-ui/react";
import { HiOutlineTrash } from "react-icons/hi";
import ImageUploader from "components/ImageUploader/ImageUploader";
import Card from "components/Card/Card";

const ActivityImageCard = ({
  title,
  imageSrc,
  onDelete,
  onToggle,
  isActive,
  onImageUpload,
}) => {
  const handleImageUpload = (image) => {
    onImageUpload(image);
  };

  return (
    <Card
      borderRadius="lg"
      overflow="hidden"
      p="6"
      boxShadow="lg"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Text fontSize="lg" fontWeight="bold" alignSelf="flex-start" mb={2}>
        {title}{" "}
        <Box as="span" color="green.500" fontWeight="normal" fontSize="sm">
          (ขนาด 768 x 768 px)
        </Box>
      </Text>
      <ImageUploader initialImage={imageSrc} onUpload={handleImageUpload} />
      <Flex justify="space-between" align="center" width="100%" mt="4">
        <Button
          borderColor={"red.500"}
          color={"red.500"}
          onClick={onDelete}
          variant="outline"
        >
          <HiOutlineTrash />
        </Button>
        <Switch isChecked={isActive} onChange={onToggle} />
      </Flex>
    </Card>
  );
};

export default ActivityImageCard;
