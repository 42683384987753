export const thiefColumnsData = [
  {
    Header: "ชื่อ",
    accessor: "name",
    disableSortBy: true,
  },
  {
    Header: "ธนาคาร",
    accessor: "bankName",
    disableSortBy: true,
  },
  {
    Header: "เลขที่บัญชี",
    accessor: "bankAccount",
    disableSortBy: true,
  },
];
