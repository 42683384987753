import { formatDate } from "../../../utils/DateformatUtils";

export const staffLoginHistoryColumnsData = () => {
  return [
    {
      Header: "ยูสเซอร์",
      accessor: "username",
    },
    {
      Header: "ตำแหน่ง",
      accessor: "role",
      disableSortBy: true,
    },
    {
      Header: "เข้าสู่ระบบล่าสุด",
      accessor: "loginDate",
      Cell: ({ value }) => formatDate(value),
    },
  ];
};
