import { IconButton } from "@chakra-ui/react";
import { HiOutlinePencil } from "react-icons/hi2";
import { formatDate } from "../../../utils/DateformatUtils";

export const roleSettingColumnsData = (history) => {
  return [
    {
      Header: "ตำแหน่ง",
      accessor: "roleName",
    },
    {
      Header: "สร้างโดย",
      accessor: "audit.createdBy",
    },
    {
      Header: "วันที่สร้าง",
      accessor: "createdDate",
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: "อัพเดทล่าสุดเมื่อ",
      accessor: "updatedDate",
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: "แก้ไข",
      accessor: "edit",
      Cell: ({ row }) =>
        row.original.id === 1 ? (
          <IconButton
            border="none"
            aria-label="edit"
            size="sm"
            background="none"
            _hover={{ background: "none" }}
            _active={{ background: "none" }}
          />
        ) : (
          <IconButton
            variant="outline"
            colorScheme="green"
            aria-label="edit"
            size="sm"
            icon={<HiOutlinePencil />}
            onClick={() =>
              history.push(`/admin/system-setting/role/edit/${row.original.id}`)
            }
          >
            แก้ไข
          </IconButton>
        ),
      disableSortBy: true,
    },
  ];
};
