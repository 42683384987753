import { animated, useSpring } from "react-spring";
import React from "react";
import { useBreakpointValue } from "@chakra-ui/react";

const AnimatedNumber = ({ n, dp }) => {
  const { number } = useSpring({ number: n, from: { number: 0 } });

  const fontSize = useBreakpointValue({ base: "xl", md: "2xl" });

  return (
    <animated.div style={{ fontSize }}>
      {number.to((n) => Number(n.toFixed(dp)).toLocaleString("en-US"))}
    </animated.div>
  );
};

export default AnimatedNumber;
