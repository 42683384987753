import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import React from "react";

import { Field, Form, Formik } from "formik";
import { saveJackpot } from "../../../../services/system-setting/jackpotSettingService";
import { useSuccessHandler } from "../../../../hooks/useSuccessHandler";
import { useErrorHandler } from "../../../../hooks/useErrorHandler";
import { ERROR_MESSAGE } from "../../../../variables/system";

const JackpotBotSettingModal = ({
  isOpen,
  onClose,
  jackpot,
  jackpotData,
  onSuccess,
}) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const handleSetJackpotSetting = async (values, actions) => {
    const jackpotSetting = { ...jackpot, ...values };
    const combinedJackpot = { ...jackpotData, ...jackpotSetting };

    saveJackpot(combinedJackpot)
      .then((response) => handleSuccess(response.data.message))
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      })
      .finally(() => {
        actions.setSubmitting(false);
        onSuccess();
        onClose();
      });
  };

  console.log(jackpot);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent p={8}>
        <ModalHeader>
          <Box textAlign="center">
            <Flex alignItems="center" justifyContent="center">
              <Text fontSize="xl">ตั้งค่าแจก AUTO</Text>
              <Badge
                colorScheme={
                  jackpot?.type === "Grand"
                    ? "red"
                    : jackpot?.type === "Major"
                    ? "pink"
                    : jackpot?.type === "Minor"
                    ? "blue"
                    : "green"
                }
                w="65px"
                h="28px"
                borderRadius="15px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                ml="2"
              >
                {jackpot?.type}
              </Badge>
            </Flex>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              [`auto${jackpot?.type}`]: jackpot
                ? jackpot[`auto${jackpot.type}`] || false
                : false,
              [`autoHour${jackpot?.type}`]: jackpot
                ? jackpot[`autoHour${jackpot.type}`] || ""
                : "",
              [`autoProfit${jackpot?.type}`]: jackpot
                ? jackpot[`autoProfit${jackpot.type}`] || ""
                : "",
              [`autoTurn${jackpot?.type}`]: jackpot
                ? jackpot[`autoTurn${jackpot.type}`] || ""
                : "",
            }}
            enableReinitialize
            onSubmit={(values, actions) => {
              handleSetJackpotSetting(values, actions);
            }}
          >
            {(props) => {
              console.log(props.values);
              return (
                <Form>
                  <Field name={`auto${jackpot?.type}`}>
                    {({ field, form }) => (
                      <FormControl display="flex" alignItems="center">
                        <Stack>
                          <FormLabel
                            htmlFor="is-no-bonus"
                            mb="0"
                            fontSize="xs"
                            fontWeight="bold"
                          >
                            สถานะเปิดใช้งาน
                          </FormLabel>
                          <Switch
                            id="is-no-bonus"
                            {...field}
                            isChecked={form.values[`auto${jackpot?.type}`]}
                            onChange={(e) =>
                              form.setFieldValue(
                                `auto${jackpot?.type}`,
                                e.target.checked,
                              )
                            }
                          />
                        </Stack>
                      </FormControl>
                    )}
                  </Field>
                  <Field name={`autoHour${jackpot?.type}`}>
                    {({ field, form }) => (
                      <FormControl mt={4}>
                        <FormLabel fontSize="xs" fontWeight="bold">
                          ทุกๆกี่ชั่วโมง
                        </FormLabel>
                        <NumberInput
                          value={form.values[`autoHour${jackpot?.type}`]}
                          onChange={(valueString) =>
                            form.setFieldValue(
                              `autoHour${jackpot?.type}`,
                              parseFloat(valueString),
                            )
                          }
                        >
                          <NumberInputField
                            ref={initialRef}
                            {...field}
                            fontSize="sm"
                          />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </FormControl>
                    )}
                  </Field>
                  <Field name={`autoProfit${jackpot.type}`}>
                    {({ field, form }) => (
                      <FormControl mt={4}>
                        <FormLabel fontSize="xs" fontWeight="bold">
                          เมื่อยอดกำไรถึง
                        </FormLabel>
                        <NumberInput
                          value={form.values[`autoProfit${jackpot.type}`]}
                          onChange={(valueString) =>
                            props.setFieldValue(
                              `autoProfit${jackpot.type}`,
                              valueString,
                            )
                          }
                        >
                          <NumberInputField
                            ref={initialRef}
                            {...field}
                            fontSize="sm"
                          />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </FormControl>
                    )}
                  </Field>
                  <Field name={`autoTurn${jackpot.type}`}>
                    {({ field, form }) => (
                      <FormControl mt={4}>
                        <FormLabel fontSize="xs" fontWeight="bold">
                          ยอดเทรินโอเวอร์ (จำนวนเท่า)
                        </FormLabel>
                        <NumberInput
                          value={form.values[`autoTurn${jackpot.type}`]}
                          onChange={(valueString) =>
                            props.setFieldValue(
                              `autoTurn${jackpot.type}`,
                              valueString,
                            )
                          }
                        >
                          <NumberInputField
                            ref={initialRef}
                            {...field}
                            fontSize="sm"
                          />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </FormControl>
                    )}
                  </Field>
                  <ModalFooter pl={0} pr={0} mt={4}>
                    <Button
                      backgroundColor="green.500"
                      color={"#000000"}
                      w="100%"
                      isLoading={props.isSubmitting}
                      type="submit"
                    >
                      ทำรายการ
                    </Button>
                  </ModalFooter>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default JackpotBotSettingModal;
