import { callListApi } from "../../utils/APIUtils";
import {
  buildBasePayload,
  buildMemberFetchMemberDepositPayload,
} from "../../utils/PayloadUtils";

// TODO: search still bug
export const fetchMemberDepositReport = async (
  page,
  pageSize,
  search,
  sort = "D",
  sortField = "id",
) => {
  const req = buildBasePayload(
    page,
    pageSize,
    search,
    sort,
    sortField,
    buildMemberFetchMemberDepositPayload,
  );

  const response = await callListApi("/api/admin/webuser/search", "POST", req)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(`API error:${e?.message}`);
    });

  const data2 = await response;

  const data = {
    count: data2.page.totalElements,
    results: data2.datas,
  };

  return data;
};

export const fetchMarketingMemberDepositReport = async (
  page,
  pageSize,
  search,
  sort = "D",
  sortField = "id",
) => {
  const req = buildBasePayload(
    page,
    pageSize,
    search,
    sort,
    sortField,
    buildMemberFetchMemberDepositPayload,
  );

  const response = await callListApi(
    "/api/admin/marketing/webuser/search",
    "POST",
    req,
  )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(`API error:${e?.message}`);
    });

  const data2 = await response;

  const data = {
    count: data2.page.totalElements,
    results: data2.datas,
  };

  return data;
};
