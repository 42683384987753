import { callapi, callListApi } from "../../utils/APIUtils";
import {
  buildBasePayload,
  buildFetchMemberPayload,
  buildFetchMemberTodayPayload,
} from "../../utils/PayloadUtils";

export const addMember = (payload) => {
  return callapi("/api/admin/webuser", "POST", payload);
};

export const updateMember = (id, payload) => {
  return callapi(`/api/admin/webuser/${id}`, "PUT", payload);
};

export const fetchBanks = (type, nationality = "thai") => {
  return callapi(`/api/master/${type}?nationality=${nationality}`, "GET", {});
};

export const createMistakeCredit = (payload) => {
  return callapi("/api/admin/mistake", "POST", payload);
};

export const resetPassword = (id) => {
  return callapi(`/api/admin/webuser/reset/${id}`, "PUT");
};

export const fetchMembers = async (
  page,
  pageSize,
  search,
  sort = "A",
  sortField = "id",
) => {
  const req = buildBasePayload(
    page,
    pageSize,
    search,
    sort,
    sortField,
    buildFetchMemberPayload,
  );

  const response = await callListApi("/api/admin/webuser/search", "POST", req)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(`API error:${e?.message}`);
    });

  const data2 = await response;

  const data = {
    count: data2.page.totalElements,
    results: data2.datas,
  };

  return data;
};

export const fetchMarketingMembers = async (
  page,
  pageSize,
  search,
  sort = "A",
  sortField = "id",
) => {
  const req = buildBasePayload(
    page,
    pageSize,
    search,
    sort,
    sortField,
    buildFetchMemberPayload,
  );

  const response = await callListApi(
    "/api/admin/marketing/webuser/search",
    "POST",
    req,
  )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(`API error:${e?.message}`);
    });

  const data2 = await response;

  const data = {
    count: data2.page.totalElements,
    results: data2.datas,
  };

  return data;
};

export const fetchMembersToday = async (
  page,
  pageSize,
  search,
  sort = "D",
  sortField = "id",
) => {
  const req = buildBasePayload(
    page,
    pageSize,
    search,
    sort,
    sortField,
    buildFetchMemberTodayPayload,
  );

  const response = await callListApi("/api/admin/webuser/search", "POST", req)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(`API error:${e?.message}`);
    });

  const data2 = await response;

  const data = {
    count: data2.page.totalElements,
    results: data2.datas,
  };

  return data;
};

export const fetchMarketingMembersToday = async (
  page,
  pageSize,
  search,
  sort = "D",
  sortField = "id",
) => {
  const req = buildBasePayload(
    page,
    pageSize,
    search,
    sort,
    sortField,
    buildFetchMemberTodayPayload,
  );

  const response = await callListApi(
    "/api/admin/marketing/webuser/search",
    "POST",
    req,
  )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(`API error:${e?.message}`);
    });

  const data2 = await response;

  const data = {
    count: data2.page.totalElements,
    results: data2.datas,
  };

  return data;
};
