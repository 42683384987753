import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Grid,
  GridItem,
  Text,
  useBreakpointValue,
  Stack,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";

import {
  DEVICE_DROPDOWN,
  ERROR_MESSAGE,
  FOOTER_ICON_TYPE,
} from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import ImageUploader from "components/ImageUploader/ImageUploader";
import {
  addFooterIcon,
  updateFooterIcon,
} from "services/website-setting/footerService";

const ManageFooterIconModal = ({ isOpen, onClose, icon = null, onSuccess }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const modalSize = useBreakpointValue({ base: "full", md: "xl" });

  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();

  const handleSaveIcon = (values, actions) => {
    const payload = icon ? { ...icon, ...values } : values;

    const response = icon ? updateFooterIcon(payload) : addFooterIcon(payload);

    response
      .then((response) => {
        onClose();
        if (response.status === 200) {
          handleSuccess(response.data.message);
          // setSearch()
          onSuccess();
        }
      })
      .catch((error) => {
        onClose();
        handleError(error, ERROR_MESSAGE);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={modalSize}
    >
      <ModalOverlay />
      <ModalContent
        maxH="100vh"
        p={8}
        borderRadius={{ base: "none", md: "md" }}
      >
        <ModalHeader>
          <Box textAlign="center">
            <Text fontSize="26px">
              {icon ? `แก้ไข Icon: ${icon?.name}` : "เพิ่ม Icon"}
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <Formik
            initialValues={{
              name: icon?.name || "",
              type: icon?.type || "",
              device: icon?.device || "",
              sort: icon?.sort || "",
              status: icon?.status || "false",
              images: icon?.images || "",
            }}
            onSubmit={(values, actions) => {
              handleSaveIcon(values, actions);
            }}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form>
                <Grid
                  templateColumns={{ base: "1fr", md: "repeat(1, 1fr)" }}
                  gap={4}
                >
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field name="name">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ชื่อ
                          </FormLabel>
                          <Input {...field} placeholder="ชื่อ" fontSize="sm" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <Box>
                    <ImageUploader
                      initialImage={values.images}
                      onUpload={(uri) => setFieldValue("images", uri)}
                    />
                  </Box>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field name="type">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ประเภท
                          </FormLabel>
                          <Select
                            {...field}
                            fontSize="sm"
                            placeholder="เลือกประเภท"
                          >
                            {FOOTER_ICON_TYPE.map((roles) => (
                              <option key={roles.id} value={roles.val}>
                                {roles.text}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field name="device">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            อุปกรณ์
                          </FormLabel>
                          <Select
                            {...field}
                            fontSize="sm"
                            placeholder="เลือกอุปกรณ์"
                          >
                            {DEVICE_DROPDOWN.map((device) => (
                              <option key={device.id} value={device.val}>
                                {device.text}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <Field name="sort">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ลำดับ
                          </FormLabel>
                          <Input {...field} placeholder="1" fontSize="sm" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field name="status">
                      {({ field, form }) => (
                        <FormControl display="flex" alignItems="center">
                          <Stack>
                            <FormLabel
                              htmlFor="is-active"
                              mb="0"
                              fontSize="xs"
                              fontWeight="bold"
                            >
                              Active
                            </FormLabel>
                            <Switch
                              id="is-active"
                              {...field}
                              isChecked={field.value === "true"}
                              onChange={() =>
                                form.setFieldValue(
                                  "status",
                                  field.value === "true" ? "false" : "true",
                                )
                              }
                            />
                          </Stack>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                </Grid>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    color={"#000000"}
                    backgroundColor="green.500"
                    w="100%"
                    type="submit"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManageFooterIconModal;
