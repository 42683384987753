import React, { useEffect, useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Stack,
  Text,
  Select,
  NumberInput,
  NumberInputField,
  Switch,
  IconButton,
  Icon,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputStepper,
  Box,
} from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import { Element } from "react-scroll";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import { Formik, Form, FieldArray } from "formik";

import { useErrorHandler } from "hooks/useErrorHandler";
import { ERROR_MESSAGE, TYPEAFFILIATE, TYPEBONUS } from "variables/system";
import { HiOutlineTrash } from "react-icons/hi2";
import { HiOutlinePlusSm } from "react-icons/hi";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import {
  fetchAffiliateSetting,
  updateAffiliateSetting,
} from "services/system-setting/affiliateSettingService";

const AffiliateSetting = ({ pageName }) => {
  const [affiliateData, setAffiliateData] = useState(null);

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  useEffect(() => {
    fetchAffiliateSetting()
      .then((response) => {
        setAffiliateData(response.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  }, []);

  const handleSubmit = (values) => {
    updateAffiliateSetting(values)
      .then((response) => {
        handleSuccess(response.data.message);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
        </AnimatedFlex>
        <Formik
          initialValues={{
            id: affiliateData?.id || "",
            typeAffiliate: affiliateData?.typeAffiliate || "",
            typeBonus: affiliateData?.typeBonus || "",
            maxBonus: affiliateData?.maxBonus || "",
            maxWithdraw: affiliateData?.maxWithdraw || "",
            maxWallet: affiliateData?.maxWallet || "",
            img: affiliateData?.img || "",
            active: affiliateData?.active || false,
            forever: affiliateData?.forever || false,
            conditions: affiliateData?.conditions || [
              { minTopup: "", maxTopup: "", bonus: "" },
            ],
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, handleChange, setFieldValue }) => (
            <Form>
              <Flex direction="column">
                <Stack direction="column" spacing="24px" w="100%">
                  <AnimatedFlex duration={0.4} delay={0.6}>
                    <Card
                      w={{ sm: "100%" }}
                      alignSelf="flex-end"
                      justifySelf="flex-end"
                    >
                      <Element id="info" name="info">
                        <CardBody>
                          <Grid
                            templateColumns={{
                              base: "1fr",
                              sm: "repeat(2, 1fr)",
                              md: "repeat(3, 1fr)",
                            }}
                            gap="24px"
                            w="100%"
                          >
                            <GridItem>
                              <FormControl>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  ประเภท แนะนำเพื่อน
                                </FormLabel>
                                <Select
                                  name="typeAffiliate"
                                  value={values.typeAffiliate}
                                  onChange={handleChange}
                                  fontSize="sm"
                                >
                                  {TYPEAFFILIATE.map((item, index) => (
                                    <option key={index} value={item.typeCode}>
                                      {item.typeName}
                                    </option>
                                  ))}
                                </Select>
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  ประเภทโบนัส
                                </FormLabel>
                                <Select
                                  name="typeBonus"
                                  value={values.typeBonus}
                                  onChange={handleChange}
                                  fontSize="sm"
                                >
                                  {TYPEBONUS.map((item, index) => (
                                    <option key={index} value={item.typeCode}>
                                      {item.typeName}
                                    </option>
                                  ))}
                                </Select>
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  โบนัสสูงสุด
                                </FormLabel>
                                <NumberInput
                                  value={values.maxBonus}
                                  onChange={(valueString) =>
                                    setFieldValue("maxBonus", valueString)
                                  }
                                >
                                  <NumberInputField
                                    name="maxBonus"
                                    placeholder="eg. 1000"
                                    fontSize="sm"
                                  />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  จำนวนยอดถอน{" "}
                                  <Box
                                    as="span"
                                    color="green.500"
                                    fontWeight="normal"
                                  >
                                    (สูงสุดกี่เท่า)
                                  </Box>
                                </FormLabel>
                                <NumberInput
                                  value={values.maxWithdraw}
                                  onChange={(valueString) =>
                                    setFieldValue("maxWithdraw", valueString)
                                  }
                                >
                                  <NumberInputField
                                    name="maxWithdraw"
                                    placeholder="eg. 5"
                                    fontSize="sm"
                                  />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  จำนวนเงิน point สะสมสูงสุด
                                </FormLabel>
                                <NumberInput
                                  value={values.maxWallet}
                                  onChange={(valueString) =>
                                    setFieldValue("maxWallet", valueString)
                                  }
                                >
                                  <NumberInputField
                                    name="maxWallet"
                                    placeholder="eg. 20000"
                                    fontSize="sm"
                                  />
                                  <NumberInputStepper>
                                    <NumberIncrementStepper />
                                    <NumberDecrementStepper />
                                  </NumberInputStepper>
                                </NumberInput>
                              </FormControl>
                            </GridItem>
                            <GridItem />
                            <GridItem>
                              <FormControl>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  ให้ทุกครั้ง
                                </FormLabel>
                                <Switch
                                  name="forever"
                                  isChecked={values.forever}
                                  onChange={handleChange}
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl>
                                <FormLabel
                                  fontWeight="semibold"
                                  fontSize="xs"
                                  mb="10px"
                                >
                                  สถานะ
                                </FormLabel>
                                <Switch
                                  name="active"
                                  isChecked={values.active}
                                  onChange={handleChange}
                                />
                              </FormControl>
                            </GridItem>
                          </Grid>
                        </CardBody>
                      </Element>
                    </Card>
                  </AnimatedFlex>
                  <FieldArray name="conditions">
                    {({ push, remove }) => (
                      <>
                        <AnimatedFlex
                          justify="space-between"
                          align="center"
                          w="100%"
                          duration={0.4}
                          delay={0.8}
                        >
                          <Text fontSize="lg" fontWeight="bold">
                            เพิ่มเงื่อนไข
                          </Text>
                          <Button
                            variant="outline"
                            borderColor={"green.500"}
                            color={"green.500"}
                            // width={{base: "100%", md: "auto"}}
                            // minW={{base: "none", md: "175px"}}
                            size="md"
                            borderRadius="6px"
                            onClick={() =>
                              push({
                                minTopup: "",
                                maxTopup: "",
                                bonus: "",
                              })
                            }
                          >
                            <Icon as={HiOutlinePlusSm} />
                          </Button>
                        </AnimatedFlex>
                        <AnimatedFlex duration={0.4} delay={1.0}>
                          <Card
                            w={{ sm: "100%" }}
                            alignSelf="flex-end"
                            justifySelf="flex-end"
                          >
                            <Element id="info" name="info">
                              <CardBody>
                                <Grid
                                  templateColumns={{
                                    base: "1fr",
                                    sm: "repeat(2, 1fr) auto",
                                    md: "repeat(3, 1fr) auto",
                                  }}
                                  gap="24px"
                                  w="100%"
                                >
                                  {values.conditions.map((condition, index) => (
                                    <React.Fragment key={index}>
                                      <GridItem
                                        colSpan={{ base: 1, sm: 1, md: 1 }}
                                      >
                                        <FormControl>
                                          <FormLabel
                                            fontWeight="semibold"
                                            fontSize="xs"
                                            mb="10px"
                                          >
                                            ต่ำสุด
                                          </FormLabel>
                                          <NumberInput
                                            value={condition.minTopup}
                                            onChange={(valueString) =>
                                              setFieldValue(
                                                `conditions.${index}.minTopup`,
                                                valueString,
                                              )
                                            }
                                          >
                                            <NumberInputField
                                              name={`conditions.${index}.minTopup`}
                                              placeholder="eg. 10000"
                                              fontSize="sm"
                                            />
                                            <NumberInputStepper>
                                              <NumberIncrementStepper />
                                              <NumberDecrementStepper />
                                            </NumberInputStepper>
                                          </NumberInput>
                                        </FormControl>
                                      </GridItem>
                                      <GridItem
                                        colSpan={{ base: 1, sm: 1, md: 1 }}
                                      >
                                        <FormControl>
                                          <FormLabel
                                            fontWeight="semibold"
                                            fontSize="xs"
                                            mb="10px"
                                          >
                                            สูงสุด
                                          </FormLabel>
                                          <NumberInput
                                            value={condition.maxTopup}
                                            onChange={(valueString) =>
                                              setFieldValue(
                                                `conditions.${index}.maxTopup`,
                                                valueString,
                                              )
                                            }
                                          >
                                            <NumberInputField
                                              name={`conditions.${index}.maxTopup`}
                                              placeholder="eg. 1000000"
                                              fontSize="sm"
                                            />
                                            <NumberInputStepper>
                                              <NumberIncrementStepper />
                                              <NumberDecrementStepper />
                                            </NumberInputStepper>
                                          </NumberInput>
                                        </FormControl>
                                      </GridItem>
                                      <GridItem
                                        colSpan={{ base: 1, sm: 1, md: 1 }}
                                      >
                                        <FormControl>
                                          <FormLabel
                                            fontWeight="semibold"
                                            fontSize="xs"
                                            mb="10px"
                                          >
                                            โบนัส
                                          </FormLabel>
                                          <NumberInput
                                            value={condition.bonus}
                                            onChange={(valueString) =>
                                              setFieldValue(
                                                `conditions.${index}.bonus`,
                                                valueString,
                                              )
                                            }
                                          >
                                            <NumberInputField
                                              name={`conditions.${index}.bonus`}
                                              placeholder="eg. 1"
                                              fontSize="sm"
                                            />
                                            <NumberInputStepper>
                                              <NumberIncrementStepper />
                                              <NumberDecrementStepper />
                                            </NumberInputStepper>
                                          </NumberInput>
                                        </FormControl>
                                      </GridItem>
                                      <GridItem
                                        colSpan={1}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="end"
                                      >
                                        <IconButton
                                          variant="outline"
                                          borderColor={"red.500"}
                                          color={"red.500"}
                                          aria-label="delete"
                                          size="md"
                                          icon={<HiOutlineTrash />}
                                          onClick={() => remove(index)}
                                        />
                                      </GridItem>
                                    </React.Fragment>
                                  ))}
                                </Grid>
                              </CardBody>
                            </Element>
                          </Card>
                        </AnimatedFlex>
                      </>
                    )}
                  </FieldArray>
                  <AnimatedFlex
                    justify="flex-end"
                    pt="15px"
                    duration={0.4}
                    delay={1.0}
                  >
                    <Button
                      type="submit"
                      backgroundColor="green.500"
                      color={"#000000"}
                      width={{ base: "100%", md: "auto" }}
                      minW={{ base: "none", md: "280px" }}
                      borderRadius="6px"
                    >
                      บันทึกข้อมูล
                    </Button>
                  </AnimatedFlex>
                </Stack>
              </Flex>
            </Form>
          )}
        </Formik>
      </Stack>
    </Flex>
  );
};
export default AffiliateSetting;
