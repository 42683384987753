import { Button, Flex, Spinner, Stack, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import React, { useEffect, useState } from "react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import SimpleTable from "components/Tables/SimpleTable";
import { memberLevelColumnsData } from "variables/columns/member-management/memberLevelColumnsData";
import { ERROR_MESSAGE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import {
  fetchMemberLevel,
  updateMemberLevel,
} from "services/member-management/memberLevelService";
import { useSuccessHandler } from "hooks/useSuccessHandler";

const MemberLevel = ({ pageName }) => {
  const [memberLevelData, setMemberLevelData] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const handleValueChange = (id, field, value) => {
    setMemberLevelData((prevData) => {
      return prevData.map((memberLevel) => {
        if (memberLevel.id === id) {
          return { ...memberLevel, [field]: parseFloat(value) };
        }
        return memberLevel;
      });
    });
  };

  useEffect(() => {
    fetchMemberLevel()
      .then((response) => {
        setMemberLevelData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleError(error, ERROR_MESSAGE);
      });
  }, []);

  const handleSave = () => {
    updateMemberLevel({ memberLevelList: memberLevelData })
      .then((response) => {
        handleSuccess(response.data.message);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="0px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  จัดการระดับสมาชิก
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <SimpleTable
                  tableData={memberLevelData}
                  columnsData={memberLevelColumnsData(handleValueChange)}
                />
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
        <AnimatedFlex justify="flex-end" duration={0.4} delay={0.8}>
          <Button
            onClick={handleSave}
            backgroundColor="green.500"
            color={"#000000"}
            width={{ base: "100%", md: "auto" }}
            minW={{ base: "none", md: "280px" }}
            borderRadius="6px"
          >
            บันทึกข้อมูล
          </Button>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default MemberLevel;
