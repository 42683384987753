import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

import { SidebarResponsive } from "components/Sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  HiMiniArrowTrendingDown,
  HiMiniArrowTrendingUp,
  HiOutlineArrowsPointingIn,
  HiOutlineArrowsPointingOut,
} from "react-icons/hi2";
import { HiOutlineLogout } from "react-icons/hi";
import NotificationWebsocket from "../Notification/NotificationWebsocket";

import useDashRoutes from "hooks/useDashRoutes";
import avatar8 from "../../assets/img/avatars/avatar8.png";
import { SettingsIcon } from "@chakra-ui/icons";
import { fetchPendingTransaction } from "../../services/admin/AdminProfileService";
import { useErrorHandler } from "../../hooks/useErrorHandler";
import { ERROR_MESSAGE } from "../../variables/system";

export default function HeaderLinks(props) {
  // const { variant, children, fixed, secondary, onOpen, ...rest } = props;

  const settingsRef = React.useRef();
  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const [task, setTask] = React.useState({
    depositTask: 0,
    withdrawTask: 0,
  });

  const history = useHistory();
  const handleError = useErrorHandler();

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  const showFullscreenIcon = useBreakpointValue({ base: false, md: true });

  const routes = useDashRoutes();

  useEffect(() => {
    fetchPendingTransaction()
      .then((response) => {
        setTask(response.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  }, []);

  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      justifyContent="flex-end"
    >
      {showFullscreenIcon && (
        <Box display="flex" alignItems="center" mr="4">
          <Button
            leftIcon={<Icon as={HiMiniArrowTrendingUp} w="18px" h="18px" />}
            variant="outline"
            h="30px"
            p="3"
            mr="4"
            onClick={() => history.push("/admin/transaction-report/deposit")}
          >
            <Text fontWeight="300" fontSize="sm">
              รอฝาก
            </Text>
            <Text
              ml="2"
              fontWeight="800"
              fontSize="xs"
              bg="green.500"
              px="2"
              borderRadius="md"
              color="#000000"
            >
              {task.depositTask}
            </Text>
          </Button>
          <Button
            leftIcon={<Icon as={HiMiniArrowTrendingDown} w="18px" h="18px" />}
            variant="outline"
            h="30px"
            p="3"
            onClick={() => history.push("/admin/transaction-report/withdraw")}
          >
            <Text fontWeight="300" fontSize="sm">
              รอถอน
            </Text>
            <Text
              ml="2"
              fontWeight="800"
              fontSize="xs"
              bg="green.500"
              px="2"
              borderRadius="md"
              color="#000000"
            >
              {task.withdrawTask}
            </Text>
          </Button>
          <NotificationWebsocket setTask={setTask} />
        </Box>
      )}
      {showFullscreenIcon && (
        <SettingsIcon
          as={
            isFullscreen
              ? HiOutlineArrowsPointingIn
              : HiOutlineArrowsPointingOut
          }
          cursor="pointer"
          me="16px"
          onClick={toggleFullscreen}
          color={"white"}
          w="18px"
          h="18px"
        />
      )}
      <Flex alignItems="center">
        {!showFullscreenIcon && (
          <Menu>
            <MenuButton
              as={Avatar}
              src={avatar8}
              height="30px"
              width="30px"
              cursor="pointer"
              mr="12px"
            ></MenuButton>
            <MenuList>
              <MenuItem onClick={() => history.push("/admin/profile")}>
                โปรไฟล์
              </MenuItem>
              <MenuItem
                onClick={() =>
                  history.push("/auth/authentication/sign-in/basic")
                }
              >
                ออกจากระบบ
              </MenuItem>
            </MenuList>
          </Menu>
        )}
        <SidebarResponsive
          logoText={props.logoText}
          secondary={props.secondary}
          routes={routes}
        />
        {showFullscreenIcon && (
          <SettingsIcon
            as={HiOutlineLogout}
            cursor="pointer"
            ref={settingsRef}
            background="none"
            color={"white"}
            onClick={() => history.push("/auth/authentication/sign-in/basic")}
            w="18px"
            h="18px"
          />
        )}
      </Flex>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
