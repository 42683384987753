import {
  Button,
  Flex,
  Icon,
  Input,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

function SearchTable1(props) {
  const { columnsData, tableData, isSum } = props;

  const columns = useMemo(() => columnsData, []);
  const data = useMemo(() => tableData, []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    // nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    setGlobalFilter,
    state,
  } = tableInstance;

  const createPages = (count) => {
    let arrPageCount = [];
    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }
    return arrPageCount;
  };

  const { pageIndex, pageSize } = state;

  const stackAlignment = useBreakpointValue({ base: "center", md: "flex-end" });
  const stackMarginStart = useBreakpointValue({ base: "0", md: "auto" });

  const sums = columnsData.reduce((acc, column) => {
    if (column.isSum) {
      acc[column.accessor] = data.reduce(
        (sum, row) => sum + row[column.accessor],
        0,
      );
    }
    console.log(acc);
    return acc;
  }, {});

  const [pageChunk, setPageChunk] = useState(0);
  const pagesPerChunk = 5;

  return (
    <>
      <Flex direction="column" w="100%">
        <Flex justify="flex-end" align="center" w="100%" px="22px" my="22px">
          {props.hideSearch ? null : (
            <Input
              type="text"
              placeholder="Search..."
              minW="75px"
              maxW="175px"
              fontSize="sm"
              _focus={{ borderColor: "green.500" }}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
          )}
        </Flex>
        <Box overflowX="auto" w="100%">
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
            w="100%"
            minW="800px"
          >
            <Thead backgroundColor="rgb(29,29,29)">
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="0px"
                      key={index}
                      p={3}
                      minW="175px"
                      textAlign="center"
                    >
                      <Flex
                        justify="center"
                        align="center"
                        fontSize="sm"
                        color="white"
                      >
                        {column.render("Header")}
                        {!column.disableSortBy && (
                          <Icon
                            w={{ sm: "10px", md: "14px" }}
                            h={{ sm: "10px", md: "14px" }}
                            color={column.isSorted ? "gray.500" : "gray.400"}
                            float="right"
                            as={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? TiArrowSortedDown
                                  : TiArrowSortedUp
                                : TiArrowUnsorted
                            }
                          />
                        )}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.length === 0 ? (
                <Tr>
                  <Td
                    colSpan={headerGroups[0].headers.length}
                    textAlign="center"
                    fontSize="sm"
                    color="red.500"
                    p={4}
                  >
                    No matching records found
                  </Td>
                </Tr>
              ) : (
                page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, index) => (
                        <Td
                          {...cell.getCellProps()}
                          fontSize="sm"
                          key={index}
                          color="white"
                          p={2}
                          minW="175px"
                          textAlign="center"
                          className="td-border-bottom"
                        >
                          {cell.render("Cell")}
                        </Td>
                      ))}
                    </Tr>
                  );
                })
              )}
              {isSum && (
                <Tr backgroundColor={"#000000"}>
                  {columns.map((column, index) => (
                    <Td
                      key={index}
                      fontSize="sm"
                      color="white"
                      p={2}
                      minW="175px"
                      textAlign="center"
                      fontWeight="bold"
                    >
                      {index === 0 ? (
                        <Text color="green.500">รวม</Text>
                      ) : column.isSum ? (
                        sums[column.accessor]
                      ) : (
                        ""
                      )}
                    </Td>
                  ))}
                </Tr>
              )}
            </Tbody>
          </Table>
        </Box>
        <Flex
          direction={{ sm: "column", md: "row" }}
          justify="space-between"
          align="center"
          px="22px"
          w="100%"
          // px={{ md: "22px" }}
        >
          <Stack
            direction={{ sm: "column", md: "row" }}
            spacing={{ sm: "4px", md: "12px" }}
            align="center"
            // me="12px"
            my="24px"
            minW={{ sm: "100px", md: "200px" }}
          >
            <Select
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
              color="gray.400"
              size="sm"
              borderRadius="md"
              maxW="75px"
              cursor="pointer"
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={500}>500</option>
              <option value={1000}>1000</option>
            </Select>
            <Text fontSize="xs" color="gray.400" fontWeight="normal">
              entries per page
            </Text>
          </Stack>
          <Stack
            direction="row"
            alignSelf={stackAlignment}
            spacing="4px"
            ms={stackMarginStart}
          >
            <Button
              variant="no-hover"
              onClick={() => previousPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="6px"
              bg="gray.600"
              display={
                pageSize === 5 ? "none" : canPreviousPage ? "flex" : "none"
              }
              _hover={{
                bg: "gray.500",
              }}
            >
              <Icon as={GrFormPrevious} w="16px" h="16px" color="gray.400" />
            </Button>
            {createPages(pageCount)
              .slice(pageChunk * pagesPerChunk, (pageChunk + 1) * pagesPerChunk)
              .map((pageNumber, index) => (
                <Button
                  variant="no-hover"
                  transition="all .5s ease"
                  onClick={() => gotoPage(pageNumber - 1)}
                  w="40px"
                  h="40px"
                  borderRadius="6px"
                  bg={pageNumber === pageIndex + 1 ? "green.500" : "gray.600"}
                  _hover={{ bg: "gray.500" }}
                  key={index}
                >
                  <Text fontSize="xs" color="#000000">
                    {pageNumber}
                  </Text>
                </Button>
              ))}
            <Button
              variant="no-hover"
              onClick={() => {
                if ((pageChunk + 1) * pagesPerChunk < pageCount) {
                  setPageChunk(pageChunk + 1);
                }
              }}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="6px"
              bg="gray.600"
              display={pageSize === 5 ? "none" : canNextPage ? "flex" : "none"}
              _hover={{
                bg: "gray.500",
                color: "white",
              }}
            >
              <Icon as={GrFormNext} w="16px" h="16px" color="gray.400" />
            </Button>
          </Stack>
        </Flex>
      </Flex>
    </>
  );
}

export default SearchTable1;
