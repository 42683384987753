import { Badge, Flex, Image, Spinner } from "@chakra-ui/react";
import { banks } from "../../bank";
import avatar8 from "../../../assets/img/avatars/avatar8.png";
import { formatDate } from "../../../utils/DateformatUtils";
import { getDepositStatus } from "../../system";
import tw from "../../../assets/img/banks/ทรูวอเล็ต.png";
import DepositEditButtons from "../../../views/transaction-report/deposit/components/DepositEditButtons";

const statusColors = {
  SUCCESS: "green",
  ERROR: "red",
  NOT_SURE: "yellow",
  BLOCK_AUTO: "yellow",
  REJECT: "red",
  PENDING: "blue",
};

const StatusBadge = ({ status }) => {
  const colorScheme = statusColors[status] || "gray";

  return (
    <Flex align="center" justify="center">
      <Badge
        colorScheme={colorScheme}
        w="85px"
        h="28px"
        speed="0.8s"
        borderRadius="15px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {status === "PENDING" && <Spinner size="xs" mr={1} />}
        {getDepositStatus(status)}
      </Badge>
    </Flex>
  );
};

export const depositTransactionReportColumnsData = (handleModalOpen) => {
  return [
    {
      Header: "สถานะ",
      accessor: "status",
      Cell: ({ cell }) => <StatusBadge status={cell.value} />,
    },
    {
      Header: "ยูสเซอร์",
      accessor: "username",
      disableSortBy: true,
    },
    {
      Header: "ยอดเงินฝาก",
      accessor: "amount",
    },
    {
      Header: "ชื่อ-นามสกุล",
      accessor: "fullName",
      Cell: ({ row }) => {
        return (
          <>
            {row.original.firstName} {row.original.lastName}
          </>
        );
      },
    },
    {
      Header: "ยอดโบนัส",
      accessor: "bonus",
      disableSortBy: true,
    },
    {
      Header: "เครดิตก่อนฝาก",
      accessor: "beforeAmount",
    },
    {
      Header: "เครดิตหลังฝาก",
      accessor: "afterAmount",
    },
    {
      Header: "ธนาคารที่โอนเข้า",
      Cell: ({ cell, row }) => (
        <Flex align="center" justify="center" w="100%">
          {row.original.bankCode ? (
            <Image
              src={`${banks[row.original.bankCode]?.logo}`}
              alt={cell.value}
              backgroundColor={banks[row.original.bankCode]?.color}
              boxSize="30px"
              borderRadius="50%"
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            />
          ) : row.original.type === "TRUEWALLET" ? (
            <Image
              src={tw}
              alt={cell.value}
              // backgroundColor={banks[row.original.bankCode]?.color}
              boxSize="30px"
              borderRadius="50%"
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            />
          ) : (
            <Image
              src={avatar8}
              alt={cell.value}
              backgroundColor={banks[row.original.bankCode]?.color}
              boxSize="30px"
              borderRadius="50%"
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            />
          )}
        </Flex>
      ),
      disableSortBy: true,
    },
    {
      Header: "แก้ไข",
      Cell: ({ row }) => (
        <Flex align="center" justify="center" w="100%">
          <DepositEditButtons row={row} handleModalOpen={handleModalOpen} />
        </Flex>
      ),
    },
    {
      Header: "โปรโมชั่น",
      accessor: "promotionName",
    },
    {
      Header: "เพิ่มโดย",
      accessor: "createdBy",
    },
    {
      Header: "แก้ไขโดย",
      accessor: "updatedBy",
    },
    {
      Header: "วันที่ทำรายการ",
      accessor: "createdDate",
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: "วันที่อัพเดต",
      accessor: "updatedDate",
      Cell: ({ value }) => formatDate(value),
    },
  ];
};
