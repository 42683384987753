import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import { loginAuth, logout } from "../../utils/APIUtils";

import {
  APP_TOKEN,
  APP_PROFILE,
  APP_ROLES,
  ERROR_MESSAGE,
  PREFIX,
} from "variables/system";
import { useErrorHandler } from "../../hooks/useErrorHandler";

function SignUp(props) {
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  // const apiKey = process.env.REACT_APP_API_BASE_URL;

  const [prefix, setPrefix] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleError = useErrorHandler();

  useEffect(() => {
    logout();
  }, []);

  const handleLogin = () => {
    let userpass = btoa(username + ":" + password);
    loginAuth(userpass, prefix)
      .then((response) => {
        if (response.status === 200) {
          let resData = response;
          localStorage.setItem(APP_TOKEN, resData.data.token);
          localStorage.setItem(
            APP_PROFILE,
            JSON.stringify(resData.data.profile),
          );
          localStorage.setItem(PREFIX, prefix);

          let roles = resData.data.roleMenuList.menuList || [];
          localStorage.setItem(APP_ROLES, JSON.stringify(roles));
          props.history.push({
            pathname: "/admin/summary",
          });
        }
      })
      .catch((reason) => {
        if (
          reason.response &&
          reason.response.data &&
          reason.response.data.message
        ) {
          handleError(ERROR_MESSAGE, reason.response.data.message);
        }
      });
  };

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
      w="100vw"
      h="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
        <Flex
          direction="column"
          w="445px"
          background="transparent"
          borderRadius="12px"
          p="40px"
          mx={{ base: "100px" }}
          bg={bgColor}
          boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
        >
          <Text
            fontSize="22px"
            color={textColor}
            fontWeight="bold"
            textAlign="center"
            mb="8px"
          >
            System Management
          </Text>
          <Text fontSize="15px" color={textColor} textAlign="center" mb="24px">
            Please sign in to your account.
          </Text>
          <FormControl>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Prefix
            </FormLabel>
            <Input
              fontSize="sm"
              ms="4px"
              borderRadius="md"
              type="text"
              placeholder="Prefix"
              mb="12px"
              size="lg"
              onChange={(e) => setPrefix(e.target.value)}
              value={prefix}
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Username
            </FormLabel>
            <Input
              fontSize="sm"
              ms="4px"
              borderRadius="md"
              type="text"
              placeholder="Username"
              mb="12px"
              size="lg"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Password
            </FormLabel>
            <Input
              fontSize="sm"
              ms="4px"
              borderRadius="md"
              type="password"
              placeholder="Password"
              mb="24px"
              size="lg"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <Button
              type="submit"
              bg="green.500"
              fontSize="sm"
              color="#000000"
              fontWeight="bold"
              w="100%"
              h="45"
              mb="24px"
              _hover={{
                bg: "green.400",
              }}
              // _active={{
              //   bg: "green.400",
              // }}
              onClick={() => handleLogin()}
            >
              SIGN IN
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            maxW="100%"
            mt="0px"
          >
            <Text
              color={textColor}
              fontWeight="medium"
              textAlign="center"
              fontSize="sm"
            >
              2024 ©️ ASGBET All Right Reserved.
            </Text>
            <Text
              color={textColor}
              fontWeight="medium"
              textAlign="center"
              fontSize="sm"
            >
              Version (1.0.0)
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignUp;
