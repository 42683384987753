import { Button, Flex, Icon, Spinner, Stack, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import React, { useEffect, useState } from "react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import { useErrorHandler } from "hooks/useErrorHandler";
import { HiOutlinePlusSm } from "react-icons/hi";
import SearchTable1 from "components/Tables/SearchTable1";
import ManageMarketingModal from "./components/ManageMarketingModal";

import { ERROR_MESSAGE } from "variables/system";
import { marketingSettingColumnsData } from "variables/columns/system-setting/marketingSettingColumnsData";
import {
  fetchMarketingLink,
  fetchMarketingStaff,
} from "services/system-setting/staffSettingService";

const MarketingSetting = ({ pageName }) => {
  const [marketingData, setMarketingData] = useState([]);
  const [marketingLinkData, setMarketingLinkData] = useState("");

  const handleError = useErrorHandler();

  const [isLoading, setIsLoading] = useState(true);
  const [modalState, setModalState] = useState({
    manageMarketingModalOpen: false,
  });
  const [selectedMarketing, setSelectedMarketing] = useState(null);

  const handleModalOpen = (modal, marketing = null) => {
    setSelectedMarketing(marketing);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedMarketing(null);
  };

  const fetchMarketingData = () => {
    setIsLoading(true);
    fetchMarketingStaff()
      .then((response) => {
        setMarketingData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleError(error, ERROR_MESSAGE);
      });
    fetchMarketingLink()
      .then((response) => {
        setMarketingLinkData(response.data.data[1].value);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    fetchMarketingData();
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
          <Button
            backgroundColor="green.500"
            color={"#000000"}
            width={{ base: "100%", md: "auto" }}
            minW={{ base: "none", md: "280px" }}
            borderRadius="6px"
            onClick={() => handleModalOpen("manageMarketingModal")}
          >
            <Icon as={HiOutlinePlusSm} mr="5px" />
            เพิ่มการตลาด
          </Button>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.8}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  ตั้งค่าการตลาด
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                marketingLinkData && (
                  <SearchTable1
                    tableData={marketingData}
                    columnsData={marketingSettingColumnsData(
                      handleModalOpen,
                      marketingLinkData,
                    )}
                  />
                )
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
        <ManageMarketingModal
          isOpen={modalState.manageMarketingModalOpen}
          onClose={() =>
            handleModalClose("manageMarketingModal", marketingData)
          }
          marketing={selectedMarketing}
          onSuccess={fetchMarketingData}
        />
      </Stack>
    </Flex>
  );
};

export default MarketingSetting;
