import { callapi } from "../../utils/APIUtils";

export const fetchFooterSetting = () => {
  return callapi("/api/admin/footer", "GET", {});
};

export const fetchFooterIcons = () => {
  return callapi("/api/admin/icon", "POST", {});
};

export const updateFooterSetting = (payload) => {
  return callapi("/api/admin/footer", "PUT", payload);
};
export const addFooterIcon = (payload) => {
  return callapi("/api/admin/icon/v2/add", "POST", payload);
};

export const updateFooterIcon = (payload) => {
  return callapi("/api/admin/icon/v2/update", "POST", payload);
};

export const deleteFooterIcon = (id) => {
  return callapi(`/api/admin/icon/${id}`, "DELETE", {});
};
