import React, { useEffect, useState } from "react";
import { useErrorHandler } from "hooks/useErrorHandler";
import { ERROR_MESSAGE } from "variables/system";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Input,
  Spinner,
  Stack,
  Switch,
  Text,
  Textarea,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import CardBody from "components/Card/CardBody";
import { footerSettingColumnsData } from "variables/columns/website-setting/footerSettingColumnsData";

import { Field, Form, Formik } from "formik";
import {
  fetchFooterIcons,
  fetchFooterSetting,
  updateFooterSetting,
} from "services/website-setting/footerService";
import { useSuccessHandler } from "hooks/useSuccessHandler";

import SimpleTable from "components/Tables/SimpleTable";
import CardHeader from "components/Card/CardHeader";
import { HiOutlinePlusSm } from "react-icons/hi";

import DeleteFooterIconModal from "./components/DeleteFooterIconModal";
import ManageFooterIconModal from "./components/ManageFooterIconModal";

export const FooterSetting = ({ pageName }) => {
  const [footerSettingData, setFooterSettingData] = useState(null);
  const [footerTableData, setFooterTableData] = useState([]);
  const [selectedFooterIcon, setSelectedFooterIcon] = useState(null);

  const handleSuccess = useSuccessHandler();

  const handleError = useErrorHandler();

  const [isLoading, setIsLoading] = useState(true);

  const [modalState, setModalState] = useState({
    manageFooterIconModalOpen: false,
    deleteFooterIconModalOpen: false,
  });

  const handleModalOpen = (modal, icon = null) => {
    setSelectedFooterIcon(icon);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedFooterIcon(null);
  };

  const fetchFooterSettingData = () => {
    fetchFooterSetting()
      .then((response) => {
        setFooterSettingData(response.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const handleSubmit = (values) => {
    const req = { ...footerSettingData, ...values };
    updateFooterSetting(req)
      .then((response) => {
        handleSuccess(response.data.message);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const fetchFooterTableData = () => {
    setIsLoading(true);
    fetchFooterIcons()
      .then((response) => {
        setFooterTableData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    fetchFooterSettingData();
    fetchFooterTableData();
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }} overflow="hidden">
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
        </AnimatedFlex>
        <Grid templateColumns={{ sm: "1fr", lg: "1.2fr 1.8fr" }} gap="24px">
          <Formik
            initialValues={{
              title: footerSettingData?.title || "",
              mobileDescription: footerSettingData?.mobileDescription || "",
              desktopDescription: footerSettingData?.desktopDescription || "",
              status: footerSettingData?.status || "false",
            }}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, setFieldValue }) => (
              <Form>
                <AnimatedFlex duration={0.4} delay={0.6}>
                  <Card w="100%">
                    <CardBody>
                      <Grid templateColumns="1fr" gap="24px" w="100%">
                        <GridItem>
                          <FormControl>
                            <FormLabel
                              fontWeight="semibold"
                              fontSize="xs"
                              mb="10px"
                            >
                              สถานะ
                            </FormLabel>
                            <Switch
                              isChecked={values.status !== "false"}
                              onChange={(e) =>
                                setFieldValue(
                                  "status",
                                  e.target.checked.toString(),
                                )
                              }
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel
                              fontWeight="semibold"
                              fontSize="xs"
                              mb="10px"
                            >
                              Title
                            </FormLabel>
                            <Field
                              as={Input}
                              name="title"
                              borderRadius="4px"
                              placeholder="eg. Jackson"
                              fontSize="sm"
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel
                              fontWeight="semibold"
                              fontSize="xs"
                              mb="10px"
                            >
                              Mobile Description
                            </FormLabel>
                            <Field
                              as={Textarea}
                              name="mobileDescription"
                              minHeight="315px"
                              fontSize="sm"
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem>
                          <FormControl>
                            <FormLabel
                              fontWeight="semibold"
                              fontSize="xs"
                              mb="10px"
                            >
                              Desktop Description
                            </FormLabel>
                            <Field
                              as={Textarea}
                              name="desktopDescription"
                              minHeight="315px"
                              fontSize="sm"
                            />
                          </FormControl>
                        </GridItem>
                      </Grid>
                    </CardBody>
                  </Card>
                </AnimatedFlex>
                <AnimatedFlex
                  justify="flex-end"
                  pt="15px"
                  duration={0.4}
                  delay={0.6}
                >
                  <Button
                    backgroundColor="green.500"
                    color={"#000000"}
                    width="100%"
                    borderRadius="6px"
                    type="submit"
                  >
                    บันทึกข้อมูล
                  </Button>
                </AnimatedFlex>
              </Form>
            )}
          </Formik>
          <Card px="0px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex justifyContent="space-between" w="100%">
                <Text fontSize="lg" fontWeight="bold">
                  จัดจาร Icons
                </Text>
                <Button
                  onClick={() => handleModalOpen("manageFooterIconModal")}
                  ml="auto"
                  borderColor={"green.500"}
                  color={"green.500"}
                  variant="outline"
                  borderRadius="6px"
                >
                  <Icon as={HiOutlinePlusSm} mr="5px" />
                  เพิ่ม Icon
                </Button>
              </Flex>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <SimpleTable
                  tableData={footerTableData}
                  columnsData={footerSettingColumnsData(handleModalOpen)}
                />
              )}
            </CardBody>
          </Card>
        </Grid>
        <DeleteFooterIconModal
          isOpen={modalState.deleteFooterIconModalOpen}
          onClose={() => handleModalClose("deleteFooterIconModal")}
          icon={selectedFooterIcon}
          onSuccess={fetchFooterTableData}
        />
        <ManageFooterIconModal
          isOpen={modalState.manageFooterIconModalOpen}
          onClose={() => handleModalClose("manageFooterIconModal")}
          icon={selectedFooterIcon}
          onSuccess={fetchFooterTableData}
        />
      </Stack>
    </Flex>
  );
};

export default FooterSetting;
