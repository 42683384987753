import { Flex, IconButton, Text, useClipboard } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { HiOutlineSquare2Stack } from "react-icons/hi2";

export const affiliateReportColumnsData = () => {
  return [
    {
      Header: "ยูสเซอร์",
      accessor: "username",
      Cell: ({ row }) => {
        const history = useHistory();
        const { onCopy } = useClipboard(row.original.username);

        return (
          <Flex align="center" justify="center">
            <IconButton
              icon={<HiOutlineSquare2Stack />}
              onClick={onCopy}
              aria-label="Copy link"
              variant="outline"
              size="sm"
              border="none"
              mr={2}
            />
            <Text
              color={row.original.memberTagsColor}
              cursor="pointer"
              _hover={{ textDecoration: "underline" }}
              onClick={() =>
                history.push(`/admin/member/${row.original.username}`)
              }
            >
              {row.original.username}
            </Text>
          </Flex>
        );
      },
    },
    {
      Header: "จำนวน point ที่ได้รับ",
      accessor: "amount",
      isSum: true,
    },
  ];
};
