import { QueryClient, QueryClientProvider } from "react-query";

import React, { useState } from "react";
import { Flex, Stack, Text } from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import BasicTableRemote from "components/Tables/BasicTableRemote";
import { paymentGatewaySettingColumnsData } from "variables/columns/finance-setting/paymentGatewaySettingColumnsData";
import {
  fetchPaymentGatewaySetting,
  updatePaymentGatewaySetting,
} from "services/finance-setting/paymentGatewayService";
import UpdatePaymentGatewaySettingModal from "./components/UpdatePaymentGatewaySettingModal";
import { ERROR_MESSAGE } from "variables/system";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import { useErrorHandler } from "hooks/useErrorHandler";

const PaymentGatewaySetting = ({ pageName }) => {
  const queryClient = new QueryClient();

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const [modalState, setModalState] = useState({
    updatePaymentGatewayModalOpen: false,
  });

  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState(null);

  const [showTable, setShowTable] = useState(true);

  const handleModalOpen = (modal, paymentGateway = null) => {
    setSelectedPaymentGateway(paymentGateway);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setShowTable(false);
    queryClient.refetchQueries();
    setShowTable(true);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedPaymentGateway(null);
  };

  const onToggleColumn = (obj) => {
    updatePaymentGatewaySetting(obj)
      .then((response) => {
        handleSuccess(response.data.message);
        queryClient.refetchQueries();
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.8}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  ตั้งค่า Payment Gateway
                </Text>
              </Flex>
            </CardHeader>
            {showTable ? (
              <QueryClientProvider client={queryClient}>
                <BasicTableRemote
                  columnsData={paymentGatewaySettingColumnsData(
                    handleModalOpen,
                    onToggleColumn,
                  )}
                  query={"banks"}
                  fetchData={fetchPaymentGatewaySetting}
                  isPagination={false}
                />
              </QueryClientProvider>
            ) : null}
          </Card>
        </AnimatedFlex>
        <UpdatePaymentGatewaySettingModal
          isOpen={modalState.updatePaymentGatewayModalOpen}
          onClose={() => handleModalClose("updatePaymentGatewayModal")}
          paymentGateway={selectedPaymentGateway}
        />
      </Stack>
    </Flex>
  );
};

export default PaymentGatewaySetting;
