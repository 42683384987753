import {
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import { HiOutlinePlusSm } from "react-icons/hi";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "hooks/useErrorHandler";
import { ERROR_MESSAGE } from "variables/system";
import BannerCard from "./components/BannerCard";
import ManageBannerModal from "./components/ManageBannerModal";
import DeleteBannerModal from "./components/DeleteBannerModal";
import { fetchBanner } from "services/website-setting/bannerService";

const BannerSetting = ({ pageName }) => {
  const [bannerData, setBannerData] = useState([]);

  const [selectedBanner, setSelectedBanner] = useState(null);

  const handleError = useErrorHandler();

  const [modalState, setModalState] = useState({
    manageBannerModalOpen: false,
    deleteBannerModalOpen: false,
  });

  const fetchBannerData = () => {
    fetchBanner()
      .then((response) => {
        setBannerData(response.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const handleModalOpen = (modal, banner = null) => {
    setSelectedBanner(banner);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedBanner(null);
  };

  useEffect(() => {
    fetchBannerData();
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }} overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
          <Button
            backgroundColor="green.500"
            color={"#000000"}
            width={{ base: "100%", md: "auto" }}
            minW={{ base: "none", md: "280px" }}
            borderRadius="6px"
            onClick={() => handleModalOpen("manageBannerModal")}
          >
            <Icon as={HiOutlinePlusSm} mr="5px" />
            เพิ่มแบนเนอร์
          </Button>
        </AnimatedFlex>
        <AnimatedFlex justify="flex-end" duration={0.4} delay={0.6}>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6} w="100%">
            {bannerData.map((banner) => (
              <Box key={banner.id}>
                <BannerCard
                  banner={banner}
                  onEdit={() => handleModalOpen("manageBannerModal", banner)}
                  onDelete={() => handleModalOpen("deleteBannerModal", banner)}
                />
              </Box>
            ))}
          </SimpleGrid>
        </AnimatedFlex>
        <ManageBannerModal
          isOpen={modalState.manageBannerModalOpen}
          onClose={() => handleModalClose("manageBannerModal")}
          banner={selectedBanner}
          onSuccess={fetchBannerData}
        />
        <DeleteBannerModal
          isOpen={modalState.deleteBannerModalOpen}
          onClose={() => handleModalClose("deleteBannerModal")}
          banner={selectedBanner}
          onSuccess={fetchBannerData}
        />
      </Stack>
    </Flex>
  );
};

export default BannerSetting;
