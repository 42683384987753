import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Grid,
  GridItem,
  Text,
  HStack,
  useBreakpointValue,
  Stack,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";

import { ERROR_MESSAGE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import { validateField } from "../../../../utils/CommonUtils";
import {
  addTrueWalletSetting,
  updateTrueWalletSetting,
} from "services/finance-setting/trueWalletSettingService";
import { useSuccessHandler } from "hooks/useSuccessHandler";

const ManageTrueWalletModal = ({ isOpen, onClose, truewallet = null }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const modalSize = useBreakpointValue({ base: "full", md: "md" });

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const handleSaveMember = (values) => {
    if (values.id) {
      updateBank(values);
    } else {
      insertBank(values);
    }

    onClose();
  };

  const insertBank = (obj) => {
    addTrueWalletSetting({ ...obj, bankName: obj.bankCode })
      .then((response) => {
        handleSuccess(response.data.message);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };
  const updateBank = (obj) => {
    updateTrueWalletSetting(obj.id, { ...obj, bankName: obj.bankCode })
      .then((response) => {
        handleSuccess(response.data.message);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={modalSize}
    >
      <ModalOverlay />
      <ModalContent
        maxH="100vh"
        p={8}
        borderRadius={{ base: "none", md: "md" }}
      >
        <ModalHeader>
          <Box textAlign="center">
            <Text fontSize="26px">
              {truewallet ? "แก้ไข ทรูวอลเล็ต" : "เพิ่ม ทรูวอลเล็ต"}
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <Formik
            initialValues={{
              id: truewallet ? truewallet.id : "",
              phoneNumber: truewallet ? truewallet.phoneNumber : "",
              name: truewallet ? truewallet.name : "",
              bankGroup: truewallet ? truewallet.bankGroup : "",
              newUserFlag: truewallet ? truewallet.newUserFlag : true,
              active: truewallet ? truewallet.active : true,
              key: truewallet ? truewallet.key : "",
            }}
            onSubmit={(values, actions) => {
              handleSaveMember(values);
              actions.setSubmitting(false);
            }}
          >
            {(props) => (
              <Form>
                <Grid
                  templateColumns={{ base: "1fr", md: "repeat(1, 1fr)" }}
                  gap={4}
                >
                  <GridItem>
                    <Field name="name" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ชื่อ
                          </FormLabel>
                          <Input {...field} placeholder="ชื่อ" fontSize="sm" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field name="phoneNumber" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.phoneNumber && form.touched.phoneNumber
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            เลขที่บัญชี True wallet
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="เลขที่บัญชี"
                            fontSize="sm"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field name="key" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.key && form.touched.key && !truewallet
                          }
                          isRequired={!truewallet}
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            Key
                          </FormLabel>
                          <Input
                            {...field}
                            fontSize="sm"
                            placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field name="bankGroup" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.bankGroup && form.touched.bankGroup
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            กลุ่ม
                          </FormLabel>
                          <Input {...field} placeholder="กลุ่ม" fontSize="sm" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>

                  <GridItem>
                    <Box mt={4}>
                      <HStack spacing={4}>
                        <Field name="newUserFlag">
                          {({ field }) => (
                            <FormControl display="flex" alignItems="center">
                              <Stack>
                                <FormLabel
                                  htmlFor="is-bonus-enabled"
                                  mb="0"
                                  fontSize="xs"
                                  fontWeight="bold"
                                >
                                  สำหรับยูสเซอร์ใหม่
                                </FormLabel>
                                <Switch
                                  id="is-bonus-enabled"
                                  {...field}
                                  isChecked={field.value}
                                />
                              </Stack>
                            </FormControl>
                          )}
                        </Field>
                        <Field name="active">
                          {({ field }) => (
                            <FormControl display="flex" alignItems="center">
                              <Stack>
                                <FormLabel
                                  htmlFor="is-no-bonus"
                                  mb="0"
                                  fontSize="xs"
                                  fontWeight="bold"
                                >
                                  สถานะเปิดใช้งาน
                                </FormLabel>
                                <Switch
                                  id="is-no-bonus"
                                  {...field}
                                  isChecked={field.value}
                                />
                              </Stack>
                            </FormControl>
                          )}
                        </Field>
                      </HStack>
                    </Box>
                  </GridItem>
                </Grid>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    color={"#000000"}
                    backgroundColor="green.500"
                    w="100%"
                    type="submit"
                    isLoading={props.isSubmitting}
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManageTrueWalletModal;
