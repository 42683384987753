import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react";
import React from "react";

import { ADD_CREDIT, ALL, ERROR_MESSAGE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import { Field, Form, Formik } from "formik";
import { validateField } from "../../../../utils/CommonUtils";
import { createMistakeCredit } from "services/member-management/memberService";
import { useSuccessHandler } from "hooks/useSuccessHandler";

const AddCreditModal = ({ isOpen, onClose, setSearch, member }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const handleAddCredit = async (values, actions) => {
    createMistakeCredit(values)
      .then((response) => {
        onClose();
        if (response.status === 200) {
          handleSuccess(response.data.message);
          setSearch();
        }
      })
      .catch((error) => {
        onClose();
        handleError(error, ERROR_MESSAGE);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent p={8}>
        <ModalHeader>
          <Box textAlign="center">
            <Text fontSize="26px">เพิ่่มรายการฝาก</Text>
            <Text
              fontSize="15px"
              color="rgb(116,116,116)"
              fontWeight="normal"
              mt={4}
            >
              เพิ่มรายการฝาก (ฝากมือ)
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              type: ADD_CREDIT,
              username: member?.username,
              credit: "",
              reason: "",
              turnOver: 0,
              minWithdraw: 0,
              bonus: 0,
              typeGame: ALL,
            }}
            onSubmit={(values, actions) => {
              handleAddCredit(values, actions);
            }}
          >
            {(props) => {
              return (
                <Form>
                  <Field name="credit" validate={validateField}>
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.credit && form.touched.credit}
                        isRequired
                      >
                        <FormLabel fontSize="xs" fontWeight="bold">
                          จำนวนเงิน
                        </FormLabel>
                        <NumberInput
                          defaultValue={0}
                          precision={2}
                          step={0.2}
                          value={props.values.credit}
                          onChange={(valueString) =>
                            props.setFieldValue("credit", valueString)
                          }
                        >
                          <NumberInputField
                            ref={initialRef}
                            {...field}
                            fontSize="sm"
                          />
                        </NumberInput>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="bonus">
                    {({ field }) => (
                      <FormControl mt={4}>
                        <FormLabel fontSize="xs" fontWeight="bold">
                          จำนวนโบนัส
                        </FormLabel>
                        <NumberInput
                          value={props.values.bonus}
                          onChange={(valueString) =>
                            props.setFieldValue("bonus", valueString)
                          }
                        >
                          <NumberInputField
                            ref={initialRef}
                            {...field}
                            fontSize="sm"
                          />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="turnOver">
                    {({ field }) => (
                      <FormControl mt={4}>
                        <FormLabel fontSize="xs" fontWeight="bold">
                          จำนวนเทินโอเวอร์
                        </FormLabel>
                        <NumberInput
                          value={props.values.turnOver}
                          onChange={(valueString) =>
                            props.setFieldValue("turnOver", valueString)
                          }
                        >
                          <NumberInputField
                            ref={initialRef}
                            {...field}
                            fontSize="sm"
                          />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="minWithdraw">
                    {({ field, form }) => (
                      <FormControl
                        mt={4}
                        isInvalid={
                          form.errors.minWithdraw && form.touched.minWithdraw
                        }
                        isRequired
                      >
                        <FormLabel fontSize="xs" fontWeight="bold">
                          ถอนขั้นต่ำ
                        </FormLabel>
                        <NumberInput
                          defaultValue={0}
                          precision={2}
                          step={0.2}
                          value={props.values.minWithdraw}
                          onChange={(valueString) =>
                            props.setFieldValue("minWithdraw", valueString)
                          }
                        >
                          <NumberInputField
                            ref={initialRef}
                            {...field}
                            fontSize="sm"
                          />
                        </NumberInput>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="maxWithdraw">
                    {({ field, form }) => (
                      <FormControl
                        mt={4}
                        isInvalid={
                          form.errors.maxWithdraw && form.touched.maxWithdraw
                        }
                        isRequired
                      >
                        <FormLabel fontSize="xs" fontWeight="bold">
                          เมื่อถอนจะได้เครดิต
                        </FormLabel>
                        <NumberInput
                          defaultValue={0}
                          precision={2}
                          step={0.2}
                          value={props.values.maxWithdraw}
                          onChange={(valueString) =>
                            props.setFieldValue("maxWithdraw", valueString)
                          }
                        >
                          <NumberInputField
                            ref={initialRef}
                            {...field}
                            fontSize="sm"
                          />
                        </NumberInput>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="reason" validate={validateField}>
                    {({ field, form }) => (
                      <FormControl
                        mt={4}
                        isInvalid={form.errors.reason && form.touched.reason}
                        isRequired
                      >
                        <FormLabel fontSize="xs" fontWeight="bold">
                          หมายเหตุ
                        </FormLabel>
                        <Input
                          {...field}
                          placeholder="หมายเหตุ"
                          fontSize="sm"
                        />
                      </FormControl>
                    )}
                  </Field>
                  <ModalFooter pl={0} pr={0} mt={4}>
                    <Button
                      backgroundColor="green.500"
                      color={"#000000"}
                      w="100%"
                      isLoading={props.isSubmitting}
                      disabled={props.isSubmitting}
                      type="submit"
                    >
                      ทำรายการ
                    </Button>
                  </ModalFooter>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddCreditModal;
