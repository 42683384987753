import { Box, Flex, Spacer, Spinner, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import "assets/css/styles.css";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import SimpleTable from "components/Tables/SimpleTable";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import moment from "moment/moment";

import { ERROR_MESSAGE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import { getReferenceDates } from "utils/DateformatUtils";
import { fetchListMarketingReport } from "services/website-report/marketingReportService";
import { marketingReportColumnsData } from "variables/columns/website-report/marketingReportColumnsData";

import { mapJsonToTableData } from "utils/CommonUtils";
import useDateRange from "hooks/useDateRange";
import FileExportDropdown from "components/FileExportDropdown/FileExportDropdown";

const MarketingReport = ({ pageName }) => {
  const { dateNow, dateDayCheck } = getReferenceDates();
  const [marketingReportData, setMarketingReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [downloadableData, setDownloadableData] = useState([]);
  const handleError = useErrorHandler();
  // const role = JSON.parse(localStorage.getItem("APP_PROFILE")).role;

  const dateSearchFormat = "yyyy-MM-DD HH:mm:ss";

  const [search, setSearch] = useState({
    createdDateFrom: moment(dateDayCheck).format(dateSearchFormat),
    createdDateTo: moment(dateNow).format(dateSearchFormat),
  });

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };

  const { selectedDates, handleDateChange } = useDateRange(
    [dateDayCheck, dateNow],
    search,
    setSearch,
    dateFieldsNames,
    dateSearchFormat,
  );

  const handleSearchData = () => {
    setIsLoading(true);
    fetchListMarketingReport(search)
      .then((response) => {
        setMarketingReportData(response.data.data);
        const formattedRecords = mapJsonToTableData(
          marketingReportData,
          marketingReportColumnsData,
        );
        setDownloadableData(formattedRecords);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    handleSearchData();
  }, [search, setSearch]);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex
          justify="space-between"
          align="center"
          duration={0.4}
          delay={0.4}
        >
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
          <Box>
            <CustomRangePicker
              selectedDates={selectedDates}
              onDateChange={handleDateChange}
            />
          </Box>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  สรุป กำไร-ขาดทุน (การตลาด)
                </Text>
              </Flex>
              <Spacer />
              <FileExportDropdown
                data={downloadableData}
                filename={"รายงานสรุป กำไร-ขาดทุน (การตลาด)"}
              />
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <SimpleTable
                  tableData={marketingReportData}
                  columnsData={marketingReportColumnsData}
                />
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default MarketingReport;
