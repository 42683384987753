import { callapi } from "../../utils/APIUtils";
import { PREFIX } from "../../variables/system";

export const fetchWebSetting = () => {
  const prefix = localStorage.getItem(PREFIX);
  return callapi(`/api/admin/agent/${prefix}`, "GET", {});
};

export const updateWebSetting = (payload) => {
  return callapi("/api/admin/agent", "PUT", payload);
};
