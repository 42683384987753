import { DEVICE_DROPDOWN } from "../variables/system";

export const numberToStringFormat = (num) => {
  const checknum = Number(num);
  if (!isNaN(checknum)) {
    return checknum.toLocaleString("en-US", { style: "decimal" });
  } else {
    return "";
  }
};

export const numberToStringFormat2Digit = (num) => {
  const checknum = Number(num);
  if (!isNaN(checknum)) {
    let digit2 = Number(checknum.toFixed(2));
    return digit2;
  } else {
    return "";
  }
};

export const validateField = (value) => {
  let error;
  if (!value) {
    error = "This field is required";
  }
  return error;
};

export const validatePassword = (value, staff) => {
  let error;
  if (!staff && !value) {
    error = "Password is required";
  }
  return error;
};

export const getDeviceText = (value) => {
  const device = DEVICE_DROPDOWN.find((device) => device.val === value);
  return device ? device.text : value;
};

export const getMemberLevelColorScheme = (value) => {
  switch (value) {
    case "4":
      return "blue";
    case "3":
      return "yellow";
    case "2":
      return "gray";
    case "1":
      return "default";
    case "0":
      return "default";
    default:
      return "default";
  }
};

export const mapJsonToTableData = (jsonData, columnsData) => {
  return jsonData.map((item) => {
    const mappedRow = {};

    columnsData.forEach((column) => {
      const accessor = column.accessor;
      const header = column.Header;

      if (accessor && header) {
        mappedRow[header] = item[accessor] !== undefined ? item[accessor] : "-";
      }
    });

    return mappedRow;
  });
};

export const getProviderNiceName = (name) => {
  switch (name) {
    case "pg":
      return "PGSOFT";
    case "dg":
      return "Dream Gaming";
    case "pp":
      return "Pragmatic Play";
    case "sa":
      return "SA Gaming";
    case "sexy":
      return "Sexy Gaming";
    case "hab":
      return "Habanero";
    case "joker":
      return "Joker";
    case "ep":
      return "EvoPlay";
    case "fc":
      return "Fachai";
    case "mg":
      return "Micro Gaming";
    case "bpg":
      return "Blueprint Gaming";
    case "rlx":
      return "Relax Gaming";
    case "bng":
      return "Booongo";
    case "kgl":
      return "Kalamba Games";
    case "bg":
      return "BG";
    case "ygg":
      return "Yggdrasil";
    case "waz":
      return "Wazdan";
    case "aws":
      return "AE Gaming Slot";
    case "ag":
      return "Asia Gaming";
    case "ng":
      return "Naga Games";
    case "netent":
      return "NetEnt";
    case "ttg":
      return "TTG";
    case "swg":
      return "SkyWind Group";
    case "bs":
      return "Betsoft";
    case "bfs":
      return "BF Sports";
    case "we":
      return "WE Entertainment";
    case "wm":
      return "WM";
    case "eg":
      return "Evolution Gaming";
    case "km":
      return "KINGMAKER";
    case "funky":
      return "Funky Games";
    case "ps":
      return "PlayStar";
    case "1x2":
      return "1X2 Gaming";
    case "qs":
      return "Quickspin";
    case "ga":
      return "Game Art";
    case "sp":
      return "SimplePlay";
    case "ds":
      return "Dragoon Soft";
    case "nlc":
      return "Nolimit City";
    case "red":
      return "Red Tiger";
    case "ids":
      return "Iron Dog";
    case "tk":
      return "Thunderkick";
    case "elk":
      return "ELK Studios";
    case "prs":
      return "Print Studios";
    case "hak":
      return "Hacksaw Gaming";
    case "png":
      return "Play n Go";
    case "max":
      return "Maverick";
    case "pug":
      return "Push Gaming";
    case "nge":
      return "NetGames Entertainment";
    case "fng":
      return "Fantasma Gaming";
    default:
      return name;
  }
};
