import React, { useState } from "react";
import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { FieldArray, Form, Formik } from "formik";
import { SimpleHSeparator } from "components/Separator/Separator";
import { getMemberLevelColorScheme } from "../../../../utils/CommonUtils";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CommissionSettingModal from "./ComissionSettingModal";
import { HiCog6Tooth } from "react-icons/hi2";

const CommissionForm = ({
  title,
  initialValues,
  handleSubmit,
  memberLevelData,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalData, setModalData] = useState(null);
  const [modalIndex, setModalIndex] = useState(null);

  const getMemberLevelName = (level) => {
    const memberLevel = memberLevelData.find(
      (member) => parseInt(member.seqLevel) + 1 === level,
    );
    return memberLevel ? memberLevel.levelName : "-";
  };

  const openModal = (data, index) => {
    setModalData(data);
    setModalIndex(index);
    onOpen();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, setFieldValue }) => {
        const handleModalSubmit = (updatedData) => {
          setFieldValue(`commissions.${modalIndex}.setting`, updatedData);
          onClose();
        };

        return (
          <Form>
            <Flex direction="column">
              <Stack direction="column" spacing="24px" w="100%">
                <FieldArray name="commissions">
                  <>
                    <Card
                      w={{ sm: "100%" }}
                      alignSelf="flex-end"
                      justifySelf="flex-end"
                    >
                      <CardHeader pb="12px">
                        <Flex justify="space-between" align="center" w="100%">
                          <Text fontSize="lg" fontWeight="bold">
                            {title}
                          </Text>
                        </Flex>
                      </CardHeader>
                      <SimpleHSeparator />
                      <CardBody pt="24px">
                        <Grid
                          templateColumns={{
                            base: "repeat(1, 1fr)",
                            sm: "repeat(5, 1fr)",
                            md: "repeat(10, 1fr)",
                          }}
                          gap="24px"
                          w="100%"
                        >
                          {values.commissions.map((commission, index) => (
                            <React.Fragment key={index}>
                              <GridItem colSpan="auto" minWidth="auto">
                                <FormControl>
                                  <FormLabel
                                    fontWeight="semibold"
                                    fontSize="xs"
                                    mb="10px"
                                  >
                                    สถานะ
                                  </FormLabel>
                                  <Switch
                                    isChecked={commission.active}
                                    onChange={() =>
                                      setFieldValue(
                                        `commissions.${index}.active`,
                                        !commission.active,
                                      )
                                    }
                                  />
                                </FormControl>
                              </GridItem>
                              <GridItem colSpan={{ base: 1, sm: 1, md: 1 }}>
                                <FormControl>
                                  <FormLabel
                                    fontWeight="semibold"
                                    fontSize="xs"
                                    mb="10px"
                                  >
                                    เลเวล
                                  </FormLabel>
                                  <Flex alignItems="center">
                                    <Badge
                                      colorScheme={getMemberLevelColorScheme(
                                        commission.level.toString(),
                                      )}
                                      w="65px"
                                      h="28px"
                                      borderRadius="15px"
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                      mr={4}
                                    >
                                      {getMemberLevelName(commission.level)}
                                    </Badge>
                                  </Flex>
                                </FormControl>
                              </GridItem>
                              <GridItem colSpan={{ base: 1, sm: 3, md: 3 }}>
                                <FormControl>
                                  <FormLabel
                                    fontWeight="semibold"
                                    fontSize="xs"
                                    mb="10px"
                                  >
                                    จ่ายขั้นต่ำ{" "}
                                    <Box
                                      as="span"
                                      color="green.500"
                                      fontWeight="normal"
                                    >
                                      (ต่อการเดิมพัน)
                                    </Box>
                                  </FormLabel>
                                  <NumberInput
                                    value={commission.minComm}
                                    onChange={(valueString) =>
                                      setFieldValue(
                                        `commissions.${index}.minComm`,
                                        valueString,
                                      )
                                    }
                                    min={0}
                                    step={0.01}
                                  >
                                    <NumberInputField fontSize="sm" />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                </FormControl>
                              </GridItem>
                              <GridItem colSpan={{ base: 1, sm: 3, md: 3 }}>
                                <FormControl>
                                  <FormLabel
                                    fontWeight="semibold"
                                    fontSize="xs"
                                    mb="10px"
                                  >
                                    จ่ายสูงสุด{" "}
                                    <Box
                                      as="span"
                                      color="green.500"
                                      fontWeight="normal"
                                    >
                                      (ต่อการเดิมพัน)
                                    </Box>
                                  </FormLabel>
                                  <NumberInput
                                    value={commission.maxComm}
                                    onChange={(valueString) =>
                                      setFieldValue(
                                        `commissions.${index}.maxComm`,
                                        valueString,
                                      )
                                    }
                                    min={0}
                                    step={0.01}
                                  >
                                    <NumberInputField fontSize="sm" />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                </FormControl>
                              </GridItem>
                              <GridItem
                                colSpan={{ base: 1, sm: 2, md: 2 }}
                                display="flex"
                                justifyContent="center"
                                alignItems="end"
                              >
                                <IconButton
                                  variant="outline"
                                  colorScheme="blue"
                                  onClick={() =>
                                    openModal(commission.setting, index)
                                  }
                                  icon={<HiCog6Tooth />}
                                  mr={2}
                                />
                              </GridItem>
                            </React.Fragment>
                          ))}
                        </Grid>
                      </CardBody>
                      <Flex justifyContent="flex-end" width="100%" mt={6}>
                        <Button
                          color={"#000000"}
                          type="submit"
                          backgroundColor="green.500"
                          width={{ base: "100%", md: "auto" }}
                          minW={{ base: "none", md: "280px" }}
                          borderRadius="6px"
                        >
                          บันทึกข้อมูล
                        </Button>
                      </Flex>
                    </Card>
                  </>
                </FieldArray>
              </Stack>
            </Flex>
            {modalData && (
              <CommissionSettingModal
                isOpen={isOpen}
                onClose={onClose}
                modalData={modalData}
                handleSubmit={handleModalSubmit}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default CommissionForm;
