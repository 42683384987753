import React from "react";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import { useErrorHandler } from "hooks/useErrorHandler";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { ERROR_MESSAGE } from "variables/system";
import { refreshErrorWithdrawTransaction } from "services/transaction-report/withdrawTransactionReportService";

const RefreshWithdrawErrorTransactionModal = ({
  isOpen,
  onClose,
  setSearch,
  transaction,
}) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const handleRefreshErrorWithdrawTransaction = async (values, actions) => {
    refreshErrorWithdrawTransaction(values)
      .then((response) => {
        onClose();
        if (response.status === 200) {
          handleSuccess(response.data.message);
          setSearch();
        }
      })
      .catch((error) => {
        onClose();
        handleError(error, ERROR_MESSAGE);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent p={8}>
        <ModalHeader>
          <Box textAlign="center">
            <Text fontSize="26px">ทำรายการใหม่</Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              withdrawId: transaction?.id,
            }}
            onSubmit={(values, actions) => {
              handleRefreshErrorWithdrawTransaction(values, actions);
            }}
          >
            {(props) => (
              <Form>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    backgroundColor="green.500"
                    color={"#000000"}
                    w="100%"
                    type="submit"
                    isLoading={props.isSubmitting}
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RefreshWithdrawErrorTransactionModal;
