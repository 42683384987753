import React from "react";
import Select from "react-select";

const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
    borderColor: "#3c3c3c",
    color: "#E2E8F0",
    fontSize: "14px",
    width: "100%",
    borderRadius: "0.375rem",
    "&:hover": {
      borderColor: "#3c3c3c",
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "rgba(23,23,23,1)",
    color: "#ccc",
    zIndex: 9999,
    fontSize: "14px",
    borderRadius: "0.375rem",
    border: "1px solid #3c3c3c",
  }),
  option: (provided) => ({
    ...provided,
    backgroundColor: "rgba(23,23,23,1)",
    color: "#E2E8F0",
    zIndex: 9999,
    fontSize: "14px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#E2E8F0",
    fontSize: "14px",
  }),
  input: (provided) => ({
    ...provided,
    color: "#E2E8F0",
    fontSize: "14px",
  }),
};

const SearchableDropdown = ({
  options,
  isSearchable,
  placeholder,
  onChange,
}) => {
  return (
    <Select
      options={options}
      isSearchable={isSearchable}
      placeholder={placeholder}
      styles={customStyles}
      onChange={onChange}
    />
  );
};

export default SearchableDropdown;
