import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

import { ERROR_MESSAGE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import { deleteMemberTag } from "services/member-management/memberTagService";
import { useSuccessHandler } from "hooks/useSuccessHandler";

const DeleteMemberTagModal = ({ isOpen, onClose, tag, onSuccess }) => {
  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const handleDeleteMemberTag = async () => {
    deleteMemberTag(tag.id)
      .then((response) => {
        onClose();
        if (response.status === 200) {
          handleSuccess(response.data.message);
          onSuccess();
        }
      })
      .catch((error) => {
        onClose();
        handleError(error, ERROR_MESSAGE);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="center">ยืนยันทำรายการ</Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>คุณแน่ใจจะลบ Tag: {tag?.name}?</ModalBody>
        <ModalFooter>
          <Button
            backgroundColor="green.500"
            color="#000000"
            onClick={handleDeleteMemberTag}
            w="100%"
          >
            ทำรายการ
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteMemberTagModal;
