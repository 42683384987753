import { Badge, Flex, Icon, IconButton } from "@chakra-ui/react";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";
import { HiDesktopComputer, HiDeviceMobile } from "react-icons/hi";

export const footerSettingColumnsData = (handleModalOpen) => [
  {
    Header: "Icon",
    accessor: "imageUrl",
    Cell: ({ value }) => (
      <Flex justifyContent="center" alignItems="center" height="100%">
        <img
          src={value}
          alt="icon"
          style={{ width: "24px", height: "24px", objectFit: "contain" }}
        />
      </Flex>
    ),
  },
  {
    Header: "ชื่อ",
    accessor: "name",
  },
  {
    Header: "ประเภท",
    accessor: "type",
  },
  {
    Header: "ลำดับ",
    accessor: "sort",
  },
  {
    Header: "อุปกรณ์",
    accessor: "device",
    Cell: ({ value }) => (
      <div style={{ textAlign: "center" }}>
        {value === "1" ? (
          <Icon as={HiDeviceMobile} w={6} h={6} color="teal.300" />
        ) : (
          <Icon as={HiDesktopComputer} w={6} h={6} color="orange.300" />
        )}
      </div>
    ),
  },
  {
    Header: "สถานะ",
    accessor: "status",
    Cell: ({ cell }) => (
      <Flex align="center" justify="center">
        <Badge
          colorScheme={cell.value === "true" ? "green" : "red"}
          w="65px"
          h="28px"
          borderRadius="15px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {cell.value === "true" ? "ACTIVE" : "INACTIVE"}
        </Badge>
      </Flex>
    ),
  },
  {
    Header: "แก้ไข",
    Cell: ({ row }) => (
      <IconButton
        variant="outline"
        colorScheme="green"
        aria-label="edit"
        size="sm"
        icon={<HiOutlinePencil />}
        onClick={() => handleModalOpen("manageFooterIconModal", row.original)}
      />
    ),
  },
  {
    Header: "ลบ",
    Cell: ({ row }) => (
      <IconButton
        variant="outline"
        colorScheme="red"
        aria-label="delete"
        size="sm"
        icon={<Icon as={HiOutlineTrash} />}
        onClick={() => handleModalOpen("deleteFooterIconModal", row.original)}
      />
    ),
  },
];
