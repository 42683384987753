import {
  Text,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useErrorHandler } from "hooks/useErrorHandler";

import { ERROR_MESSAGE } from "variables/system";
import React from "react";
import { deletePromotionSetting } from "services/system-setting/promotionSettingService";
import { useSuccessHandler } from "hooks/useSuccessHandler";

const DeletePromotionModal = ({ isOpen, onClose, promotion, onSuccess }) => {
  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const handleDeletePromotion = async () => {
    deletePromotionSetting(promotion.id)
      .then((response) => {
        if (response.status === 200) {
          handleSuccess(response.data.message);
          onSuccess();
        }
        onClose();
      })
      .catch((error) => {
        onClose();
        handleError(error, ERROR_MESSAGE);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="center">ยืนยันทำรายการ</Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{`คุณแน่ใจจะลบโปรโมชั่น: ${promotion?.name} ?`}</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            backgroundColor="green.500"
            color={"#000000"}
            onClick={handleDeletePromotion}
            w="100%"
          >
            ทำรายการ
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeletePromotionModal;
