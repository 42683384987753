import { callapi } from "../../utils/APIUtils";

export const fetchRole = () => {
  return callapi("/api/admin/rolemenu/all", "GET", {});
};

export const fetchRoleById = (id) => {
  return callapi(`/api/admin/rolemenu/${id}`, "GET", {});
};

export const saveRole = (payload) => {
  return callapi("/api/admin/rolemenu", "PUT", payload);
};
