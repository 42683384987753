import { Flex, Image } from "@chakra-ui/react";
import { banks } from "../../bank";
import { formatDateWithoutMeridiem } from "../../../utils/DateformatUtils";
import avatar8 from "../../../assets/img/avatars/avatar8.png";

export const dashboardLastDeposit = [
  {
    Header: "ยูสเซอร์เนม",
    accessor: "username",
  },
  {
    Header: "ธนาคาร",
    accessor: "bankCode",
    Cell: ({ cell, row }) => (
      <Flex align="center" justify="center" w="100%">
        <Image
          src={`${banks[row.original.bankCode]?.logo}`}
          alt={cell.value}
          backgroundColor={banks[row.original.bankCode]?.color}
          boxSize="30px"
          borderRadius="50%"
          boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
        />
      </Flex>
    ),
  },
  {
    Header: "ยอด",
    accessor: "amount",
  },
  {
    Header: "โบนัส",
    accessor: "bonus",
  },
  {
    Header: "หมายเหตุ",
    accessor: "reason",
    disableSortBy: true,
  },
  {
    Header: "เวลา",
    accessor: "createdDate",
    Cell: ({ value }) => formatDateWithoutMeridiem(value),
  },
];

export const dashboardLastWithdraw = [
  {
    Header: "ยูสเซอร์เนม",
    accessor: "username",
  },
  {
    Header: "ธนาคาร",
    accessor: "bankCode",
    Cell: ({ cell, row }) => (
      <Flex align="center" justify="center" w="100%">
        {row.original.bankCode ? (
          <Image
            src={`${banks[row.original.bankCode]?.logo}`}
            alt={cell.value}
            backgroundColor={banks[row.original.bankCode]?.color}
            boxSize="30px"
            borderRadius="50%"
            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
          />
        ) : (
          <Image
            src={avatar8}
            alt={cell.value}
            backgroundColor={banks[row.original.bankCode]?.color}
            boxSize="30px"
            borderRadius="50%"
            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
          />
        )}
      </Flex>
    ),
  },
  {
    Header: "ยอด",
    accessor: "amount",
  },
  {
    Header: "หมายเหตุ",
    accessor: "reason",
    disableSortBy: true,
  },
  {
    Header: "เวลา",
    accessor: "createdDate",
    Cell: ({ value }) => formatDateWithoutMeridiem(value),
  },
];
