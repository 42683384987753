import { Button, Flex, Icon, Spinner, Stack, Text } from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import SimpleTable from "components/Tables/SimpleTable";
import React, { useEffect, useMemo, useState } from "react";

import { useErrorHandler } from "hooks/useErrorHandler";
import { roleSettingColumnsData } from "variables/columns/system-setting/roleSettingColumnsData";
import { fetchRole } from "services/system-setting/roleSettingService";
import { ERROR_MESSAGE } from "variables/system";
import { HiOutlinePlusSm } from "react-icons/hi";
import { useHistory } from "react-router-dom";

const RoleSetting = ({ pageName }) => {
  const [roleData, setRoleData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleError = useErrorHandler();
  const history = useHistory();

  const columns = useMemo(() => roleSettingColumnsData(history), [history]);

  const fetchRoleData = () => {
    setIsLoading(true);
    fetchRole()
      .then((response) => {
        setRoleData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    fetchRoleData();
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }} overflow="hidden">
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
          <Button
            color={"#000000"}
            backgroundColor="green.500"
            width={{ base: "100%", md: "auto" }}
            minW={{ base: "none", md: "280px" }}
            borderRadius="6px"
            onClick={() => history.push("/admin/system-setting/role/add")}
          >
            <Icon as={HiOutlinePlusSm} mr="5px" />
            เพิ่มตำแหน่ง
          </Button>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="0px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  ตำแหน่งทั้งหมด
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <SimpleTable tableData={roleData} columnsData={columns} />
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default RoleSetting;
