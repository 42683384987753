import { callapi } from "../../utils/APIUtils";

export const addPopup = (payload) => {
  return callapi("/api/admin/banner-popup/v2/add", "POST", payload);
};

export const fetchPopup = () => {
  return callapi("/api/admin/banner-popup", "POST", {});
};

export const updatePopup = (payload) => {
  return callapi("/api/admin/banner-popup/v2/update", "POST", payload);
};

export const deletePopup = (id) => {
  return callapi(`/api/admin/banner-popup/${id}`, "DELETE", {});
};
