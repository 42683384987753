import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
} from "@chakra-ui/react";
import { useCSVDownloader } from "react-papaparse";
import React from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const FileExportDropdown = ({ data, filename }) => {
  const { CSVDownloader, Type } = useCSVDownloader();
  const handleExcelExport = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(dataBlob, `${filename}.xlsx`);
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        borderColor="green.500"
        borderWidth="1px"
        ml={2}
        color="green.500"
        backgroundColor="transparent"
      >
        Export
      </MenuButton>
      <MenuList>
        <MenuItem>
          <CSVDownloader filename={filename} data={data} type={Type.Button}>
            <Text fontWeight="normal" fontSize="sm">
              Export as CSV
            </Text>
          </CSVDownloader>
        </MenuItem>
        <MenuItem onClick={handleExcelExport}>
          <Text fontWeight="normal" fontSize="sm">
            Export as Excel
          </Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default FileExportDropdown;
