import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { ERROR_MESSAGE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import { Field, Form, Formik } from "formik";
import { validateField } from "../../../../utils/CommonUtils";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import {
  submitJackpot,
  verifyJackpot,
} from "services/system-setting/jackpotSettingService";

const ManageJackpotModal = ({ isOpen, onClose, jackpot, onSuccess }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const [isUserVerified, setIsUserVerified] = useState(false);
  const [verifySuccessMessage, setVerifySuccessMessage] = useState("");
  const [verifyUnSuccessMessage, setVerifyUnSuccessMessage] = useState("");

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const handleGiveJackpot = async (values, actions) => {
    submitJackpot(values)
      .then((response) => {
        if (response.status === 200) {
          handleSuccess(response.data.message);
          setVerifySuccessMessage("");
          setVerifyUnSuccessMessage("");
          onClose();
          onSuccess();
        }
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
        onClose();
        setVerifySuccessMessage("");
        setVerifyUnSuccessMessage("");
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const handleVerifyJackpot = async (username) => {
    verifyJackpot({ username: username })
      .then((response) => {
        if (response.status === 200) {
          setIsUserVerified(true);
          setVerifySuccessMessage("Verify ยูสเซอร์สำเร็จ");
          setVerifyUnSuccessMessage("");
        }
      })
      .catch(() => {
        setIsUserVerified(false);
        setVerifySuccessMessage("");
        setVerifyUnSuccessMessage("ไม่มียูสเซอร์นี้อยู่ในระบบ");
      });
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent p={8}>
        <ModalHeader>
          <Box textAlign="center">
            <Flex alignItems="center" justifyContent="center">
              <Text fontSize="xl">ให้แจ๊คพ๊อต</Text>
              <Badge
                colorScheme={
                  jackpot?.type === "Grand"
                    ? "red"
                    : jackpot?.type === "Major"
                    ? "pink"
                    : jackpot?.type === "Minor"
                    ? "blue"
                    : "green"
                }
                w="65px"
                h="28px"
                borderRadius="15px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                ml="2"
              >
                {jackpot?.type}
              </Badge>
            </Flex>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              type: jackpot?.type,
              username: "",
              turnover: 0,
            }}
            enableReinitialize
            onSubmit={(values, actions) => {
              handleGiveJackpot(values, actions);
            }}
          >
            {(props) => (
              <Form>
                <Field name="username" validate={validateField}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.username && form.touched.username}
                      isRequired
                      mt={4}
                    >
                      <FormLabel fontSize="xs" fontWeight="bold">
                        ยูสเซอร์เนม
                      </FormLabel>
                      <Flex>
                        <Input
                          {...field}
                          placeholder="ยูสเซอร์เนม"
                          fontSize="sm"
                        />
                        <Button
                          ml="2"
                          fontSize="sm"
                          colorScheme="green"
                          variant="outline"
                          onClick={() => {
                            handleVerifyJackpot(field.value);
                          }}
                        >
                          ตรวจสอบ
                        </Button>
                      </Flex>
                      {isUserVerified && (
                        <Text fontSize="xs" color="green.500" mt="2">
                          {verifySuccessMessage}
                        </Text>
                      )}
                      {!isUserVerified && verifyUnSuccessMessage && (
                        <Text fontSize="xs" color="red.500" mt="2">
                          {verifyUnSuccessMessage}
                        </Text>
                      )}
                    </FormControl>
                  )}
                </Field>
                <Field name="turnover">
                  {({ field }) => (
                    <FormControl mt={4}>
                      <FormLabel fontSize="xs" fontWeight="bold">
                        จำนวนเทินโอเวอร์
                      </FormLabel>
                      <NumberInput
                        value={props.values.turnOver}
                        onChange={(valueString) =>
                          props.setFieldValue("turnOver", valueString)
                        }
                      >
                        <NumberInputField
                          ref={initialRef}
                          {...field}
                          fontSize="sm"
                        />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </FormControl>
                  )}
                </Field>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    backgroundColor="green.500"
                    color={"#000000"}
                    w="100%"
                    isLoading={props.isSubmitting}
                    type="submit"
                    disabled={!isUserVerified}
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManageJackpotModal;
