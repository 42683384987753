import Card from "components/Card/Card";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { banks } from "variables/bank";

const TopTransactionCard = ({
  bankCode,
  username,
  phoneNumber,
  number,
  isDeposit,
}) => {
  const color = isDeposit ? "green.500" : "red.500";
  const prefix = isDeposit ? "+" : "-";

  return (
    <Card padding="8px 10px">
      <Flex align="center" justify="space-between">
        <Box>
          <Image
            src={`${banks[bankCode]?.logo}`}
            boxSize="30px"
            borderRadius="full"
            backgroundColor={banks[bankCode]?.color}
            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            alt="Stat Image"
          />
        </Box>
        <Box flex="1" textAlign="center">
          <Text display="block" fontSize="sm">
            {phoneNumber}
          </Text>
          <Text display="block" color="grey" fontSize="xs">
            {username}
          </Text>
        </Box>
        <Box>
          <Text fontWeight="normal" color={color} fontSize="sm">
            {prefix}
            {number} ฿
          </Text>
        </Box>
      </Flex>
    </Card>
  );
};

export default TopTransactionCard;
