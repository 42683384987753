import {
  Flex,
  Stack,
  SimpleGrid,
  Box,
  Button,
  Icon,
  Text,
} from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import PromotionCard from "./components/PromotionCard";
import React, { useEffect, useState } from "react";
import { useErrorHandler } from "hooks/useErrorHandler";
import { ERROR_MESSAGE } from "variables/system";
import { HiOutlinePlusSm } from "react-icons/hi";
import DeletePromotionModal from "./components/DeletePromotionModal";
import { fetchPromotionSetting } from "services/system-setting/promotionSettingService";
import { useHistory } from "react-router-dom";

const PromotionSetting = ({ pageName }) => {
  const [promotionData, setPromotionData] = useState([]);
  const [selectedPromotion, setSelectedPromotion] = useState(null);

  const handleError = useErrorHandler();

  const [modalState, setModalState] = useState({
    deletePromotionModalOpen: false,
  });

  const fetchPromotionData = () => {
    fetchPromotionSetting()
      .then((response) => {
        setPromotionData(response.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    fetchPromotionData();
  }, []);

  const handleModalOpen = (modal, promotion = null) => {
    setSelectedPromotion(promotion);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedPromotion(null);
  };

  const history = useHistory();

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }} overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
          <Button
            backgroundColor="green.500"
            color={"#000000"}
            width={{ base: "100%", md: "auto" }}
            minW={{ base: "none", md: "280px" }}
            borderRadius="6px"
            onClick={() => history.push("/admin/system-setting/promotion/add")}
          >
            <Icon as={HiOutlinePlusSm} mr="5px" />
            เพิ่มโปรโมชั่น
          </Button>
        </AnimatedFlex>
        <AnimatedFlex justify="flex-end" duration={0.4} delay={0.6}>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6} w="100%">
            {promotionData.map((promo) => (
              <Box key={promo.id}>
                <PromotionCard
                  promotion={promo}
                  onEdit={() =>
                    history.push({
                      pathname: `/admin/system-setting/promotion/edit/${promo.id}`,
                      state: {
                        promotion: promo,
                      },
                    })
                  }
                  onDelete={() =>
                    handleModalOpen("deletePromotionModal", promo)
                  }
                />
              </Box>
            ))}
          </SimpleGrid>
        </AnimatedFlex>
        <DeletePromotionModal
          isOpen={modalState.deletePromotionModalOpen}
          onClose={() => handleModalClose("deletePromotionModal")}
          promotion={selectedPromotion}
          onSuccess={fetchPromotionData}
        />
      </Stack>
    </Flex>
  );
};

export default PromotionSetting;
