import React from "react";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import { useErrorHandler } from "hooks/useErrorHandler";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { validateField } from "../../../../utils/CommonUtils";
import { createBlockAutoWithdrawTransaction } from "services/transaction-report/withdrawTransactionReportService";
import { ERROR_MESSAGE } from "variables/system";

const RejectWithdrawTransactionModal = ({
  isOpen,
  onClose,
  setSearch,
  transaction,
}) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const handleRejectWithdrawTransaction = async (values, actions) => {
    createBlockAutoWithdrawTransaction(values)
      .then((response) => {
        onClose();
        if (response.status === 200) {
          handleSuccess(response.data.message);
          setSearch();
        }
      })
      .catch((error) => {
        onClose();
        handleError(error, ERROR_MESSAGE);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent p={8}>
        <ModalHeader>
          <Box textAlign="center">
            <Text fontSize="26px">ยกเลิกรายการถอน</Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              withdrawId: transaction?.id,
              status: "REJECT",
              reason: "",
            }}
            onSubmit={(values, actions) => {
              handleRejectWithdrawTransaction(values, actions);
            }}
          >
            {(props) => (
              <Form>
                <Field name="status">
                  {({ form }) => (
                    <FormControl
                      display="flex"
                      flexDirection="column"
                      alignItems="left"
                      mt={4}
                    >
                      <FormLabel
                        htmlFor="status-toggle"
                        mb="2"
                        fontSize="xs"
                        fontWeight="bold"
                        textAlign="left"
                      >
                        คืนเครดิต
                      </FormLabel>
                      <Switch
                        id="status-toggle"
                        onChange={(e) => {
                          const newStatus = e.target.checked
                            ? "REJECT_N_REFUND"
                            : "REJECT";
                          form.setFieldValue("status", newStatus);
                        }}
                      />
                    </FormControl>
                  )}
                </Field>

                <Field name="reason" validate={validateField}>
                  {({ field, form }) => (
                    <FormControl
                      mt={4}
                      isInvalid={form.errors.reason && form.touched.reason}
                      isRequired
                    >
                      <FormLabel fontSize="xs" fontWeight="bold">
                        หมายเหตุ
                      </FormLabel>
                      <Input {...field} placeholder="หมายเหตุ" fontSize="sm" />
                    </FormControl>
                  )}
                </Field>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    backgroundColor="green.500"
                    color={"#000000"}
                    w="100%"
                    type="submit"
                    isLoading={props.isSubmitting}
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RejectWithdrawTransactionModal;
