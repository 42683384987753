import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Grid,
  GridItem,
  Text,
  useBreakpointValue,
  Select,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { DEVICE_DROPDOWN, ERROR_MESSAGE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import ImageUploader from "components/ImageUploader/ImageUploader";
import moment from "moment/moment";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import { addPopup, updatePopup } from "services/website-setting/popupService";

const ManagePopupModal = ({ isOpen, onClose, popup = null, onSuccess }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const modalSize = useBreakpointValue({ base: "full", md: "6xl" });
  const gridTemplateColumns = useBreakpointValue({
    base: "1fr",
    lg: "1fr 2fr",
  });
  const buttonSize = useBreakpointValue({ base: "100%", md: "65.7%" });

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const handleSubmit = (values, actions) => {
    const postValues = {
      ...values,
      startDate: moment(values.startDate).format("yyyy/MM/DD"),
      endDate: moment(values.endDate).format("yyyy/MM/DD"),
    };

    const updateValues = {
      ...popup,
      ...values,
      startDate: moment(values.startDate).format("yyyy/MM/DD"),
      endDate: moment(values.endDate).format("yyyy/MM/DD"),
    };

    const response = popup ? updatePopup(updateValues) : addPopup(postValues);

    response
      .then((response) => {
        if (response.status === 200) {
          handleSuccess(response.data.message);
          onSuccess();
        }
        onClose();
      })
      .catch((error) => {
        onClose();
        handleError(error, ERROR_MESSAGE);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={modalSize}
    >
      <ModalOverlay />
      <ModalContent
        maxH="100vh"
        p={8}
        borderRadius={{ base: "none", md: "md" }}
      >
        <ModalHeader>
          <Box textAlign="center">
            <Text fontSize="26px">
              {popup ? `แก้ไข Popup: ${popup?.name}` : "เพิ่ม Popup"}
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <Formik
            initialValues={{
              name: popup?.name || "",
              content: popup?.content || "",
              actionLink: popup?.actionLink || "",
              image: popup?.image || "",
              device: popup?.device || "",
              language: popup?.language || "TH",
              sort: popup?.sort || 1,
              status: popup?.status || true,
              startDate: moment(popup?.startDate).format("yyyy-MM-DD") || "",
              endDate: moment(popup?.endDate).format("yyyy-MM-DD") || "",
            }}
            onSubmit={(values, actions) => {
              handleSubmit(values, actions);
            }}
            enableReinitialize
          >
            {({ values, handleChange, setFieldValue, isSubmitting }) => (
              <Form>
                <Grid templateColumns={gridTemplateColumns} gap={4}>
                  <Box>
                    <ImageUploader
                      initialImage={values.image}
                      onUpload={(uri) => setFieldValue("image", uri)}
                    />
                  </Box>
                  <Box>
                    <Grid
                      templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                      gap={4}
                    >
                      <GridItem colSpan={2}>
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ชื่อโปรโมชั่น
                          </FormLabel>
                          <Input
                            name="name"
                            onChange={handleChange}
                            value={values.name}
                            fontSize="sm"
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={2}>
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            คอนเทนต์
                          </FormLabel>
                          <Input
                            name="content"
                            onChange={handleChange}
                            value={values.content}
                            fontSize="sm"
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={2}>
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ลิ้งค์
                          </FormLabel>
                          <Input
                            name="actionLink"
                            onChange={handleChange}
                            value={values.actionLink}
                            fontSize="sm"
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={{ base: 1, md: 1 }}>
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ลำดับ
                          </FormLabel>
                          <Input
                            name="sort"
                            onChange={handleChange}
                            value={values.sort}
                            fontSize="sm"
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={{ base: 1, md: 1 }}>
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            อุปกรณ์
                          </FormLabel>
                          <Select
                            name="device"
                            onChange={handleChange}
                            value={values.device}
                            placeholder="เลือกอุปกรณ์"
                            fontSize="sm"
                          >
                            {DEVICE_DROPDOWN.map((type, id) => (
                              <option key={id} value={type.val}>
                                {type.text}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={{ base: 1, md: 1 }}>
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            จากวันที่
                          </FormLabel>
                          <Input
                            name="startDate"
                            onChange={(e) =>
                              setFieldValue("startDate", e.target.value)
                            }
                            value={values.startDate}
                            fontSize="sm"
                            type="date"
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={{ base: 1, md: 1 }}>
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ถึงวันที่
                          </FormLabel>
                          <Input
                            name="endDate"
                            onChange={(e) =>
                              setFieldValue("endDate", e.target.value)
                            }
                            value={values.endDate}
                            fontSize="sm"
                            type="date"
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <FormControl>
                          <FormLabel mb={0} fontSize="xs" fontWeight="bold">
                            Active
                          </FormLabel>
                          <Switch
                            isChecked={values.status === "true"}
                            onChange={(e) =>
                              setFieldValue(
                                "status",
                                e.target.checked.toString(),
                              )
                            }
                          />
                        </FormControl>
                      </GridItem>
                    </Grid>
                  </Box>
                </Grid>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    backgroundColor="green.500"
                    color="#000000"
                    w={buttonSize}
                    type="submit"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManagePopupModal;
