import {
  Button,
  Flex,
  Icon,
  Spacer,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import React, { useEffect, useState } from "react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import SimpleTable from "components/Tables/SimpleTable";
import { memberTagColumnsData } from "variables/columns/member-management/memberTagColumnsData";

import { ERROR_MESSAGE } from "variables/system";
import { HiOutlinePlusSm } from "react-icons/hi";
import { useErrorHandler } from "hooks/useErrorHandler";
import ManageMemberTagModal from "./components/ManageMemberTagModal";
import DeleteMemberTagModal from "./components/DeleteMemberTagModal";
import { fetchMemberTag } from "services/member-management/memberTagService";

const MemberTag = ({ pageName }) => {
  const [memberTagData, setMemberTagData] = useState([]);

  const [modalState, setModalState] = useState({
    manageMemberTagModalOpen: false,
    deleteMemberTagModalOpen: false,
  });

  const [selectedTag, setSelectedTag] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const handleError = useErrorHandler();

  const handleModalOpen = (modal, tag = null) => {
    setSelectedTag(tag);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedTag(null);
  };

  const fetchMemberTagData = () => {
    setIsLoading(true);
    fetchMemberTag()
      .then((response) => {
        setMemberTagData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    fetchMemberTagData();
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontSize="lg" fontWeight="bold">
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.8}>
          <Card px="0px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text fontSize="18px" fontWeight="bold" mb="6px">
                  จัดการแท็คสมาชิก
                </Text>
              </Flex>
              <Spacer />
              <Button
                backgroundColor="green.500"
                color={"#000000"}
                width={{ base: "100%", md: "auto" }}
                // minW={{ base: "none", md: "280px" }}
                borderRadius="6px"
                onClick={() => handleModalOpen("manageMemberTagModal")}
              >
                <Icon as={HiOutlinePlusSm} mr="5px" />
                เพิ่ม Tag
              </Button>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <SimpleTable
                  tableData={memberTagData}
                  columnsData={memberTagColumnsData(handleModalOpen)}
                />
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
        <ManageMemberTagModal
          isOpen={modalState.manageMemberTagModalOpen}
          onClose={() => handleModalClose("manageMemberTagModal")}
          tag={selectedTag}
          onSuccess={fetchMemberTagData}
        />
        <DeleteMemberTagModal
          isOpen={modalState.deleteMemberTagModalOpen}
          onClose={() => handleModalClose("deleteMemberTagModal")}
          tag={selectedTag}
          onSuccess={fetchMemberTagData}
        />
      </Stack>
    </Flex>
  );
};

export default MemberTag;
