import { Badge, Flex, Image, Text } from "@chakra-ui/react";
import { banks } from "../../bank";
import { formatDate } from "../../../utils/DateformatUtils";
import { useHistory } from "react-router-dom";

export const newMemberTodayColumnsData = () => {
  return [
    {
      Header: "ยูสเซอร์",
      accessor: "username",
      Cell: ({ row }) => {
        const history = useHistory();
        return (
          <Text
            color={row.original.memberTagsColor}
            cursor="pointer"
            _hover={{ textDecoration: "underline" }}
            onClick={() =>
              history.push(`/admin/member/${row.original.username}`)
            }
          >
            {row.original.username}
          </Text>
        );
      },
    },
    {
      Header: "เบอร์โทร",
      accessor: "tel",
    },
    {
      Header: "ชื่อ-นามสกุล",
      accessor: "fullName",
    },
    {
      Header: "ธนาคาร",
      accessor: "ธนาคาร",
      Cell: ({ cell, row }) => (
        <Flex align="center" justify="center" w="100%">
          <Image
            src={`${banks[row.original.bankName]?.logo}`}
            alt={cell.value}
            backgroundColor={banks[row.original.bankName]?.color}
            boxSize="30px"
            borderRadius="50%"
            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
          />
        </Flex>
      ),
      disableSortBy: true,
    },
    {
      Header: "เลขบัญชี",
      accessor: "accountNumber",
    },
    {
      Header: "สถานะโปรโมชั่น",
      accessor: "isBonus",
      Cell: ({ cell }) => (
        <Flex align="center" justify="center">
          <Badge
            colorScheme={cell.value === true ? "green" : "red"}
            w="90px"
            h="28px"
            borderRadius="15px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {cell.value === true ? "รับโปรโมชั่น" : "ไม่รับโปรโมชั่น"}
          </Badge>
        </Flex>
      ),
    },
    {
      Header: "วันลงทะเบียน",
      accessor: "createdDate",
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: "IP",
      accessor: "ip",
      disableSortBy: true,
    },
  ];
};
