import {
  Text,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useErrorHandler } from "hooks/useErrorHandler";

import { ERROR_MESSAGE } from "variables/system";
import React from "react";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import { deletePopup } from "services/website-setting/popupService";

const DeletePopupModal = ({ isOpen, onClose, popup, onSuccess }) => {
  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const handleDeletePopup = async () => {
    deletePopup(popup.id)
      .then((response) => {
        if (response.status === 200) {
          handleSuccess(response.data.message);
          onSuccess();
        }
        onClose();
      })
      .catch((error) => {
        onClose();
        handleError(error, ERROR_MESSAGE);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="center">ยืนยันทำรายการ</Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{`คุณแน่ใจจะลบ ป๊อปอัพ: ${popup?.name} ?`}</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            backgroundColor="green.500"
            color="#000000"
            onClick={handleDeletePopup}
            w="100%"
          >
            ทำรายการ
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeletePopupModal;
