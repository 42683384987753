import {
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Box,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import { useSortBy, useTable } from "react-table";
import "../../assets/css/styles.css";

function SimpleTable(props) {
  const { columnsData, tableData, rowSize } = props;
  const columns = useMemo(() => columnsData, []);
  const data = useMemo(() => tableData.slice(0, rowSize), []);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    tableInstance;

  return (
    <>
      <Flex direction="column" w="100%" mt="15px">
        <Box overflowX="auto" w="100%">
          <Table
            {...getTableProps()}
            variant="simple"
            color="gray.500"
            mb="24px"
            w="100%"
          >
            <Thead backgroundColor="rgb(29,29,29)">
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={index}
                      pe="0px"
                      p={3}
                      minW="175px"
                      textAlign="center"
                    >
                      <Flex
                        justify="center"
                        align="center"
                        fontSize={{ sm: "10px", lg: "13px" }}
                        color="white"
                      >
                        {column.render("Header")}
                        {!column.disableSortBy && (
                          <Icon
                            w={{ sm: "10px", md: "14px" }}
                            h={{ sm: "10px", md: "14px" }}
                            color={column.isSorted ? "gray.500" : "gray.400"}
                            float="right"
                            as={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? TiArrowSortedDown
                                  : TiArrowSortedUp
                                : TiArrowUnsorted
                            }
                          />
                        )}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      return (
                        <Td
                          {...cell.getCellProps()}
                          fontSize="sm"
                          key={index}
                          color="white"
                          p={2}
                          minW="175px"
                          textAlign="center"
                          className="td-border-bottom"
                        >
                          {cell.render("Cell")}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Flex>
    </>
  );
}

export default SimpleTable;
