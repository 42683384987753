import React, { useEffect, useState } from "react";
import SockJS from "sockjs-client";
import Stomp from "stompjs";

import {
  API_BASE_URL,
  URL_WEBSOCKET,
  ERROR_MESSAGE,
  PREFIX,
} from "../../variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";

const getRandomMilliseconds = () => {
  const minSeconds = 30; // Minimum number of seconds (30 seconds)
  const maxSeconds = 60; // Maximum number of seconds (1 minute)

  // Convert seconds to milliseconds
  const minMilliseconds = minSeconds * 1000;
  const maxMilliseconds = maxSeconds * 1000;

  // Generate a random number between minMilliseconds and maxMilliseconds
  const randomMilliseconds =
    Math.floor(Math.random() * (maxMilliseconds - minMilliseconds + 1)) +
    minMilliseconds;

  return randomMilliseconds;
};

const NotificationWebsocket = ({ setTask }) => {
  const [sock, setSock] = useState(null);
  const audioRef = React.createRef();
  const soundFile = "/sound/noti-iPhone.mp3";

  const handleError = useErrorHandler();

  useEffect(() => {
    const notisound = audioRef.current;
    const connect = () => {
      const socket = new SockJS(API_BASE_URL + URL_WEBSOCKET);
      const stompClient = Stomp.over(socket);
      const prefix = localStorage.getItem(PREFIX);
      // Connect to the WebSocket server
      stompClient.connect({}, () => {
        // console.log('Connected to WebSocket', profile.prefix, notisound);
        // Subscribe to a specific destination/topic
        stompClient.subscribe("/topic/noti-" + prefix, (message) => {
          // console.log("Received message from WebSocket:", message.body);
          let obj = JSON.parse(message.body);
          setTask(obj);
          if (notisound && obj.isSound) {
            let isplay = notisound.cloneNode(true).play();
            // console.log(isplay);
            if (isplay) {
              isplay
                .then(() => {
                  // Autoplay started!
                })
                .catch((error) => {
                  // console.log(error);
                  handleError(ERROR_MESSAGE, error);
                  // todo toast
                });
            }
          }
        });
      });

      socket.onclose = () => {
        // console.log("Connection closed. Reconnecting...");
        reconnect();
      };

      setSock(socket);
    };

    const reconnect = () => {
      setTimeout(connect, getRandomMilliseconds()); // Reconnect after a delay (e.g., 60000 milliseconds)
    };

    connect();

    return () => {
      if (sock) {
        sock.close();
      }
    };
  }, []); // Run the effect only once during component mount

  return (
    <div>
      <audio id="soundnoti" ref={audioRef}>
        <source src={soundFile} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      {/* <button onClick={() => { audioRef.current.cloneNode(true).play() }}>Test</button> */}
    </div>
  );
};

export default NotificationWebsocket;
