import { callapi } from "../../utils/APIUtils";

export const fetchAdminProfile = (username) => {
  return callapi(`/api/admin/get/${username}`, "GET", {});
};

export const updateAdminProfile = (payload) => {
  return callapi("/api/admin/update-admin", "PUT", payload);
};

export const fetchPendingTransaction = () => {
  return callapi("/api/admin/task", "GET", {});
};
