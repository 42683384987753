import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";

import { HiOutlinePlusSm } from "react-icons/hi";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";

import { ERROR_MESSAGE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import PopupCard from "./components/PopupCard";
import ManagePopupModal from "./components/ManagePopupModal";
import DeletePopupModal from "./components/DeletePopupModal";
import { fetchPopup } from "services/website-setting/popupService";

const PopupSetting = ({ pageName }) => {
  const [popupData, setPopupData] = useState([]);

  const [selectedPopup, setSelectedPopup] = useState(null);

  const handleError = useErrorHandler();

  const [modalState, setModalState] = useState({
    managePopupModalOpen: false,
    deletePopupModalOpen: false,
  });

  const fetchPopupData = () => {
    fetchPopup()
      .then((response) => {
        setPopupData(response.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const handleModalOpen = (modal, popup = null) => {
    setSelectedPopup(popup);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedPopup(null);
  };

  useEffect(() => {
    fetchPopupData();
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="15px" w="100%" h="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.6}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
          <Button
            backgroundColor="green.500"
            color={"#000000"}
            width={{ base: "100%", md: "auto" }}
            minW={{ base: "none", md: "280px" }}
            borderRadius="6px"
            onClick={() => handleModalOpen("managePopupModal")}
          >
            <Icon as={HiOutlinePlusSm} mr="5px" />
            เพิ่มป๊อปอัพ
          </Button>
        </AnimatedFlex>
        <AnimatedFlex justify="flex-end" duration={0.4} delay={0.8}>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6} w="100%">
            {popupData.map((popup) => (
              <Box key={popup.id}>
                <PopupCard
                  popup={popup}
                  onEdit={() => handleModalOpen("managePopupModal", popup)}
                  onDelete={() => handleModalOpen("deletePopupModal", popup)}
                />
              </Box>
            ))}
          </SimpleGrid>
        </AnimatedFlex>
        <ManagePopupModal
          isOpen={modalState.managePopupModalOpen}
          onClose={() => handleModalClose("managePopupModal")}
          popup={selectedPopup}
          onSuccess={fetchPopupData}
        />
        <DeletePopupModal
          isOpen={modalState.deletePopupModalOpen}
          onClose={() => handleModalClose("deletePopupModal")}
          popup={selectedPopup}
          onSuccess={fetchPopupData}
        />
      </Stack>
    </Flex>
  );
};

export default PopupSetting;
