import { Flex, Icon, IconButton, Image, Switch } from "@chakra-ui/react";
import { banks } from "../../bank";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi2";

export const banksSettingColumnsData = (onToggleColumn, handleModalOpen) => [
  {
    Header: "ประเภทบริการ",
    accessor: "serviceType",
    disableSortBy: true,
  },
  {
    Header: "ธนาคาร",
    Cell: ({ cell, row }) => (
      <Flex align="center" justify="center" w="100%">
        <Image
          src={`${banks[row.original.bankCode]?.logo}`}
          alt={cell.value}
          backgroundColor={banks[row.original.bankCode]?.color}
          boxSize="30px"
          borderRadius="50%"
          boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
        />
      </Flex>
    ),
    disableSortBy: true,
  },
  {
    Header: "ชื่อบัญชี",
    accessor: "bankAccountName",
    disableSortBy: true,
  },
  {
    Header: "เลขบัญชี",
    accessor: "bankAccountNo",
    disableSortBy: true,
  },
  {
    Header: "ฝาก",
    accessor: "ฝาก",
    Cell: ({ row }) => (
      <Switch
        size="md"
        colorScheme="green"
        onChange={() =>
          onToggleColumn({
            ...row.original,
            bankType:
              row.original.bankType == "DEPOSIT" ? "WITHDRAW" : "DEPOSIT",
          })
        }
        isChecked={row.original.bankType == "DEPOSIT" ? true : false}
      />
    ),
    disableSortBy: true,
  },
  {
    Header: "ถอน",
    accessor: "ถอน",
    Cell: ({ row }) => {
      return (
        row.original.serviceType !== "SCBCONNECT" && (
          <Switch
            size="md"
            colorScheme="green"
            onChange={() =>
              onToggleColumn({
                ...row.original,
                bankType:
                  row.original.bankType === "WITHDRAW" ? "DEPOSIT" : "WITHDRAW",
              })
            }
            isChecked={row.original.bankType === "WITHDRAW"}
          />
        )
      );
    },
    disableSortBy: true,
  },

  {
    Header: "สถานะเปิดใช้งาน",
    accessor: "active",
    Cell: ({ row }) => (
      <Switch
        size="md"
        colorScheme="green"
        onChange={() =>
          onToggleColumn({
            ...row.original,
            active: !row.original.active,
          })
        }
        isChecked={row.original.active}
      />
    ),
    disableSortBy: true,
  },
  {
    Header: "แก้ไข",
    accessor: "แก้ไข",
    Cell: ({ row }) => (
      <IconButton
        variant="outline"
        borderColor={"green.500"}
        color={"green.500"}
        aria-label="edit"
        size="sm"
        icon={<Icon as={HiOutlinePencil} />}
        onClick={() => handleModalOpen("manageBankModal", row.original)}
      >
        แก้ไข
      </IconButton>
    ),
    disableSortBy: true,
  },
  {
    Header: "ลบ",
    accessor: "ลบ",
    Cell: ({ row }) => (
      <IconButton
        variant="outline"
        borderColor={"red.500"}
        color={"red.500"}
        aria-label="delete"
        size="sm"
        icon={<Icon as={HiOutlineTrash} />}
        onClick={() => handleModalOpen("deleteBankModal", row.original)}
      >
        แก้ไข
      </IconButton>
    ),
    disableSortBy: true,
  },
];
