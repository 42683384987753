import { Button, Flex, Spinner, Stack, Switch, Text } from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import SimpleTable from "components/Tables/SimpleTable";

import React, { useEffect, useState } from "react";
import { useErrorHandler } from "hooks/useErrorHandler";

import {
  fetchJackpot,
  saveJackpot,
} from "services/system-setting/jackpotSettingService";
import { jackpotSettingColumnsData } from "variables/columns/system-setting/jackpotSettingColumnsData";

import ManageJackpotModal from "./components/ManageJackpotModal";
import {
  transformJackpotArrayToJson,
  transformJackpotJsonToArray,
} from "../../../utils/JackpotUtils";
import { ERROR_MESSAGE } from "variables/system";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import JackpotBotSettingModal from "./components/JackpotBotSettingModal";

const JackpotSetting = ({ pageName }) => {
  const [jackpotData, setJackpotData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedJackpot, setSelectedJackpot] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const [modalState, setModalState] = useState({
    manageJackpotModalOpen: false,
    jackpotBotSettingModalOpen: false,
  });

  const handleModalOpen = (modal, jackpot = null) => {
    setSelectedJackpot(jackpot);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedJackpot(null);
  };

  const fetchJackpotData = () => {
    setIsLoading(true);
    fetchJackpot()
      .then((response) => {
        const data = response.data.data;
        const formattedData = transformJackpotJsonToArray(data);
        setIsActive(response.data.data.active);
        setJackpotData(formattedData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    fetchJackpotData();
  }, []);

  const handleValueChange = (id, field, value) => {
    setJackpotData((prevData) => {
      return prevData.map((jackpot) => {
        if (jackpot.id === id) {
          return { ...jackpot, [field]: parseFloat(value) };
        }
        return jackpot;
      });
    });
  };

  const handleSave = () => {
    const data = transformJackpotArrayToJson(jackpotData);
    const combinedData = { ...data, active: isActive };
    saveJackpot(combinedData)
      .then((response) => {
        if (response.status === 200) {
          handleSuccess(response.data.message);
          fetchJackpotData();
        }
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const handleSwitchChange = () => {
    setIsActive(!isActive);
  };

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }}>
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="0px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex justifyContent="space-between" alignItems="center" w="100%">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  รางวัลแจ๊คพ๊อต
                </Text>
                <Flex direction="column" alignItems="right">
                  <Text
                    color="white"
                    fontSize="12px"
                    mb="2px"
                    fontWeight="bold"
                  >
                    เปิด/ปิด
                  </Text>
                  <Switch
                    colorScheme="green"
                    isChecked={isActive}
                    onChange={handleSwitchChange}
                  />
                </Flex>
              </Flex>
            </CardHeader>
            <CardBody>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <SimpleTable
                  tableData={jackpotData}
                  columnsData={jackpotSettingColumnsData(
                    handleModalOpen,
                    handleValueChange,
                  )}
                />
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
        <AnimatedFlex justify="flex-end" duration={0.4} delay={0.8}>
          <Button
            color={"#000000"}
            backgroundColor="green.500"
            width={{ base: "100%", md: "auto" }}
            minW={{ base: "none", md: "280px" }}
            borderRadius="6px"
            onClick={handleSave}
          >
            บันทึกข้อมูล
          </Button>
        </AnimatedFlex>
        <ManageJackpotModal
          isOpen={modalState.manageJackpotModalOpen}
          onClose={() => handleModalClose("manageJackpotModal")}
          jackpot={selectedJackpot}
          onSuccess={fetchJackpotData}
        />
        <JackpotBotSettingModal
          isOpen={modalState.jackpotBotSettingModalOpen}
          onClose={() => handleModalClose("jackpotBotSettingModal")}
          jackpot={selectedJackpot}
          jackpotData={transformJackpotArrayToJson(jackpotData)}
          onSuccess={fetchJackpotData}
        />
      </Stack>
    </Flex>
  );
};

export default JackpotSetting;
