import { Flex } from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionFlex = motion(Flex);

const AnimatedFlex = ({ children, duration = 0.4, delay = 0.4, ...props }) => {
  return (
    <MotionFlex
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration, delay, ease: "easeInOut" }}
      {...props}
    >
      {children}
    </MotionFlex>
  );
};
export default AnimatedFlex;
