import moment from "moment";
import { format, parseISO } from "date-fns";

export const formatDate = (isoString) => {
  try {
    const date = parseISO(isoString);
    if (isNaN(date)) {
      throw new Error("Invalid date");
    }

    return format(date, "dd-MM-yy HH:mm:ss");
  } catch (e) {
    console.error("Error formatting date:", e);
    return "-";
  }
};

export const getReferenceDates = () => {
  const dateNow = new Date();
  let dateDayCheck = new Date();
  dateDayCheck.setHours(0, 0, 0, 0);

  if (dateNow.getTime() < dateDayCheck.getTime()) {
    dateDayCheck.setDate(dateDayCheck.getDate() - 1);
  }

  return { dateNow, dateDayCheck };
};

export const formatDateWithoutMeridiem = (dateString) => {
  return moment(dateString, "DD/MM/YYYY hh:mm:ss A").format(
    "DD/MM/YYYY HH:mm:ss",
  );
};
