import { ChakraProvider, Portal, useDisclosure } from "@chakra-ui/react";
import "assets/css/pud-dashboard-styles.css";
import avatar8 from "../assets/img/avatars/avatar8.png";
// Custom components
import MainPanel from "components/Layout/MainPanel";
import PanelContainer from "components/Layout/PanelContainer";
import PanelContent from "components/Layout/PanelContent";
// Layout components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useState } from "react";
import "react-quill/dist/quill.snow.css"; // ES6
import { Redirect, Route, Switch } from "react-router-dom";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
// Custom Chakra theme
import theme from "theme/theme.js";
import { APP_PROFILE, APP_ROLES } from "../variables/system";
import { callapi, logout } from "../utils/APIUtils";

import MemberProfile from "../views/shared/member-profile";
import AdminProfile from "../views/shared/admin-profile";
import ManageRoleView from "../views/system-setting/role/views/ManageRoleView";

import useDashRoutes from "../hooks/useDashRoutes.js";
import ManagePromotionView from "../views/system-setting/promotion/views/ManagePromotionView";
import MarketingDashboardView from "../views/system-setting/marketing/views/MarketingDashboardView";

theme.config.initialColorMode = "dark";

export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  // const [sidebarVariant, setSidebarVariant] = useState("opaque");
  const sidebarVariant = "opaque";
  // const [fixed, setFixed] = useState(false);
  const fixed = false;
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(265);
  const [username, setUsername] = useState("MachuUsername");
  // const [isLogin, setIsLogin] = useState(true);

  // ref for main panel div
  const mainPanel = React.createRef();

  const getRoute = () => {
    // const accessToken = localStorage.getItem(APP_TOKEN);
    const profile =
      localStorage.getItem(APP_PROFILE) != null
        ? JSON.parse(localStorage.getItem(APP_PROFILE))
        : null;

    if (profile) {
      callapi("/api/admin/get/" + profile.username, "GET", {})
        .then(() => {
          setUsername(profile.username);
          // setIsLogin(true);
        })
        .catch(() => {
          logout();
        });
    } else {
      return false;
    }

    return true;
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].items);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondaryNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    const roles =
      localStorage.getItem(APP_ROLES) != null
        ? JSON.parse(localStorage.getItem(APP_ROLES))
        : null;
    // console.log(roles);

    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        const found = roles.find((element) => element.menuId == prop.menuId);
        // console.log(found)
        return found ? (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        ) : null;
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };

  const { onOpen } = useDisclosure();
  document.documentElement.dir = "ltr";
  document.documentElement.layout = "admin";

  const routes = useDashRoutes();

  // Chakra Color Mode
  return (
    <ChakraProvider theme={theme} resetCss={false} overflow="scroll">
      <SidebarContext.Provider
        value={{
          sidebarWidth,
          setSidebarWidth,
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar
          routes={routes}
          logoText={"Backoffice Management"}
          username={username}
          profileUrl={avatar8}
          display="none"
          sidebarVariant={sidebarVariant}
          toggleSidebar
          {...rest}
        />
        <MainPanel
          ref={mainPanel}
          w={{
            base: "100%",
            xl: `calc(100% - ${sidebarWidth}px)`,
          }}
        >
          <Portal>
            <AdminNavbar
              onOpen={onOpen}
              logoText={"Backoffice Management"}
              username={username}
              profileUrl={avatar8}
              brandText={getActiveRoute(routes)}
              secondary={getActiveNavbar(routes)}
              fixed={fixed}
              {...rest}
            />
          </Portal>

          {getRoute() ? (
            <PanelContent>
              <PanelContainer>
                <Switch>
                  {getRoutes(routes)}
                  <Route
                    path="/admin/member/:username"
                    component={MemberProfile}
                  />
                  <Route
                    path="/admin/profile"
                    component={(props) => (
                      <AdminProfile {...props} pageName="ข้อมูลส่วนตัว" />
                    )}
                  ></Route>
                  <Route
                    path="/admin/system-setting/promotion/add"
                    component={(props) => (
                      <ManagePromotionView
                        {...props}
                        pageName="เพิ่มโปรโมชั่น"
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/system-setting/promotion/edit/:promoId"
                    component={(props) => (
                      <ManagePromotionView
                        {...props}
                        pageName="แก้ไขโปรโมชั่น"
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/marketing/:id"
                    component={(props) => (
                      <MarketingDashboardView
                        {...props}
                        pageName="ภาพรวมการตลาด"
                      />
                    )}
                  ></Route>
                  <Route
                    path="/admin/system-setting/role/add"
                    component={(props) => (
                      <ManageRoleView {...props} pageName="เพิ่มตำแหน่ง" />
                    )}
                  ></Route>
                  <Route
                    path="/admin/system-setting/role/edit/:roleId"
                    component={(props) => (
                      <ManageRoleView {...props} pageName="แก้ไขตำแหน่ง" />
                    )}
                  ></Route>
                  <Redirect from="/admin" to="/admin/dashboard/default" />
                </Switch>
              </PanelContainer>
            </PanelContent>
          ) : (
            <Redirect to="/auth/login" />
          )}
        </MainPanel>
      </SidebarContext.Provider>
    </ChakraProvider>
  );
}
