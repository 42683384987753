export const createLineChartOptions = ({
  xAxisCategories = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  lineColors = ["rgba(29,215,96,1)", "rgba(223,23,24,1)", "#FF5733"],
  showLegend = false,
} = {}) => {
  return {
    chart: {
      toolbar: {
        show: false,
      },
      type: "line",
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val === 0 ? "" : val;
      },
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: xAxisCategories,
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "12px",
        },
      },
    },
    legend: {
      show: showLegend,
    },
    gradient: false,
    grid: {
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    markers: {
      size: 0,
      colors: lineColors,
      strokeColors: lineColors,
      strokeWidth: 2,
      strokeOpacity: 0.9,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [],
      shape: "circle",
      radius: 2,
      offsetX: 0,
      offsetY: 0,
      showNullDataPoints: true,
    },
    fill: {
      type: "solid",
      colors: ["#fff", "#fff", "#fff"],
      opacity: "0.1",
    },
    colors: lineColors,
  };
};
