import React from "react";
import { useErrorHandler } from "hooks/useErrorHandler";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { validateField } from "../../../../utils/CommonUtils";

import { COLOR_DROPDOWN, ERROR_MESSAGE } from "variables/system";
import ColorPicker from "components/ColorPicker/ColorPicker";
import {
  addMemberTag,
  updateMemberTag,
} from "services/member-management/memberTagService";
import { useSuccessHandler } from "hooks/useSuccessHandler";

const ManageMemberTagModal = ({ isOpen, onClose, tag, onSuccess }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const handleSaveMemberTag = (values, actions) => {
    const response = tag ? updateMemberTag(values) : addMemberTag(values);
    response
      .then((response) => {
        onClose();
        if (response.status === 200) {
          handleSuccess(response.data.message);
          onSuccess();
        }
      })
      .catch((error) => {
        onClose();
        handleError(error, ERROR_MESSAGE);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent p={8}>
        <ModalHeader>
          <Box textAlign="center">
            <Text fontSize="26px">
              {tag ? "แก้ไข Tag สมาชิก" : "เพิ่ม Tag สมาชิก"}
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              id: tag?.id || null,
              color: tag?.color || "rgba(29,215,96,1)",
              name: tag?.name || "",
              description: tag?.description || "",
              lang: tag?.lang || "",
            }}
            onSubmit={(values, actions) => {
              handleSaveMemberTag(values, actions);
            }}
          >
            {(props) => (
              <Form>
                <Field name="name" validate={validateField}>
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={form.errors.name && form.touched.name}
                      isRequired
                    >
                      <FormLabel fontSize="xs" fontWeight="bold">
                        ชื่อ
                      </FormLabel>
                      <Input {...field} placeholder="ชื่อ" fontSize="sm" />
                    </FormControl>
                  )}
                </Field>
                <Field name="description" validate={validateField}>
                  {({ field, form }) => (
                    <FormControl
                      mt={4}
                      isInvalid={
                        form.errors.description && form.touched.description
                      }
                      isRequired
                    >
                      <FormLabel fontSize="xs" fontWeight="bold">
                        รายละเอียด
                      </FormLabel>
                      <Input
                        {...field}
                        placeholder="รายละเอียด"
                        fontSize="sm"
                      />
                    </FormControl>
                  )}
                </Field>
                <FormControl mt={4}>
                  <FormLabel fontSize="xs" fontWeight="bold">
                    สี
                  </FormLabel>
                  <ColorPicker
                    colors={COLOR_DROPDOWN}
                    initialColor={props.initialValues.color}
                    fieldName="color"
                  />
                </FormControl>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    backgroundColor="green.500"
                    color="#000000"
                    w="100%"
                    type="submit"
                    isLoading={props.isSubmitting}
                    disabled={props.isSubmitting}
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManageMemberTagModal;
