import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Grid,
  GridItem,
  Text,
  HStack,
  useBreakpointValue,
  Stack,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";

import { ERROR_MESSAGE } from "variables/system";
import { useErrorHandler } from "hooks/useErrorHandler";
import { validateField } from "utils/CommonUtils";
import {
  addMember,
  fetchBanks,
  updateMember,
} from "services/member-management/memberService";
import { fetchMemberTag } from "services/member-management/memberTagService";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import { fetchMarketingStaff } from "services/system-setting/staffSettingService";

const ManageMemberModal = ({ isOpen, onClose, setSearch, member = null }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const [banks, setBanks] = useState([]);
  const [memberTags, setMemberTags] = useState([]);
  const [marketingStaff, setMarketingStaff] = useState([]);

  const modalSize = useBreakpointValue({ base: "full", md: "2xl" });

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const initialValues = {
    firstName: member?.firstName || "",
    lastName: member?.lastName || "",
    password: member?.password || "",
    accountNumber: member?.accountNumber || "",
    tel: member?.tel || "",
    bankName: member?.bankName || "",
    line: member?.line || "",
    isBonus: member?.isBonus || false,
    blockBonus: member?.blockBonus || false,
    depositAuto: member?.depositAuto || false,
    withdrawAuto: member?.withdrawAuto || false,
    affiliate: member?.affiliate || "",
    nationality: member?.nationality || "",
    username: member?.username || "",
    memberTagsId: member ? member.memberTagsId : "",
    memberLevelName: member ? member.memberLevelName : "",
    marketingId: member ? member.marketingId : "",
  };

  const handleSaveMember = (values, actions) => {
    if (!member) {
      values.username = values.tel;
    }

    const payload = member ? { ...member, ...values } : values;

    const response = member
      ? updateMember(member.id, payload)
      : addMember(payload);

    response
      .then((response) => {
        onClose();
        if (response.status === 200) {
          handleSuccess(response.data.message);
          setSearch();
        }
      })
      .catch((error) => {
        onClose();
        handleError(error, ERROR_MESSAGE);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  useEffect(() => {
    fetchBanks("bankUser")
      .then((response) => {
        setBanks(response.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  }, []);

  useEffect(() => {
    fetchMemberTag()
      .then((response) => {
        setMemberTags(response.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  }, []);

  useEffect(() => {
    fetchMarketingStaff()
      .then((response) => {
        setMarketingStaff(response.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  }, []);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={modalSize}
    >
      <ModalOverlay />
      <ModalContent
        maxH="100vh"
        p={8}
        borderRadius={{ base: "none", md: "md" }}
      >
        <ModalHeader>
          <Box textAlign="center">
            <Text fontSize="26px">
              {member ? `แก้ไขสมาชิก: ${member.username}` : "เพิ่มสมาชิก"}
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              handleSaveMember(values, actions);
            }}
          >
            {(props) => (
              <Form>
                <Grid
                  templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                  gap={4}
                >
                  <GridItem colSpan={{ base: 2, md: 2 }}>
                    <Field name="nationality" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.nationality && form.touched.nationality
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            สัญชาติ
                          </FormLabel>
                          <Select
                            {...field}
                            placeholder="เลือกสัญชาติ"
                            ref={initialRef}
                            fontSize="sm"
                          >
                            <option value="thai">ไทย</option>
                            <option value="foreign">ต่างชาติ</option>
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="firstName" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.firstName && form.touched.firstName
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ชื่อ{" "}
                            <Box
                              as="span"
                              color="green.500"
                              fontWeight="normal"
                            >
                              (ตามสมุดบัญชี)
                            </Box>
                          </FormLabel>
                          <Input {...field} placeholder="ชื่อ" fontSize="sm" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="lastName" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.lastName && form.touched.lastName
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            นามสกุล{" "}
                            <Box
                              as="span"
                              color="green.500"
                              fontWeight="normal"
                            >
                              (ตามสมุดบัญชี)
                            </Box>
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="นามสกุล"
                            fontSize="sm"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="accountNumber" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.accountNumber &&
                            form.touched.accountNumber
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            หมายเลขบัญชีธนาคาร
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="หมายเลขบัญชี"
                            fontSize="sm"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="bankName" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.bankName && form.touched.bankName
                          }
                          isRequired
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ธนาคาร
                          </FormLabel>
                          <Select
                            {...field}
                            placeholder="เลือกธนาคาร"
                            fontSize="sm"
                          >
                            {banks.map((bank) => (
                              <option key={bank.id} value={bank.bankCode}>
                                {bank.bankName}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="password" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.password && form.touched.password
                          }
                          isRequired={!member}
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            พาสเวิร์ด
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="พาสเวิร์ด"
                            type="password"
                            isReadOnly={!!member}
                            fontSize="sm"
                            backgroundColor={member ? "gray.800" : ""}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="tel" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.tel && form.touched.tel}
                          isRequired={!member}
                        >
                          <FormLabel fontSize="xs" fontWeight="bold">
                            เบอร์โทรศัพท์
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="เบอร์โทรศัพท์"
                            isReadOnly={!!member}
                            fontSize="sm"
                            backgroundColor={member ? "gray.800" : ""}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="affiliate">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            บุคคลแนะนำ
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="บุคคลแนะนำ"
                            fontSize="sm"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="marketingId">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ตัวแทนการตลาด
                          </FormLabel>
                          <Select
                            {...field}
                            placeholder="เลือกตัวแทนการตลาด"
                            fontSize="sm"
                          >
                            {marketingStaff.map((staff) => (
                              <option key={staff.id} value={staff.id}>
                                {staff.username}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="line">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel fontSize="xs" fontWeight="bold">
                            ไลน์ไอดี
                          </FormLabel>
                          <Input
                            {...field}
                            placeholder="ไลน์ไอดี"
                            fontSize="sm"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  {member && (
                    <>
                      <GridItem colSpan={{ base: 2, md: 1 }}>
                        <Field name="memberTagsId">
                          {({ field }) => (
                            <FormControl>
                              <FormLabel fontSize="xs" fontWeight="bold">
                                แท็ค
                              </FormLabel>
                              <Select
                                {...field}
                                placeholder="เลือก Tag"
                                fontSize="sm"
                              >
                                {memberTags.map((memberTag) => (
                                  <option
                                    key={memberTag.id}
                                    value={memberTag.id}
                                  >
                                    {memberTag.name}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                      <GridItem colSpan={{ base: 2, md: 1 }}>
                        <Field name="memberLevelName">
                          {({ field }) => (
                            <FormControl>
                              <FormLabel fontSize="xs" fontWeight="bold">
                                ระดับ
                              </FormLabel>
                              <Input
                                {...field}
                                isReadOnly={!!member}
                                fontSize="sm"
                                backgroundColor={member ? "gray.800" : ""}
                              />
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                    </>
                  )}
                  <GridItem colSpan={2}>
                    <Box mt={4}>
                      <HStack spacing={4}>
                        <Field name="isBonus">
                          {({ field }) => (
                            <FormControl display="flex" alignItems="center">
                              <Stack>
                                <FormLabel
                                  htmlFor="is-bonus-enabled"
                                  mb="0"
                                  fontSize="xs"
                                  fontWeight="bold"
                                >
                                  โบนัส
                                </FormLabel>
                                <Switch
                                  id="is-bonus-enabled"
                                  {...field}
                                  isChecked={field.value}
                                />
                              </Stack>
                            </FormControl>
                          )}
                        </Field>
                        <Field name="blockBonus">
                          {({ field }) => (
                            <FormControl display="flex" alignItems="center">
                              <Stack>
                                <FormLabel
                                  htmlFor="is-no-bonus"
                                  mb="0"
                                  fontSize="xs"
                                  fontWeight="bold"
                                >
                                  ไม่ให้โบนัส
                                </FormLabel>
                                <Switch
                                  id="is-no-bonus"
                                  {...field}
                                  isChecked={field.value}
                                />
                              </Stack>
                            </FormControl>
                          )}
                        </Field>
                        <Field name="depositAuto">
                          {({ field }) => (
                            <FormControl display="flex" alignItems="center">
                              <Stack>
                                <FormLabel
                                  htmlFor="is-auto-deposit"
                                  mb="0"
                                  fontSize="xs"
                                  fontWeight="bold"
                                >
                                  ฝากออโต้
                                </FormLabel>
                                <Switch
                                  id="is-auto-deposit"
                                  {...field}
                                  isChecked={field.value}
                                />
                              </Stack>
                            </FormControl>
                          )}
                        </Field>
                        <Field name="withdrawAuto">
                          {({ field }) => (
                            <FormControl display="flex" alignItems="center">
                              <Stack>
                                <FormLabel
                                  htmlFor="is-auto-withdraw"
                                  mb="0"
                                  fontSize="xs"
                                  fontWeight="bold"
                                >
                                  ถอนออโต้
                                </FormLabel>
                                <Switch
                                  id="is-auto-withdraw"
                                  {...field}
                                  isChecked={field.value}
                                />
                              </Stack>
                            </FormControl>
                          )}
                        </Field>
                      </HStack>
                    </Box>
                  </GridItem>
                </Grid>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    backgroundColor="green.500"
                    color={"#000000"}
                    w="100%"
                    type="submit"
                    isLoading={props.isSubmitting}
                    disabled={props.isSubmitting}
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManageMemberModal;
