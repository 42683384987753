import { Badge, Flex } from "@chakra-ui/react";
import { formatDate } from "../../../utils/DateformatUtils";

export const spinnerReportColumnsData = [
  {
    Header: "ชื่อรางวัล",
    accessor: "name",
    Cell: ({ cell }) => (
      <Flex align="center" justify="center">
        <Badge
          colorScheme={"green"}
          h="28px"
          borderRadius="15px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {cell.value}
        </Badge>
      </Flex>
    ),
  },
  {
    Header: "ยูสเซอร์เนม",
    accessor: "username",
    disableSortBy: true,
  },
  {
    Header: "มูลค่า",
    accessor: "amount",
    disableSortBy: true,
  },
  {
    Header: "เครดิตก่อนได้รับ",
    accessor: "beforeAmount",
  },
  {
    Header: "เครดิตหลังได้รับ",
    accessor: "afterAmount",
  },
  {
    Header: "ยอดเทินโอเวอร์",
    accessor: "turnOver",
  },
  {
    Header: "ประเภท",
    accessor: "type",
  },
  {
    Header: "วันที่ทำรายการ",
    accessor: "createdDate",
    Cell: ({ value }) => formatDate(value),
  },
];
