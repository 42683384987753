export const switchStyles = {
  components: {
    Switch: {
      variants: {
        "custom-track-color": {
          track: {
            bg: "gray.300",
            _checked: {
              bg: "green.500",
            },
          },
          thumb: {
            bg: "blue.500",
            _checked: {
              bg: "yellow.500",
            },
          },
        },
        "no-hover": {
          track: {
            _hover: {
              bg: "gray.300",
            },
          },
          thumb: {
            _hover: {
              bg: "blue.500",
            },
          },
        },
      },
      // Base styles
      baseStyle: {
        track: {
          bg: "gray.200",
          _checked: {
            bg: "green.500",
          },
        },
        thumb: {
          bg: "white",
          _checked: {
            bg: "white",
          },
        },
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
};
