import { callapi, callListApi } from "../../utils/APIUtils";

export const fetchPaymentGatewaySetting = async () => {
  const response = await callListApi("/api/admin/paymentgateway", "GET", {})
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error(`API error: ${res.statusText}`);
      }
    })
    .catch((e) => {
      throw new Error(`API error: ${e.message}`);
    });

  const data2 = await response;

  const data = {
    count: data2.data.length,
    results: data2.data,
  };
  return data;
};

export const updatePaymentGatewaySetting = (payload) => {
  return callapi("/api/admin/paymentgateway", "POST", payload);
};
