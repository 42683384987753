import { Flex, Grid, Stack, Text } from "@chakra-ui/react";
import ActivityImageCard from "./components/ActivityImageCard";
import { useEffect, useState } from "react";
import { useErrorHandler } from "hooks/useErrorHandler";
import { ERROR_MESSAGE } from "variables/system";
import {
  fetchActivityImageSetting,
  updateActivityImageSetting,
} from "services/website-setting/activityImageSettingService";
import { useSuccessHandler } from "hooks/useSuccessHandler";

const ActivityImageSetting = ({ pageName }) => {
  const [activityImageData, setActivityImageData] = useState([]);

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const fetchActivityImageData = () => {
    fetchActivityImageSetting()
      .then((response) => {
        setActivityImageData(response.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const handleToggle = (id) => {
    const updatedData = activityImageData.map((activity) => {
      if (activity.id === id) {
        return { ...activity, active: !activity.active };
      }
      return activity;
    });
    setActivityImageData(updatedData);
    handleUpdateImageData(updatedData);
  };

  const handleImageUpload = (id, newImageUrl) => {
    const updatedData = activityImageData.map((activity) => {
      if (activity.id === id) {
        return { ...activity, backgroundImage: newImageUrl };
      }
      return activity;
    });
    setActivityImageData(updatedData);
    handleUpdateImageData(updatedData);
  };

  const handleUpdateImageData = (data) => {
    updateActivityImageSetting({ data: data })
      .then((response) => {
        handleSuccess(response.data.message);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    fetchActivityImageData();
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }} overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <Text fontWeight="bold" fontSize="lg">
          {pageName}
        </Text>
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(3, 1fr)" }}
          gap={6}
        >
          {activityImageData.map((activity) => (
            <ActivityImageCard
              key={activity.id}
              title={activity.title}
              imageSrc={activity.backgroundImage}
              isActive={activity.active}
              onDelete={() => handleImageUpload(activity.id, "")}
              onToggle={() => handleToggle(activity.id)}
              onImageUpload={(newImageUrl) =>
                handleImageUpload(activity.id, newImageUrl)
              }
            />
          ))}
        </Grid>
      </Stack>
    </Flex>
  );
};

export default ActivityImageSetting;
