import React from "react";
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { Flex, InputGroup, Icon } from "@chakra-ui/react";
import { FiCalendar } from "react-icons/fi";

const CustomRangePicker = ({ selectedDates, onDateChange }) => {
  return (
    <Flex align="center">
      <InputGroup>
        <RangeDatepicker
          triggerVariant="input"
          triggerIcon={<Icon as={FiCalendar} color="white" />}
          selectedDates={selectedDates}
          onDateChange={onDateChange}
          propsConfigs={{
            dateNavBtnProps: {
              variant: "outline",
              border: "none",
              borderRadius: "md",
            },
            dayOfMonthBtnProps: {
              defaultBtnProps: {
                _hover: {
                  color: "black",
                  background: "green.500",
                },
                borderRadius: "md",
                fontWeight: "normal",
              },
              isInRangeBtnProps: {
                color: "black",
                background: "green.500",
                fontWeight: "bold",
              },
              selectedBtnProps: {
                background: "green.500",
                color: "black",
                fontWeight: "bold",
              },
              todayBtnProps: {
                background: "transparent",
                color: "green.500",
              },
            },
            inputProps: {
              fontSize: "sm",
              pl: "2rem",
            },
            popoverCompProps: {
              popoverContentProps: {
                background: "gray.700",
                color: "white",
              },
            },
            calendarPanelProps: {
              wrapperProps: {
                borderColor: "green",
              },
              contentProps: {
                borderWidth: 0,
              },
              headerProps: {
                padding: "5px",
              },
              dividerProps: {
                display: "solid",
              },
            },
            weekdayLabelProps: {
              fontWeight: "normal",
            },
            dateHeadingProps: {
              fontWeight: "normal",
            },
          }}
        />
      </InputGroup>
    </Flex>
  );
};

export default CustomRangePicker;
