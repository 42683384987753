import { Box, Flex, Grid, Icon, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { getReferenceDates } from "utils/DateformatUtils";
import CustomRangePicker from "../../../../components/CustomRangePicker/CustomRangePicker";
import ReportsCard from "../../../dashboard/components/ReportsCard";
import {
  HiOutlineCurrencyDollar,
  HiOutlineDocumentAdd,
  HiOutlinePresentationChartLine,
  HiOutlineUserAdd,
  HiOutlineUserGroup,
  HiOutlineUserRemove,
  HiOutlineXCircle,
} from "react-icons/hi";
import { HiOutlineDocumentMinus } from "react-icons/hi2";
import moment from "moment/moment";
import { fetchDashboardMarketingSummary } from "services/dashboard/dashboardService";
import { ERROR_MESSAGE } from "variables/system";
import { useParams } from "react-router-dom";
import { useErrorHandler } from "hooks/useErrorHandler";
import useDateRange from "../../../../hooks/useDateRange";

const ManagePromotionView = ({ pageName }) => {
  const { id } = useParams();
  const handleError = useErrorHandler();
  const { dateNow, dateDayCheck } = getReferenceDates();
  const dateSearchFormat = "YYYY-MM-DD HH:mm:ss";
  const dateFieldsNames = { from: "startDate", to: "endDate" };

  const [search, setSearch] = useState({
    startDate: moment(dateDayCheck).format(dateSearchFormat),
    endDate: moment(dateNow).format(dateSearchFormat),
    mid: id,
  });

  const { selectedDates, handleDateChange } = useDateRange(
    [dateDayCheck, dateNow],
    search,
    setSearch,
    dateFieldsNames,
    dateSearchFormat,
  );

  const [summary, setSummary] = useState({
    deposit: {
      amount: "0.00",
      count: "0",
    },
    depositMistake: {
      amount: "0.00",
      count: "0",
    },
    depositWaitToApprove: {
      amount: "0.00",
      count: "0",
    },
    withdraw: {
      amount: "0.00",
      count: "0",
    },
    withdrawMistake: {
      amount: "0.00",
      count: "0",
    },
    withdrawWaitToApprove: {
      amount: "0.00",
      count: "0",
    },
    depositBonus: {
      amount: "0.00",
      count: "0",
    },
    profit: {
      amount: "0.00",
      count: "0",
    },
    newRegister: {
      amount: "0.00",
      count: "0",
    },
    newRegisterNoDeposit: {
      amount: "0.00",
      count: "0",
    },
    countDeposit: {
      amount: "0.00",
      count: "0",
    },
    countWithdraw: {
      amount: "0.00",
      count: "0",
    },
    remainBalance: {
      amount: "0.00",
      count: "0",
    },
  });

  const searchDashboard = () => {
    const req = {
      startDate: search.startDate,
      endDate: search.endDate,
      mid: id,
    };

    const response = fetchDashboardMarketingSummary(req);

    response
      .then((res) => {
        if (res.status === 200) {
          setSummary(res.data.data);
        }
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    searchDashboard();
  }, [selectedDates]);

  return (
    <Flex flexDirection="column" pt={{ base: "65px", md: "75px" }}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="lg" fontWeight="bold">
          {pageName}
        </Text>
        <Box>
          <CustomRangePicker
            selectedDates={selectedDates}
            onDateChange={handleDateChange}
          />
        </Box>
      </Flex>
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(5, 1fr)" }}
        gap="24px"
        mb="24px"
        pt={{ base: "20px", md: "25px" }}
      >
        <ReportsCard
          title={"ยอดฝาก"}
          number={summary.deposit.amount}
          icon={
            <Icon
              as={HiOutlineDocumentAdd}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="black"
            />
          }
          description={summary.deposit.count + " รายการ"}
          isInteger={false}
        />
        <ReportsCard
          title={"ยอดถอน"}
          icon={
            <Icon
              as={HiOutlineDocumentMinus}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="black"
            />
          }
          number={summary.withdraw?.amount}
          description={summary.withdraw?.count + " รายการ"}
          isInteger={false}
        />
        <ReportsCard
          title={"ยอดฝากโบนัส"}
          icon={
            <Icon
              as={HiOutlineCurrencyDollar}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="black"
            />
          }
          number={summary.depositBonus?.amount}
          description={summary.depositBonus?.count + " รายการ"}
          isInteger={false}
        />
        <ReportsCard
          title={"กำไร"}
          icon={
            <Icon
              as={HiOutlinePresentationChartLine}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="black"
            />
          }
          number={summary.profit?.amount}
          description={summary.profit?.count + " รายการ"}
          isInteger={false}
        />
        <ReportsCard
          title={"สมัครใหม่"}
          number={summary.newRegister.count}
          icon={
            <Icon
              as={HiOutlineUserGroup}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="black"
            />
          }
          description={"สมาชิกสมัครใหม่"}
          isInteger={true}
        />
        <ReportsCard
          title={"สมัครไม่ฝาก"}
          icon={
            <Icon
              as={HiOutlineXCircle}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="black"
            />
          }
          number={summary.newRegisterNoDeposit?.count}
          description={"สมาชิกที่สมัครใหม่แต่ไม่ฝาก"}
          isInteger={true}
        />
        <ReportsCard
          title={"จำนวนคนฝาก"}
          number={summary.countDeposit?.count}
          icon={
            <Icon
              as={HiOutlineUserAdd}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="black"
            />
          }
          description={"ไม่นับกรณีคนฝากรอบสอง"}
          isInteger={true}
        />
        <ReportsCard
          title={"จำนวนคนถอน"}
          icon={
            <Icon
              as={HiOutlineUserRemove}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="black"
            />
          }
          number={summary.countWithdraw?.count}
          description={"ไม่นับกรณีคนถอนรอบสอง"}
          isInteger={true}
        />
      </Grid>
    </Flex>
  );
};

export default ManagePromotionView;
