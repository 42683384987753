export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const PREFIX = "PREFIX";
export const APP_TOKEN = "APP_TOKEN";
export const APP_PROFILE = "APP_PROFILE";
export const APP_ROLES = "APP_ROLES";

export const USER_SEARCH = "USER_SEARCH";

export const ACCESS_TOKEN = "Bearer ";

export const AUTH_URL = "/api/admin/auth";
export const URL_UPLOAD = "/api/file/uploadFile";
export const URL_DOWNLOAD = "/api/file/downloadFile/";
export const URL_WEBSOCKET = "/noti-websocket";

export const FILTER_DELAY = 1500;
// Role
export const EXCLUSIVE_ROLE = "XSUPERADMIN";
export const SUPER_ROLE = "SUPERADMIN";
export const ADMIN_ROLE = "ADMIN";
export const STAFF_ROLE = "STAFF";
export const MARKETING_ROLE = "MARKETING";

export const ROLE_LIST = [SUPER_ROLE, ADMIN_ROLE, STAFF_ROLE];

// Action
export const VIEW = "VIEW";
export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";

// Message
export const SUCCESS_MESSAGE = "ทำรายการสำเร็จ";
export const ERROR_MESSAGE = "ระบบไม่สามารถดำเนินการได้";
// bankNationality { nationality: "laos", type: "ลาว" }, { nationality: "thai", type: "ไทย" }
export const BANK_NATIONALITY = [{ nationality: "indo", type: "Indonesia" }];
// bankType
export const BANK_TYPE = [
  { bankType: "DEPOSIT", type: "ธนาคารฝาก", label: "bank.type.deposit" },
  {
    bankType: "WITHDRAW",
    type: "ธนาคารถอน",
    label: "bank.type.withdraw",
  },
];

// Bank
export const BANK = "เลือก";
export const SCB = "ไทยพาณิชย์";
export const KBANK = "กสิกร";

export const BANK_NAME = [BANK, SCB, KBANK];

export const DEFAULT = "DEFAULT";
export const TSCB = "SCB";
export const TKBANK = "KBANK";

export const BANK_CODE = [DEFAULT, TSCB, TKBANK];

// export const MONTH = ["มกราคม", "กุมภาพันธ์", "มีนาคม","เมษายน",
// "พฤษภาคม","มิถุนายน","กรกฎาคม","สิงหาคม","กันยายน","ตุลาคม","พฤศจิกายน","ธันวาคม"]

export const MONTH = [
  { montName: "มกราคม(Jan)", monthNumber: "01" },
  { montName: "กุมภาพันธ์(Feb)", monthNumber: "02" },
  { montName: "มีนาคม(Mar)", monthNumber: "03" },
  { montName: "เมษายน(Apr)", monthNumber: "04" },
  { montName: "พฤษภาคม(May)", monthNumber: "05" },
  { montName: "มิถุนายน(Jun)", monthNumber: "06" },
  { montName: "กรกฎาคม(Jul)", monthNumber: "07" },
  { montName: "สิงหาคม(Aug)", monthNumber: "08" },
  { montName: "กันยายน(Sep)", monthNumber: "09" },
  { montName: "ตุลาคม(Oct)", monthNumber: "10" },
  { montName: "พฤศจิกายน(Nov)", monthNumber: "11" },
  { montName: "ธันวาคม(Dec)", monthNumber: "12" },
];

export const YEARS = [
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
  "2031",
  "2032",
  "2033",
  "2034",
  "2035",
  "2036",
  "2037",
  "2038",
  "2039",
  "2040",
];

export const BANKNAME = [
  "ธนาคารไืทยพาณิชย์",
  "ธนาคารกสิกรไทย",
  "ธนาคารกรุงเทพ",
  "ธนาคารกรุงศรีอยุธยา",
  "ธนาคารกรุงไทย",
  "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
  "ธนาคารทหารไทย",
  "ธนาคารไอซีบีซี (ไทย)",
  "ธนาคารไทยเครดิตเพื่อรายย่อย",
  "ธนาคารซิตี้แบงก์",
  "ธนาคารยูโอบี",
  "ธนาคารออมสิน",
  "ธนาคารเอชเอสบีซี ประเทศไทย",
  "ธนาคารมิซูโฮ คอร์ปอเรต",
  "ธนาคารอาคารสงเคราะห์",
  "ธนาคารแลนด์ แอนด์ เฮ้าส์",
  "ธนาคารทิสโก้",
  "ธนาคารเกียรตินาคิน",
  "ธนาคารอิสลามแห่งประเทศไทย",
];

export const BANKNAME1 = [
  {
    bankName: "ธนาคารไทยพาณิชย์",
    bankCode: "scb",
    code: "014",
  },
  {
    bankName: "ธนาคารกสิกรไทย",
    bankCode: "kbank",
    code: "004",
  },
  {
    bankName: "ธนาคารกรุงเทพ",
    bankCode: "bbl",
    code: "002",
  },
  {
    bankName: "ธนาคารกรุงศรีอยุธยา",
    bankCode: "bay",
    code: "025",
  },
  {
    bankName: "ธนาคารกรุงไทย",
    bankCode: "ktb",
    code: "006",
  },
  {
    bankName: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
    bankCode: "baac",
    code: "034",
  },
  {
    bankName: "ธนาคารทหารไทย",
    bankCode: "tmb",
    code: "011",
  },
  {
    bankName: "ธนาคารไอซีบีซี (ไทย)",
    bankCode: "icbc",
    code: "070",
  },
  {
    bankName: "ธนาคารไทยเครดิตเพื่อรายย่อย",
    bankCode: "tcrb",
    code: "071",
  },
  {
    bankName: "ธนาคารซิตี้แบงก์",
    bankCode: "citi",
    code: "017",
  },
  {
    bankName: "ธนาคารยูโอบี",
    bankCode: "uob",
    code: "024",
  },
  {
    bankName: "ธนาคารออมสิน",
    bankCode: "gsb",
    code: "030",
  },
  {
    bankName: "ธนาคารเอชเอสบีซี ประเทศไทย",
    bankCode: "hsbc",
    code: "031",
  },
  {
    bankName: "ธนาคารมิซูโฮ คอร์ปอเรต",
    bankCode: "mizuho",
    code: "039",
  },
  {
    bankName: "ธนาคารอาคารสงเคราะห์",
    bankCode: "ghb",
    code: "033",
  },
  {
    bankName: "ธนาคารทิสโก้",
    bankCode: "tisco",
    code: "067",
  },
  {
    bankName: "ธนาคารเกียรตินาคิน",
    bankCode: "kk",
    code: "069",
  },
  {
    bankName: "ธนาคารอิสลามแห่งประเทศไทย",
    bankCode: "ibank",
    code: "066",
  },
];

export const USERNAME = "username";
export const TEL = "mobileNo";
export const BANKCODE = "bank";
export const NAME = "firstName";
export const LNAME = "lastName";

export const SEARCHBY = [USERNAME, TEL, BANKCODE, NAME, LNAME];

export const TYPEUSER = [
  { typeUser: "All", typeUserValue: "0" },
  {
    typeUser: "deposit",
    typeUserValue: "1",
  },
  { typeUser: "nodeposit", typeUserValue: "2" },
  { typeUser: "1stdeposit", typeUserValue: "3" },
];

export const FIRSTNAME = "firstName";

export const LASTNAME = "lastName";

export const SEARCHBY1 = [USERNAME, TEL, BANKCODE, FIRSTNAME, LASTNAME];
export const TYPEPROMOTION = [
  { typeCode: "ALLDAY", typeName: "ฝากทั้งวัน" },
  { typeCode: "NEWUSER", typeName: "สมาชิกใหม่" },
  { typeCode: "FIRSTTIME", typeName: "ฝากแรกของวัน" },
  { typeCode: "GOLDTIME", typeName: "นาทีทอง" },
  { typeCode: "7DAYINROW", typeName: "ฝากติดต่อกัน 7 วัน" },
];
export const TYPEREFUND = [
  { typeCode: "WEEKLY", typeName: "รายอาทิตย์" },
  { typeCode: "MONTHLY", typeName: "รายเดือน" },
];

export const TYPEGAME = [
  { typeCode: "ALL", typeName: "ทั้งหมด" },
  { typeCode: "SPORT", typeName: "กีฬา" },
  { typeCode: "GAME", typeName: "สล๊อต" },
  { typeCode: "CASINO", typeName: "คาสิโน" },
  { typeCode: "TABLE_GAME", typeName: "สกิลเกมส์" },
  { typeCode: "FISHING", typeName: "ยิงปลา" },
  // {typeCode: "LOTTO", typeName: "LOTTO"},
  // {typeCode: "MULTI_PLAYER", typeName: "MULTI_PLAYER"},
  // {typeCode: "POKER", typeName: "POKER"},
  // {typeCode: "KENO", typeName: "KENO"},
  // {typeCode: "TRADING", typeName: "TRADING"}
];
export const TYPEBONUS = [
  { typeCode: "FIX", typeName: "ค่าคงที่" },
  { typeCode: "PERCENT", typeName: "%" },
];
export const TYPETURN = [
  { typeCode: "TURNOVER", typeName: "เทิร์นโอเวอร์" },
  { typeCode: "MINCREDIT", typeName: "ถอนขั้นต่ำ" },
];
export const NATIONALITY = [{ nationality: "thai" }, { nationality: "laos" }];
export const TYPEAFFILIATE = [
  { typeCode: "DEPOSIT", typeName: "จากยอดฝาก" },
  {
    typeCode: "WINLOSE",
    typeName: "จากยอด แพ้/ชนะ",
  },
];

export const ID = "id";

export const SEARCHTHIEVES = [ID, NAME, BANKCODE, BANKNAME];

// export const SEARCHBUTTON = [MONEY, TRUEWALLET, SEVEN, ERROR];
export const MONEY = "MONEY";
export const BONUS = "BONUS";
export const TRUEWALLET = "TRUEWALLET";
export const SEVEN = "SEVEN";
export const ERROR = "ERROR";
export const REFUNDWINLOSE = "REFUNDWINLOSE";

// Mistakes
export const NO_SLIP = "NO_SLIP";
export const CUT_CREDIT = "CUT_CREDIT";
export const ADD_CREDIT = "ADD_CREDIT";

// Game Types
export const ALL = "ALL";
export const SPORT = "SPORT";
export const GAME = "GAME";
export const TABLE_GAME = "TABLE_GAME";
export const FISHING = "FISHING";

export const TTYPE = [
  { name: "auto", value: "1" },
  { name: "manual", value: "0" },
];

export const STATUS_W = [
  { value: "SUCCESS", label: "success" },
  { value: "PENDING", label: "pending" },
  { value: "ERROR", label: "error" },
  { value: "REJECT", label: "reject" },
  { value: "REJECT_N_REFUND", label: "rejectAndRefund" },
];

export const getWithdrawSName = (status) => {
  switch (status) {
    case "SUCCESS":
      return "success";
    case "PENDING":
      return "inprogress";
    case "ERROR":
      return "error";
    case "REJECT":
      return "reject";
    case "REJECT_N_REFUND":
      return "rejectAndRefund";
    case "BLOCK_AUTO":
      return "blockAuto";
    default:
      return "";
  }
};

export const getDepositStatus = (status) => {
  switch (status) {
    case "SUCCESS":
      return "success";
    case "PENDING":
      return "inprogress";
    case "ERROR":
      return "error";
    case "REJECT":
      return "reject";
    case "REJECT_N_REFUND":
      return "rejectAndRefund";
    case "BLOCK_AUTO":
      return "blockAuto";
    case "NOT_SURE":
      return "notSure";
    default:
      return "";
  }
};

export const COLOR_DROPDOWN = [
  "#718096",
  "#E53E3E",
  "#DD6B20",
  "#D69E2E",
  "#53b199",
  "#319795",
  "#3182CE",
  "#00B5D8",
  "#805AD5",
  "#D53F8C",
];

export const LANG_DROPDOWN = [
  { val: "TH", text: "TH" },
  { val: "EN", text: "EN" },
  { val: "ID", text: "Indonesia" },
];
export const DEVICE_DROPDOWN = [
  { val: "1", text: "Mobile" },
  { val: "2", text: "Desktop" },
];

export const FOOTER_ICON_TYPE = [
  {
    val: "1",
    text: "Contact US",
  },
  {
    val: "2",
    text: "PAYMENT METHOD",
  },
  {
    val: "4",
    text: "DEPOSIT METHOD",
  },
  {
    val: "3",
    text: "PROVIDER",
  },
];

export const LEVEL_VALIDITY = [
  { val: "", text: "-" },
  { val: 1, text: "1 Month" },
  { val: 3, text: "3 Month" },
  {
    val: 6,
    text: "6 Month",
  },
  { val: 12, text: "1 Year" },
  { val: 24, text: "2 Year" },
];

export const GRAND_JACKPOT = "Grand";
export const MAJOR_JACKPOT = "Major";
export const MINOR_JACKPOT = "Minor";
export const MINI_JACKPOT = "Mini";

export const BANK_MAP = {
  "004": "KBANK",
  "014": "SCB",
  "002": "BBL",
  "025": "BAY",
  "006": "KTB",
  "011": "TTB",
  "030": "GSB",
  "034": "BAAC",
  "070": "CIMB",
  "024": "UOBT",
  "069": "KKP",
  "067": "TISCO",
  "017": "CITI",
  "033": "GHB",
  "066": "IBANK",

  1001: "BCEL",
  1002: "LDB",
  1003: "STB",
  1004: "BFL",
  1005: "LVB",
  1006: "JDB",
  1007: "KBANK",
  1008: "LDB2",
  1009: "MJBL",
  1010: "APB",
  1011: "PSVB",
  1012: "BIC",
};
