import { Flex, Badge, Select, Input } from "@chakra-ui/react";
import { LEVEL_VALIDITY } from "../../system";
import React, { useState } from "react";

export const memberLevelColumnsData = (handleValueChange) => [
  {
    Header: "ระดับ",
    accessor: "levelName",
    Cell: ({ cell }) => (
      <Flex align="center" justify="center">
        <Badge
          colorScheme={
            cell.value === "Platinum"
              ? "blue"
              : cell.value === "Silver"
              ? "gray"
              : cell.value === "Gold"
              ? "yellow"
              : "default"
          }
          w="65px"
          h="28px"
          borderRadius="15px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {cell.value}
        </Badge>
      </Flex>
    ),
    disableSortBy: true,
  },
  {
    Header: "จำนวนการฝาก",
    accessor: "depositCount",
    Cell: ({ row }) => {
      const [localValue, setLocalValue] = useState(row.original.depositCount);
      const handleChange = (e) => {
        setLocalValue(e.target.value);
      };

      const handleBlur = () => {
        let val = localValue;
        if (val < 0) val = 0;
        setLocalValue(val);
        handleValueChange(row.original.id, "depositCount", val);
      };

      return (
        <Input
          value={localValue}
          onChange={handleChange}
          onBlur={handleBlur}
          fontSize="sm"
        />
      );
    },
    disableSortBy: true,
  },
  {
    Header: "ยอดฝากขั้นต่ำ",
    accessor: "minTotalDeposit",
    Cell: ({ row }) => {
      const [localValue, setLocalValue] = useState(
        row.original.minTotalDeposit.toFixed(2),
      );
      const handleChange = (e) => {
        setLocalValue(e.target.value);
      };

      const handleBlur = () => {
        let val = parseFloat(localValue);
        if (isNaN(val) || val < 0) {
          val = 0;
        }
        setLocalValue(val.toFixed(2));
        handleValueChange(row.original.id, "minTotalDeposit", val);
      };

      return (
        <Input
          value={localValue}
          onChange={handleChange}
          onBlur={handleBlur}
          fontSize="sm"
        />
      );
    },
    disableSortBy: true,
  },
  {
    Header: "เทิรน์โอเวอร์ขั้นต่ำ",
    accessor: "minTotalTurnover",
    Cell: ({ row }) => {
      const [localValue, setLocalValue] = useState(
        row.original.minTotalTurnover.toFixed(2),
      );
      const handleChange = (e) => {
        setLocalValue(e.target.value);
      };

      const handleBlur = () => {
        let val = parseFloat(localValue);
        if (isNaN(val) || val < 0) {
          val = 0;
        }
        setLocalValue(val.toFixed(2));
        handleValueChange(row.original.id, "minTotalTurnover", val);
      };

      return (
        <Input
          value={localValue}
          onChange={handleChange}
          onBlur={handleBlur}
          fontSize="sm"
        />
      );
    },
    disableSortBy: true,
  },
  {
    Header: "ระยะเวลา",
    accessor: "validity",
    Cell: ({ row, cell: { value } }) => {
      const [localValue, setLocalValue] = useState(value);

      const handleValidityChange = (e) => {
        const newValue = e.target.value;
        setLocalValue(newValue);
        handleValueChange(row.original.id, "validity", newValue);
      };

      return (
        <Select
          value={localValue}
          onChange={handleValidityChange}
          fontSize="sm"
        >
          {LEVEL_VALIDITY.map((item, index) => (
            <option key={index} value={item.val}>
              {item.text}
            </option>
          ))}
        </Select>
      );
    },
    disableSortBy: true,
  },
];
