import React, { useEffect, useState } from "react";
import { Flex, Grid, GridItem, Stack, Text } from "@chakra-ui/react";

import { useErrorHandler } from "hooks/useErrorHandler";
import { ERROR_MESSAGE } from "variables/system";
import {
  fetchGameSetting,
  updateGameSetting,
} from "services/system-setting/gameSettingService";
import { useSuccessHandler } from "hooks/useSuccessHandler";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import ProviderCard from "./components/ProviderCard";

const GameSetting = ({ pageName }) => {
  const [slotProviderData, setSlotProviderData] = useState(null);
  const [casinoProviderData, setCasinoProviderData] = useState(null);
  const [sportProviderData, setSportProviderData] = useState(null);
  const [cardProviderData, setCardProviderData] = useState(null);

  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const [isLoading, setIsLoading] = useState(true);

  const fetchGameSettingData = () => {
    fetchGameSetting()
      .then((response) => {
        setIsLoading(true);
        const sortData = (data) => {
          return data.sort((a, b) => a.provider.localeCompare(b.provider));
        };
        const { gameSettingList } = response.data.data;

        setSlotProviderData(
          sortData(
            gameSettingList.filter((setting) => setting.type === "SLOT"),
          ),
        );
        setCasinoProviderData(
          sortData(
            gameSettingList.filter((setting) => setting.type === "LIVE"),
          ),
        );
        setSportProviderData(
          sortData(
            gameSettingList.filter((setting) => setting.type === "SPORT"),
          ),
        );
        setCardProviderData(
          sortData(
            gameSettingList.filter((setting) => setting.type === "CARD"),
          ),
        );

        setIsLoading(false);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const handleToggle = (provider, type, isEnable) => {
    const req = { isEnable, provider, type };

    updateGameSetting(req)
      .then((response) => {
        handleSuccess(response.data.message);
        fetchGameSettingData();
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
        fetchGameSettingData();
      });
  };

  useEffect(() => {
    fetchGameSettingData();
  }, []);

  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "75px" }} overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="lg">
            {pageName}
          </Text>
        </AnimatedFlex>
        <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
          <GridItem>
            <AnimatedFlex justify="space-between" duration={0.4} delay={0.6}>
              <ProviderCard
                title="Slot"
                providerData={slotProviderData}
                isLoading={isLoading}
                handleToggle={handleToggle}
              />
            </AnimatedFlex>
          </GridItem>
          <GridItem>
            <Stack direction="column" spacing="24px" w="100%">
              <AnimatedFlex justify="space-between" duration={0.4} delay={0.8}>
                <ProviderCard
                  title="Casino"
                  providerData={casinoProviderData}
                  isLoading={isLoading}
                  handleToggle={handleToggle}
                />
              </AnimatedFlex>
              <AnimatedFlex justify="space-between" duration={0.4} delay={1.0}>
                <ProviderCard
                  title="Card"
                  providerData={cardProviderData}
                  isLoading={isLoading}
                  handleToggle={handleToggle}
                  pt={6}
                />
              </AnimatedFlex>
              <AnimatedFlex justify="space-between" duration={0.4} delay={1.2}>
                <ProviderCard
                  title="Sport"
                  providerData={sportProviderData}
                  isLoading={isLoading}
                  handleToggle={handleToggle}
                  pt={6}
                />
              </AnimatedFlex>
            </Stack>
          </GridItem>
        </Grid>
      </Stack>
    </Flex>
  );
};

export default GameSetting;
