import { Button, HStack, Icon } from "@chakra-ui/react";
import { HiOutlineCheck, HiOutlineXMark } from "react-icons/hi2";

const EditButtons = ({ row, handleModalOpen }) => {
  return (
    <HStack spacing={2}>
      <Button
        leftIcon={<Icon as={HiOutlineCheck} boxSize={4} />}
        variant="outline"
        colorScheme="green"
        size="xs"
        fontSize="xs"
        padding={2}
        onClick={() =>
          handleModalOpen("approveInvalidTransactionModal", row.original)
        }
      >
        อนุมัติ
      </Button>
      <Button
        leftIcon={<Icon as={HiOutlineXMark} boxSize={4} />}
        variant="outline"
        colorScheme="red"
        size="xs"
        fontSize="xs"
        padding={2}
        onClick={() =>
          handleModalOpen("rejectInvalidTransactionModal", row.original)
        }
      >
        ยกเลิก
      </Button>
    </HStack>
  );
};

export default EditButtons;
