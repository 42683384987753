import { callListApi } from "../../utils/APIUtils";
import { buildBasePayload, buildThiefPayload } from "../../utils/PayloadUtils";

export const fetchThieve = async (
  page,
  pageSize,
  search,
  sort = "D",
  sortField = "id",
) => {
  const req = buildBasePayload(
    page,
    pageSize,
    search,
    sort,
    sortField,
    buildThiefPayload,
  );

  const response = await callListApi(
    "/api/admin/searchthieve",
    "POST",
    req,
  ).then((res) => {
    if (res.status === 200) {
      return res.data;
    } else {
      throw new Error(`API error:${res?.message}`);
    }
  });
  const data2 = await response;

  const data = {
    count: data2.page.totalElements,
    results: data2.datas,
  };

  return data;
};
